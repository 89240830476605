export class StorageController {
    static set(key: string, value: string) {
        let storage = window.localStorage;
        if (!storage) {
            return;
        }

        storage.setItem(key, value);
    }

    static get(key: string): string | null {
        let storage = window.localStorage;
        if (!storage) {
            return null;
        }

        return storage.getItem(key) || null;
    }
}