import { CourseListConfiguration } from '../common/Ridingazua.Model';
import { ApplicationEventListener, ApplicationState, ApplicationEvent } from './Ridingazua.ApplicationState';
import { CourseInfoDialogController } from './Ridingazua.CourseInfoDialogController';
import { CourseListDialogController } from './Ridingazua.CourseListDialogController';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { SectionEditor } from './Ridingazua.SectionEditor';

export class SummaryController implements ApplicationEventListener {
    private buttonDescription: HTMLElement;
    private buttonLock: HTMLElement;
    private spanLength: HTMLSpanElement;
    private spanElevation: HTMLSpanElement;
    private spanCourseName: HTMLSpanElement;
    private divUser: HTMLDivElement;
    private spanUserNick: HTMLSpanElement;
    private buttonUserCourseList: HTMLElement;

    private static instance: SummaryController;

    static createInstance(): SummaryController {
        if (!this.instance) {
            this.instance = new SummaryController();
        }
        return this.instance;
    }

    static getInstance(): SummaryController {
        return this.createInstance();
    }
    
    private constructor() {
        let div = document.createElement('div');
        div.id = 'div-summary';
        div.classList.add('box');
        div.appendChild(this.createCourseNameDiv());
        div.appendChild(this.createUserDiv());
        div.appendChild(this.createLengthElevationDiv());
        ApplicationState.addListener(this);
        document.getElementById('div-right-top').appendChild(div);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        if (ApplicationState.isCourseChangedEvent(event)) {
            this.update();
            return;
        }

        switch (event) {
            case ApplicationEvent.COURSE_SAVE_REQUIRED_CHANGED:
            case ApplicationEvent.LOGIN:
            case ApplicationEvent.LOGOUT:
                this.update();
                return;

            case ApplicationEvent.EDITOR_LOCKED_CHANGED:
                this.updateLockButton();
        }
    }

    private createLengthElevationDiv(): HTMLDivElement {
        let div = document.createElement('div');
        div.style.marginTop = '5px';

        let flagIconElement = HTMLUtility.createIconElement('flag');
        div.appendChild(flagIconElement);

        let spanLength = document.createElement('span');
        div.appendChild(spanLength);
        this.spanLength = spanLength;

        let elevationIconElement = HTMLUtility.createIconElement('trending_up');
        elevationIconElement.style.marginInlineStart = '5px';
        div.appendChild(elevationIconElement);

        let spanElevation = document.createElement('span');
        div.appendChild(spanElevation);
        this.spanElevation = spanElevation;

        return div;
    }

    private createCourseNameDiv(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('children-spacing');

        let label = document.createElement('label');
        label.textContent = `${Resources.text.course}:`;
        div.appendChild(label);

        let span = document.createElement('span');
        div.appendChild(span);
        this.spanCourseName = span;

        let buttonDescription = HTMLUtility.createIconButton(Resources.text.course_description, 'info', () => {
            this.showCourseInfoDialog();
        });
        div.appendChild(buttonDescription);
        buttonDescription.classList.add('small');
        buttonDescription.style.display = 'none';
        this.buttonDescription = buttonDescription;

        this.updateLockButton();

        return div;
    }

    private updateLockButton() {
        if(this.buttonLock) {
            this.buttonLock.parentNode.removeChild(this.buttonLock);
        }

        let iconName = SectionEditor.isLocked ? 'lock' : 'lock_open';

        let buttonLock = HTMLUtility.createIconButton(Resources.text.editor_locked, iconName, () => {
            this.toggleLock();
        });

        this.buttonDescription.parentNode.insertBefore(buttonLock, this.buttonDescription.nextSibling);
        buttonLock.classList.add('small');
        this.buttonLock = buttonLock;
    }

    private createUserDiv(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('children-spacing');
        div.classList.add('middle');
        div.style.display = 'none'; // 처음에는 숨겨놓는다.
        div.style.marginTop = '5px';

        let label = document.createElement('label');
        label.textContent = `${Resources.text.course_owner_name}:`;
        div.appendChild(label);

        let span = document.createElement('span');
        div.appendChild(span);

        let buttonUserCourseList = HTMLUtility.createIconButton(Resources.text.course_list, 'account_circle', () => {
            this.showCourseListOfCourseUser();
        });
        div.appendChild(buttonUserCourseList);
        buttonUserCourseList.classList.add('small');

        this.divUser = div;
        this.spanUserNick = span;
        this.buttonUserCourseList = buttonUserCourseList;

        return div;
    }

    private showCourseListOfCourseUser() {
        let userNick = ApplicationState.course?.user?.nick;
        if (!userNick) {
            return;
        }

        let configuration = CourseListConfiguration.defaultConfiguration();
        configuration.userNick = userNick;
        CourseListDialogController.showWithConfiguration(configuration);
    }

    private showCourseInfoDialog() {
        CourseInfoDialogController.show(ApplicationState.course);
    }

    private update() {
        let course = ApplicationState.course;
        course.updateTotalValues();

        this.buttonDescription.style.display = course.id ? '' : 'none';

        let lengthKilometers = course.lengthMeter / 1000;
        this.spanLength.textContent = `${lengthKilometers.toFixed(1)}(km)`;

        let elevationGain = course.totalElevationGain;
        this.spanElevation.textContent = `${elevationGain.toFixed(1)}(m)`;

        let courseText = course.name || Resources.text.course_noname;

        if (ApplicationState.isSaveRequired) {
            courseText += ` (${Resources.text.not_saved})`;
        }
        this.spanCourseName.textContent = courseText;

        let user = ApplicationState.user;
        let userId = user?.id;

        // 아직 저장되지 않은 코스거나, 내가 저장한 코스
        let isMyCourse = !course.id || (userId && userId == course.userId);
        let isAnonymouseCourse = !course.user?.nick;

        this.spanUserNick.textContent = isMyCourse ? user?.nick || user?.name || '' : course.user.nick || Resources.text.anonymous;
        this.divUser.style.display = isMyCourse ? 'none' : '';
        this.buttonUserCourseList.style.display = !isMyCourse && isAnonymouseCourse ? 'none' : '';
    }

    private toggleLock() {
        this.clearLockButtonBlinkTimer();

        SectionEditor.isLocked = SectionEditor.isLocked ? false : true;
        if(SectionEditor.isLocked) {
            toastr.error(Resources.text.editor_did_locked_message);
        } else {
            toastr.info(Resources.text.editor_did_unlocked_message);
        }
    }

    private didToastEditorLocked = false;
    private lockButtonTimer: NodeJS.Timer;

    private clearLockButtonBlinkTimer() {
        this.buttonLock.style.opacity = null;

        if(!this.lockButtonTimer) { return; }
        
        clearInterval(this.lockButtonTimer);
        this.lockButtonTimer = null;
    }

    private toastEditorLockedMessageIfNeeded() {
        if(!this.didToastEditorLocked) {
            toastr.error(Resources.text.editor_unlock_info_message);
            this.didToastEditorLocked = true;
        }
    }

    didTaskDeniedByLockedEditor() {
        this.toastEditorLockedMessageIfNeeded();

        this.clearLockButtonBlinkTimer();
        
        let count = 6;
        this.lockButtonTimer = setInterval(() => {
            let opacity = this.buttonLock.style.opacity;
            this.buttonLock.style.opacity = opacity == "0" ? null : "0";
            count -= 1;
            if(count == 0) {
                this.clearLockButtonBlinkTimer();
            }
        }, 300);
    }
}