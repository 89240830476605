import { ApplicationState, ApplicationEvent } from './Ridingazua.ApplicationState';
import { APIManager } from './Ridingazua.APIManager';
import { LoginDialogController } from './Ridingazua.LoginDialogController';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { AdvertisementPosition, Course, CoursePermission } from '../common/Ridingazua.Model';
import { isNothing } from '../common/Ridingazua.Utility';
import { AdsController } from './Ridingazua.AdsController';
import { sprintf } from 'sprintf-js';
import { InputTagAutocompleteController } from './Ridingazua.TagInputAutocompleteController';

export class SaveDialogController {
    private static instance?: SaveDialogController;
    private dialog: JQuery;
    private div: HTMLDivElement;
    private dl: HTMLDListElement;
    private inputCourseName: HTMLInputElement;
    private selectPermission: HTMLSelectElement;
    private pPermissionDescription: HTMLParagraphElement;
    private divTagButtonContainer: HTMLDivElement;
    private inputTag: HTMLInputElement;
    private ddTag: HTMLElement;
    private textareaDescription: HTMLTextAreaElement;
    private buttonSave: JQueryUI.DialogButtonOptions;
    private buttonSaveAsNew: JQueryUI.DialogButtonOptions;
    private tags: string[] = [];

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        HTMLUtility.appendInputHiddenAutofocus(div);

        let dl = document.createElement('dl');
        div.appendChild(dl);
        this.dl = dl;

        this.appendNameElements();
        this.appendPermissionElements();
        this.appendTagElements();
        this.appendDescriptionElements();

        this.buttonSave = {
            text: Resources.text.save,
            click: () => {
                this.save(false);
            },
        };

        this.buttonSaveAsNew = {
            text: Resources.text.save_as_new,
            click: () => {
                this.save(true);
            },
        };

        this.dialog = $(div).dialog({
            title: Resources.text.save,
            modal: true,
            closeOnEscape: false,
            open: () => {
                if (!this.dialog) {
                    return;
                }

                this.dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });
            },
        });
    }

    private appendNameElements() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        let idForCourseNameInput = 'input-course-name';
        label.setAttribute('for', idForCourseNameInput);
        label.textContent = Resources.text.course_name;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);

        let inputCourseName = document.createElement('input');
        dd.appendChild(inputCourseName);
        inputCourseName.style.width = '95%';
        inputCourseName.type = 'text';
        inputCourseName.id = idForCourseNameInput;
        inputCourseName.placeholder = Resources.text.course_name;
        this.inputCourseName = inputCourseName;
    }

    private appendPermissionElements() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        let idForSelectCoursePermission = 'select-course-permission';
        label.setAttribute('for', idForSelectCoursePermission);
        label.textContent = Resources.text.course_permission;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);
        dd.classList.add('children-vertical-spacing');

        let selectPermission = this.createSelectPermission();
        dd.appendChild(selectPermission);
        selectPermission.id = idForSelectCoursePermission;
        this.selectPermission = selectPermission;

        let pPermissionDescription = document.createElement('p');
        dd.appendChild(pPermissionDescription);
        this.pPermissionDescription = pPermissionDescription;
    }

    private appendTagElements() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        let idForInputTag = 'input-course-tag';
        label.setAttribute('for', idForInputTag);
        label.textContent = Resources.text.course_tag;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);
        dd.classList.add('children-vertical-spacing');
        this.ddTag = dd;

        let divTagButtonContainer = document.createElement('div');
        dd.appendChild(divTagButtonContainer);
        this.divTagButtonContainer = divTagButtonContainer;

        let inputTag = document.createElement('input');
        dd.appendChild(inputTag);
        inputTag.id = idForInputTag;
        inputTag.type = 'text';
        inputTag.style.width = '95%';
        inputTag.maxLength = Course.maxTagLength;
        inputTag.placeholder = Resources.text.course_tag_placeholder;
        this.inputTag = inputTag;

        let inputTagAutocompleteController = new InputTagAutocompleteController(inputTag);
        inputTagAutocompleteController.onInputWhitespace = (value) => {
            this.parseAndAddTags(value);
            this.inputTag.value = '';
            inputTagAutocompleteController.onInputTagChange();
        };
        inputTagAutocompleteController.onInputValueIncludesWhitespace = (value) => {
            let lastIndexOfWhitespace = value.lastIndexOf(' ');
            let subValue = value.substring(0, lastIndexOfWhitespace);
            this.parseAndAddTags(subValue);
            this.inputTag.value = value.substring(lastIndexOfWhitespace + 1);
            inputTagAutocompleteController.onInputTagChange();
        };
        inputTagAutocompleteController.onEnterKeyDown = (value) => {
            this.parseAndAddTags(value);
            this.inputTag.value = '';
            inputTagAutocompleteController.onInputTagChange();
        };
        inputTagAutocompleteController.onSelectTagItem = (tag) => {
            this.parseAndAddTags(tag);
            this.inputTag.value = '';
            inputTagAutocompleteController.onInputTagChange();
        };
    }

    private appendDescriptionElements() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        let idForTextareaDescription = 'textarea-course-description';
        label.setAttribute('for', idForTextareaDescription);
        label.textContent = Resources.text.course_description;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);
        dd.classList.add('children-vertical-spacing');

        let p = document.createElement('p');
        dd.appendChild(p);
        p.textContent = `(${Resources.text.course_description_link_http_required})`;

        let textareaDescription = document.createElement('textarea');
        dd.appendChild(textareaDescription);
        textareaDescription.id = idForTextareaDescription;
        textareaDescription.style.width = '95%';
        textareaDescription.style.height = '70px';
        textareaDescription.style.resize = 'none';
        textareaDescription.maxLength = Course.maxDescriptionLength;
        textareaDescription.placeholder = Resources.text.course_description_placeholder;
        this.textareaDescription = textareaDescription;
    }

    private createSelectPermission() {
        let select = document.createElement('select');

        let allCoursePermissions = Course.allCoursePermissions;
        for (let coursePermission of allCoursePermissions) {
            let option = document.createElement('option');
            option.textContent = Course.getCoursePermissionName(coursePermission);
            option.value = coursePermission.toString();
            select.appendChild(option);
        }

        select.onchange = () => {
            this.pPermissionDescription.textContent = Course.getCoursePermissionDescription(parseInt(select.value));
            this.resetSize();
        };

        return select;
    }

    private update() {
        AdsController.createInstance().replaceAdForDialogWithAdPosition(AdvertisementPosition.SAVE_DIALOG, this.div, this.dialog);

        let course = ApplicationState.course;
        this.inputCourseName.value = course.name ? course.name : '';
        this.selectPermission.selectedIndex = Course.allCoursePermissions.indexOf(course.permission);
        this.pPermissionDescription.textContent = Course.getCoursePermissionDescription(course.permission);
        this.textareaDescription.value = course.description || '';
        this.tags = course.tags || [];
        this.updateTagButtons();

        let buttons: JQueryUI.DialogButtonOptions[] = [];
        let isCourseOwn = course.userId && course.userId === ApplicationState.user?.id;

        if (course.id && isCourseOwn) {
            buttons.push(this.buttonSave);
        }

        buttons.push(this.buttonSaveAsNew);

        this.dialog.dialog('option', 'buttons', buttons);
        this.resetSize();
    }

    private updateTagButtons() {
        this.divTagButtonContainer.innerHTML = '';
        for (let tag of this.tags) {
            let button = document.createElement('button');
            this.divTagButtonContainer.appendChild(button);
            button.style.marginInlineEnd = '3px';
            button.style.marginBottom = '3px';
            button.textContent = tag;
            button.onclick = () => {
                this.tags.splice(this.tags.indexOf(tag), 1);
                this.updateTagButtons();
            };
        }

        if (this.tags.length && !this.divTagButtonContainer.parentElement) {
            this.ddTag.prepend(this.divTagButtonContainer);
        } else if (!this.tags.length && this.divTagButtonContainer.parentElement) {
            this.divTagButtonContainer.remove();
        }
    }

    private resetSize() {
        if (!this.dialog) {
            return;
        }

        this.dialog.dialog('option', 'width', Math.min(window.innerWidth * 0.95, AdsController.dialogWidthForAds));
        this.dialog.dialog('option', 'maxWidth', window.innerWidth * 0.95);
        this.dialog.dialog('option', 'height', 'auto');
    }

    private isSaving = false;

    private async save(asNewCourse: boolean) {
        if (this.isSaving) {
            toastr.warning(Resources.text.course_saving);
            return;
        }

        let courseName = this.inputCourseName.value.trim();
        if (!courseName || courseName.length == 0) {
            toastr.error(Resources.text.course_name_required);
            return;
        }

        let permission = parseInt(this.selectPermission.value);
        if (isNothing(permission)) {
            permission = CoursePermission.LINK_PUBLIC_READ_ONLY;
        }

        // 입력이 완료되지 않은 태그까지 포함하여 처리
        let inputtingTag = this.inputTag.value;
        this.inputTag.value = '';
        this.parseAndAddTags(inputtingTag);

        let tags = this.tags;
        let description = this.textareaDescription.value;
        if (description && description.length > Course.maxDescriptionLength) {
            toastr.error(sprintf(Resources.text.course_description_overflow_message, Course.maxDescriptionLength));
            return;
        }

        let course = ApplicationState.course.clone();
        course.name = courseName;
        course.permission = permission;
        course.tags = tags;
        course.description = description;
        if (asNewCourse) {
            course.setAsNew();
        }
        this.isSaving = true;
        toastr.info(Resources.text.course_saving);

        try {
            let courseId = await APIManager.saveCourse(course);
            course = ApplicationState.course;
            course.id = courseId;
            course.name = courseName;
            course.permission = permission;
            course.tags = tags;
            course.description = description;
            course.userId = ApplicationState.user.id;

            if (asNewCourse) {
                course.pathName = null;
            }

            toastr.success(Resources.text.course_saved);

            ApplicationState.isSaveRequired = false;
            ApplicationState.executeListeners(ApplicationEvent.COURSE_SAVED, course);
            if (asNewCourse) {
                ApplicationState.replaceWindowHistoryStateBySavedCourse();
            }
            this.close();
        } catch (error) {
            let message = APIManager.messageFromResponseError(error) || Resources.text.failed_to_save_course;
            toastr.error(message);
        }

        this.isSaving = false;
    }

    private close() {
        this.dialog.dialog('close');
    }

    static show() {
        if (!ApplicationState.user) {
            toastr.info(Resources.text.login_required);
            LoginDialogController.show(() => {
                SaveDialogController.show();
            });
            return;
        }

        if (!this.instance) {
            this.instance = new SaveDialogController();
        }
        this.instance.update();
        this.instance.dialog.dialog('open');
    }

    private parseAndAddTags(value: string) {
        let components = value.split(' ');
        components = components.filter((component) => {
            return component.length > 0 && !this.tags.includes(component);
        });

        let overflowedTags = components.filter((component) => {
            return component.length > Course.maxTagLength;
        });

        if (overflowedTags.length) {
            toastr.error(sprintf(Resources.text.course_tag_overflow_message, Course.maxTagLength));
            return;
        }

        for (let component of components) {
            component = component.trim();
            while (component.startsWith('#')) {
                // #으로 시작하면 안됨
                component = component.substring(1);
            }
            if (!component.length) {
                continue;
            }
            this.tags.push(component);
        }

        this.updateTagButtons();
    }

    private restoreLastTagToInputTag() {
        if (!this.tags.length) {
            return;
        }
        let lastTag = this.tags.splice(this.tags.length - 1, 1)[0];
        this.inputTag.value = lastTag;
        this.inputTag.selectionStart = lastTag.length;
        this.updateTagButtons();
    }
}
