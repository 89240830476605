import dateFormat from 'dateformat';
import { sprintf } from 'sprintf-js';
import { Advertisement, AdvertisementPosition } from '../common/Ridingazua.Model';
import { AdsController } from './Ridingazua.AdsController';
import { APIManager } from './Ridingazua.APIManager';
import { CommonDialogController } from './Ridingazua.CommonDialogController';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';

export class AdManagerDialogController {
    private static instance: AdManagerDialogController;
    private dialog: JQuery;
    private tbody: HTMLTableSectionElement;

    static show() {
        if (!this.instance) {
            this.instance = new AdManagerDialogController();
        }
        this.instance.dialog.dialog('open');
        this.instance.load();
    }

    private columns = [
        'ID',
        Resources.text.ad_manager_current_showing,
        Resources.text.ad_manager_position,
        Resources.text.ad_manager_image_file,
        Resources.text.ad_manager_start_time,
        Resources.text.ad_manager_link,
        Resources.text.ad_manager_delete,
    ];

    private constructor() {
        let div = document.createElement('div');
        HTMLUtility.appendInputHiddenAutofocus(div);

        let table = document.createElement('table');
        div.appendChild(table);
        table.style.width = '100%';

        let thead = document.createElement('thead');
        table.appendChild(thead);

        let tr = document.createElement('tr');
        thead.appendChild(tr);

        this.columns.forEach((column) => {
            let th = document.createElement('th');
            tr.appendChild(th);
            th.textContent = column;
        });

        let tbody = document.createElement('tbody');
        table.appendChild(tbody);
        this.tbody = tbody;

        this.dialog = $(div).dialog({
            title: Resources.text.ad_manager,
            modal: true,
            width: window.innerWidth * 0.9,
            height: 'auto',
            maxHeight: window.innerHeight * 0.9,
            buttons: [
                {
                    text: Resources.text.ad_manager_new,
                    click: () => {
                        let editDialog = AdvertisementEditDialogController.show();
                        editDialog.onAdSaved = () => {
                            this.load();
                        };
                    },
                },
            ],
        });
    }

    private async load() {
        this.tbody.innerHTML = '';

        let adList = await APIManager.loadAdList();

        if (!adList.length) {
            let tr = document.createElement('tr');
            this.tbody.appendChild(tr);

            let td = document.createElement('td');
            tr.appendChild(td);
            td.classList.add('center');
            td.classList.add('middle');
            td.style.padding = '10px';
            td.colSpan = this.columns.length;
            td.textContent = Resources.text.ad_manager_no_ad;
        } else {
            adList.forEach((item) => {
                let tr = document.createElement('tr');
                this.tbody.appendChild(tr);

                let linkTds: HTMLElement[] = [];

                let td = document.createElement('td');
                tr.appendChild(td);
                td.classList.add('link');
                td.classList.add('center');
                td.classList.add('middle');
                td.textContent = item.id.toString();
                linkTds.push(td);

                td = document.createElement('td');
                tr.appendChild(td);
                td.classList.add('link');
                td.classList.add('center');
                td.classList.add('middle');
                td.textContent = item.isShowing ? '○' : '×';
                linkTds.push(td);

                td = document.createElement('td');
                tr.appendChild(td);
                td.classList.add('link');
                td.classList.add('center');
                td.classList.add('middle');
                td.textContent = Advertisement.nameForPosition(item.position);
                linkTds.push(td);

                td = document.createElement('td');
                tr.appendChild(td);
                td.classList.add('link');
                td.classList.add('center');
                td.classList.add('middle');
                linkTds.push(td);

                let img = document.createElement('img');
                td.appendChild(img);
                img.classList.add('border');
                img.style.margin = '3px';
                img.style.width = '100px';
                img.style.height = '100px';
                img.style.objectFit = 'contain';
                img.src = item.thumbnailImageUrl;
                linkTds.push(td);

                td = document.createElement('td');
                tr.appendChild(td);
                td.classList.add('link');
                td.classList.add('center');
                td.classList.add('middle');
                td.textContent = item.formattedStartTime || '';
                linkTds.push(td);

                td = document.createElement('td');
                tr.appendChild(td);
                td.classList.add('center');
                td.classList.add('middle');

                let a = document.createElement('a');
                td.appendChild(a);
                a.textContent = Resources.text.ad_manager_link;
                a.href = item.link || '';
                a.target = '_blank';

                td = document.createElement('td');
                tr.appendChild(td);
                td.classList.add('center');
                td.classList.add('middle');

                let deleteButton = HTMLUtility.createIconButton(Resources.text.delete, 'delete', () => {
                    this.showDeleteConfirm(item);
                });
                td.appendChild(deleteButton);

                linkTds.forEach((td) => {
                    td.onclick = () => {
                        let editDialog = AdvertisementEditDialogController.show(item);
                        editDialog.onAdSaved = () => {
                            this.load();
                        };
                    };
                });
            });
        }

        HTMLUtility.resetDialogPositionToCenter(this.dialog);
    }

    private showDeleteConfirm(ad: Advertisement) {
        CommonDialogController.showConfirm(Resources.text.ad_manager_delete, Resources.text.ad_manager_delete_confirm, [
            {
                text: Resources.text.yes,
                action: () => {
                    this.deleteAd(ad);
                },
            },
            {
                text: Resources.text.no,
                action: () => {},
            },
        ]);
    }

    private async deleteAd(ad: Advertisement) {
        try {
            toastr.info(Resources.text.ad_manager_deleting);
            await APIManager.deleteAd(ad);
            toastr.success(Resources.text.ad_manager_deleted);
            this.load();
        } catch (exception) {
            toastr.error(Resources.text.ad_manager_error_on_delete);
        }
    }

    close() {
        this.dialog.dialog('close');
    }
}

export class AdvertisementEditDialogController {
    private static instance: AdvertisementEditDialogController;
    private dialog: JQuery;
    private _item: Advertisement;

    get item(): Advertisement {
        return this._item;
    }

    set item(item: Advertisement) {
        this._item = item;
        this.update();
    }

    onAdSaved?: () => void;

    static show(item?: Advertisement): AdvertisementEditDialogController {
        if (!this.instance) {
            this.instance = new AdvertisementEditDialogController();
        }
        this.instance.item = item?.clone() || new Advertisement();
        this.instance.dialog.dialog('open');
        this.instance.dialog.dialog('option', 'width', 'auto');
        this.instance.dialog.dialog('option', 'height', 'auto');
        return this.instance;
    }

    private selectPosition: HTMLSelectElement;
    private inputFile: HTMLInputElement;
    private inputStartTime: HTMLInputElement;
    private inputLink: HTMLInputElement;
    private pSelectedImage: HTMLParagraphElement;
    private imgSelected: HTMLImageElement;
    private pCurrentImage: HTMLParagraphElement;
    private imgCurrent: HTMLImageElement;

    private constructor() {
        let div = document.createElement('div');
        HTMLUtility.appendInputHiddenAutofocus(div);

        let dl = document.createElement('dl');
        div.appendChild(dl);

        // 광고 위치 선택
        let dt = document.createElement('dt');
        dl.appendChild(dt);
        let label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.ad_manager_position;
        label.setAttribute('for', 'select-ad-position');

        let dd = document.createElement('dd');
        dl.appendChild(dd);

        let selectPosition = document.createElement('select');
        dd.appendChild(selectPosition);
        selectPosition.id = 'select-ad-position';
        selectPosition.style.width = '100%';
        this.selectPosition = selectPosition;

        Advertisement.allPositions.forEach((position) => {
            let option = document.createElement('option');
            selectPosition.appendChild(option);
            option.textContent = Advertisement.nameForPosition(position);
            option.value = position.toString();
        });

        // 광고 이미지 파일
        dt = document.createElement('dt');
        dl.appendChild(dt);
        label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.ad_manager_image_file;
        label.setAttribute('for', 'input-ad-file');

        dd = document.createElement('dd');
        dl.appendChild(dd);

        let pImageSizeInfo = document.createElement('p');
        dd.appendChild(pImageSizeInfo);
        pImageSizeInfo.textContent = Resources.text.ad_manager_image_size_info;

        let inputFile = document.createElement('input');
        dd.appendChild(inputFile);
        inputFile.id = 'input-ad-file';
        inputFile.style.marginTop = '5px';
        inputFile.style.width = '100%';
        inputFile.type = 'file';
        inputFile.accept = '.jpg,.gif,.png';
        inputFile.onchange = () => {
            this.onInputFileChange();
        };
        this.inputFile = inputFile;

        // 선택된 이미지 미리보기 처리
        let p = document.createElement('p');
        p.style.marginTop = '10px';
        p.textContent = Resources.text.ad_manager_selected_image;
        dd.appendChild(p);
        this.pSelectedImage = p;

        let img = document.createElement('img');
        dd.appendChild(img);
        img.classList.add('border');
        img.style.margin = '3px';
        img.style.width = '200px';
        img.style.height = 'auto';
        img.style.objectFit = 'contain';
        this.imgSelected = img;

        // 이미 등록된 이미지에 대한 처리
        p = document.createElement('p');
        p.style.marginTop = '10px';
        p.textContent = Resources.text.ad_manager_current_image;
        dd.appendChild(p);
        this.pCurrentImage = p;

        img = document.createElement('img');
        dd.appendChild(img);
        img.classList.add('border');
        img.classList.add('link');
        img.style.margin = '3px';
        img.style.width = '200px';
        img.style.height = 'auto';
        img.style.objectFit = 'contain';
        img.onclick = () => {
            window.open(this.item.sourceImageUrl());
        };
        this.imgCurrent = img;

        // 광고 게시 시작 시간
        dt = document.createElement('dt');
        dl.appendChild(dt);
        label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.ad_manager_start_time;
        label.setAttribute('for', 'input-ad-start-time');

        dd = document.createElement('dd');
        dl.appendChild(dd);

        let pStartTime = document.createElement('p');
        dd.appendChild(pStartTime);
        let startTimePlaceholder1 = sprintf(Resources.text.ad_manager_start_time_placeholder1, dateFormat(new Date(), Advertisement.dateFormat));
        pStartTime.innerHTML = startTimePlaceholder1 + '<br>' + Resources.text.ad_manager_start_time_placeholder2;

        let inputStartTime = document.createElement('input');
        dd.appendChild(inputStartTime);
        inputStartTime.id = 'input-ad-start-time';
        inputStartTime.style.width = '100%';
        inputStartTime.type = 'text';
        inputStartTime.placeholder = startTimePlaceholder1;
        this.inputStartTime = inputStartTime;

        // 광고 링크
        dt = document.createElement('dt');
        dl.appendChild(dt);
        label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.ad_manager_link;
        label.setAttribute('for', 'input-ad-link');

        dd = document.createElement('dd');
        dl.appendChild(dd);

        let inputLink = document.createElement('input');
        dd.appendChild(inputLink);
        inputLink.id = 'input-ad-link';
        inputLink.style.width = '100%';
        inputLink.type = 'text';
        inputLink.placeholder = Resources.text.ad_manager_link_placeholder;
        this.inputLink = inputLink;

        this.dialog = $(div).dialog({
            title: Resources.text.ad_manager_edit_ad,
            modal: true,
            width: 'auto',
            height: 'auto',
            buttons: [
                {
                    text: Resources.text.cancel,
                    click: () => {
                        this.close();
                    }
                },
                {
                    text: Resources.text.preview,
                    click: () => {
                        this.preview();
                    },
                },
                {
                    text: Resources.text.save,
                    click: () => {
                        this.submit();
                    },
                },
            ],
        });
    }

    private update() {
        let selectedPosition = this.item.position || AdvertisementPosition.NEW_DIALOG;
        let positionIndex = Advertisement.allPositions.indexOf(selectedPosition);
        if (positionIndex < 0) {
            positionIndex = 0;
        }

        this.inputFile.value = '';
        this.pSelectedImage.style.display = 'none';
        this.imgSelected.removeAttribute('src');
        this.imgSelected.style.display = 'none';

        this.selectPosition.selectedIndex = positionIndex;
        this.inputStartTime.value = this.item.formattedStartTime;
        this.inputLink.value = this.item.link || '';

        let thumbnailImageUrl = this.item?.thumbnailImageUrl;
        if (thumbnailImageUrl) {
            this.imgCurrent.src = thumbnailImageUrl;
        } else {
            this.imgCurrent.removeAttribute('src');
        }
        this.imgCurrent.style.display = thumbnailImageUrl ? '' : 'none';
        this.pCurrentImage.style.display = thumbnailImageUrl ? '' : 'none';

        HTMLUtility.resetDialogPositionToCenter(this.dialog);
    }

    private onInputFileChange() {
        let files = this.inputFile.files;
        if (!FileReader || !files || !files.length) {
            return;
        }

        let fileReader = new FileReader();
        fileReader.onload = () => {
            this.imgSelected.onload = () => {
                HTMLUtility.resetDialogPositionToCenter(this.dialog);
            };
            this.imgSelected.src = fileReader.result as string;
            this.imgSelected.style.display = '';
            this.pSelectedImage.style.display = '';

            this.pCurrentImage.style.display = 'none';
            this.imgCurrent.removeAttribute('src');
            this.imgCurrent.style.display = 'none';

            // 이미지 크기를 검사하는 처리
            let tempImage = new Image();
            tempImage.onload = () => {
                let ratio = tempImage.height / tempImage.width;
                let imageTooSmall = tempImage.width < 1000;
                let imageTooVerticallyLong = ratio > 0.5;

                let warningTexts: string[] = [];
                if (imageTooSmall) {
                    warningTexts.push(Resources.text.ad_manager_image_size_warning);
                }

                if (imageTooVerticallyLong) {
                    warningTexts.push(Resources.text.ad_manager_image_ratio_warning);
                }

                if (warningTexts.length) {
                    let warningText = warningTexts.join('\n\n');
                    CommonDialogController.showConfirm(Resources.text.warning, warningText, [{ text: Resources.text.ok }]);
                }
            };
            tempImage.src = fileReader.result as string;
        };

        fileReader.readAsDataURL(files[0]);
    }

    private async preview() {
        let localImgSrc = this.imgSelected.src;
        let currentImgSrc = this.item.imageUrl(500, window.devicePixelRatio);
        let imgSrc = localImgSrc || currentImgSrc;
        let link = this.inputLink.value.trim();

        if (!imgSrc) {
            toastr.error(Resources.text.ad_manager_image_not_found);
            return;
        }

        let ad = new Advertisement();
        ad.imageUrlForPreview = imgSrc;
        ad.link = link;

        let dialogController = await CommonDialogController.showConfirm(
            Resources.text.preview,
            '광고 미리보기용 다이얼로그입니다.\n이미지의 크기는 다이얼로그의 크기에 맞춰집니다.\n다이얼로그의 크기를 변경하며 볼 수 있습니다.',
            [
                {
                    text: Resources.text.close,
                },
            ],
            null
        );

        AdsController.createInstance().insertLocalAdForDialogWithAd(ad, dialogController.div, dialogController.dialog);
    }

    private submitting = false;

    private async submit() {
        let startTimeMilliseconds = Date.parse(this.inputStartTime.value.trim());
        let now = new Date().getTime();

        if (this.inputStartTime.value && !startTimeMilliseconds) {
            // 날짜는 입력되었으나 파싱이 안됨
            toastr.error(Resources.text.ad_manager_invalid_start_time);
            return;
        }

        // 이미 등록된 광고의 시작 날짜를 변경하지 않은 것이므로 날짜 유효성을 검사하지 않는다.
        let ignoreStartTimeValidation = this.item.id && this.item.formattedStartTime == this.inputStartTime.value.trim();

        if (!ignoreStartTimeValidation && startTimeMilliseconds && startTimeMilliseconds < now) {
            // 날짜는 입력 또는 변경되었으나 현재보다 이전임
            toastr.error(Resources.text.ad_manager_invalid_start_time);
            return;
        }

        // 입력된 값 할당
        this.item.position = Advertisement.allPositions[this.selectPosition.selectedIndex];
        this.item.startTimestamp = startTimeMilliseconds ? startTimeMilliseconds / 1000 : null;
        this.item.link = this.inputLink.value.trim();

        // 이미지 업로드 전 유효성 검사
        let invalidMessage = this.item.invalidMessage();
        if (invalidMessage) {
            toastr.error(invalidMessage);
            return;
        }

        if (this.submitting) {
            toastr.warning(Resources.text.ad_manager_submitting);
            return;
        }

        toastr.info(Resources.text.ad_manager_submitting);

        try {
            this.submitting = true;
            await this.uploadImageIfRequired();

            // 이미지 업로드 후 유효성 검사
            let invalidMessage = this.item.invalidMessage();
            if (invalidMessage) {
                toastr.error(invalidMessage);
                this.submitting = false;
                return;
            }

            await APIManager.saveAd(this.item);
            toastr.success(Resources.text.ad_manager_submitted);
            this.submitting = false;
            if (this.onAdSaved) {
                this.onAdSaved();
            }
            AdsController.createInstance().refreshShowingAdList();
            this.close();
        } catch (error) {
            toastr.error(Resources.text.ad_manager_error_on_save);
            this.submitting = false;
        }
    }

    private async uploadImageIfRequired() {
        if (!this.inputFile.files.length) {
            return;
        }

        let uploadedFileInfo = await APIManager.uploadFile(this.inputFile.files[0]);
        this.item.imageFileId = uploadedFileInfo.id;
        this.item.imageWidth = uploadedFileInfo.imageWidth;
        this.item.imageHeight = uploadedFileInfo.imageHeight;
        this.inputFile.value = null;
    }

    close() {
        this.dialog.dialog('close');
    }
}
