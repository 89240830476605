export class Resources {
    /**
     * 지원하는 언어들
     */
    static supportedLanguages = ['en', 'ko'];

    static Resources: {};

    static textResourcesKo: TextResources;
    static textResourcesEn: TextResources;
    static get allTextResources(): TextResources[] {
        return [this.textResourcesEn, this.textResourcesKo];
    }

    static acceptedLanguage(): string {
        let language = 'en';
        try {
            language = navigator.language || 'en';
        } catch (error) {
            // do nothing
        }

        if (language) {
            language = language.split('-')[0].toLowerCase();
        }

        if (!this.supportedLanguages.includes(language)) {
            language = this.supportedLanguages[0];
        }

        return language;
    }

    static textForLanguage(language: string): TextResources {
        if (language && language.includes('-')) {
            language = language.split('-')[0].toLowerCase();
        }

        switch (language) {
            case 'ko':
                return this.textResourcesKo;
            default:
                return this.textResourcesEn;
        }
    }

    static get text(): TextResources {
        return this.textForLanguage(this.acceptedLanguage());
    }
}

export interface TextResources {
    ok: string;
    cancel: string;
    yes: string;
    no: string;
    apply: string;
    close: string;
    enabled: string;
    disabled: string;
    error: string;
    error_occured: string;
    not_found_404: string;
    settings: string;
    help: string;
    important: string;
    caution: string;
    warning: string;
    readme: string;
    dont_show_again: string;
    delete: string;
    link: string;
    copy: string;
    copied: string;
    failed_to_copy: string;
    prev: string;
    next: string;
    go_to_page: string;
    page: string;
    course: string;
    section: string;
    not_saved: string;
    selected_range: string;
    selected_map_section: string;
    auto_route: string;
    auto_route_profile: string;
    auto_route_profile_cycling_recommended: string;
    auto_route_profile_cycling_mountain: string;
    auto_route_profile_road: string;
    auto_left_right: string;
    map_type: string;
    map_type_osm: string;
    map_type_google_road: string;
    map_type_google_terrain: string;
    map_type_google_satellite: string;
    map_type_google_hybrid: string;
    map_type_overlay_image_map: string;
    overlay_image_map_sttings: string;
    overlay_image_map_url_template: string;
    overlay_image_map_url_template_description: string;
    overlay_image_map_url_template_invalid: string;
    new_course: string;
    open: string;
    save: string;
    import: string;
    download: string;
    undo: string;
    redo: string;
    preview: string;
    more_menus: string;
    editor_locked: string;
    editor_unlocked: string;
    editor_did_locked_message: string;
    editor_did_unlocked_message: string;
    editor_unlock_info_message: string;
    search_place_by_google: string;
    search_place_by_kakao: string;
    search_place_clear: string;
    search_place_list: string;
    search_place_current_bounds: string;
    search_place_korea_only: string;
    failed_to_search_places: string;
    no_place_search_results: string;
    load_course: string;
    confirm_message_for_lost: string;
    course_list_search_keyword_placeholder: string;
    course_list_my: string;
    course_list_public: string;
    course_list_all_if_superuser: string;
    course_list_user_format: string;
    course_list_suffix_search_result_format;
    course_list: string;
    search: string;
    distance: string;
    position: string;
    elevation: string;
    slope: string;
    course_noname: string;
    course_start: string;
    course_finish: string;
    course_name: string;
    course_owner_name: string;
    course_length: string;
    course_elevations: string;
    course_info: string;
    course_created: string;
    course_updated: string;
    course_permission: string;
    course_permission_link: string;
    course_permission_private: string;
    course_permission_link_public_read_only: string;
    course_permission_all_public_read_only: string;
    course_permission_description_private: string;
    course_permission_description_link_public_read_only: string;
    course_permission_description_all_public_read_only: string;
    course_more: string;
    course_tag: string;
    course_tag_placeholder: string;
    course_tag_overflow_message: string;
    course_tag_not_found: string;
    course_description: string;
    course_description_not_found: string;
    course_description_link_http_required: string;
    course_description_placeholder: string;
    course_description_overflow_message: string;
    save_as_new: string;
    new_course_from_file: string;
    new_section_from_file: string;
    message_for_select_file_type1: string;
    message_for_select_file_type2: string;
    message_for_course_name_empty: string;
    loading_course_list: string;
    no_course_list: string;
    sections: string;
    waypoint: string;
    waypoints: string;
    waypoint_type: string;
    waypoint_name: string;
    waypoint_note: string;
    waypoint_position: string;
    waypoint_elevation: string;
    waypoint_edit: string;
    waypoint_deleted: string;
    waypoint_set_visible_in_elevation_chart: string;
    waypoint_set_all_default_visible_in_elevation_chart: string;
    waypoint_show_all_default_visible_in_elevation_chart: string;
    waypoint_hide_all_default_visible_in_elevation_chart: string;
    waypoint_type_generic: string;
    waypoint_type_summit: string;
    waypoint_type_valley: string;
    waypoint_type_water: string;
    waypoint_type_food: string;
    waypoint_type_danger: string;
    waypoint_type_left: string;
    waypoint_type_right: string;
    waypoint_type_straight: string;
    waypoint_type_first_aid: string;
    waypoint_type_category_1: string;
    waypoint_type_category_2: string;
    waypoint_type_category_3: string;
    waypoint_type_category_4: string;
    waypoint_type_category_h: string;
    waypoint_type_sprint: string;
    no_waypoints: string;
    course_instruction_sharp_left: string;
    course_instruction_sharp_right: string;
    course_instruction_slight_left: string;
    course_instruction_slight_right: string;
    find_climbs_in_course: string;
    find_climbs_in_course_short: string;
    login: string;
    login_with_facebook: string;
    login_with_kakao: string;
    login_with_google: string;
    facebook_login_deprecated: string;
    privacy_policy: string;
    hello_format: string;
    goodbye: string;
    logout: string;
    zoom_in_map: string;
    zoom_out_map: string;
    my_location: string;
    finding_my_location: string;
    elevation_chart: string;
    elevation_chart_image: string;
    image_width: string;
    image_height: string;
    opacity: string;
    no_data_for_elevation_chart: string;
    section_new: string;
    section_paste: string;
    section_rename: string;
    section_up: string;
    section_down: string;
    section_duplicate: string;
    section_copy_to_clipboard: string;
    section_copy_failed_by_empty: string;
    section_copied_to_clipboard: string;
    section_copy_failed: string;
    section_pasted: string;
    section_paste_failed: string;
    section_reverse: string;
    section_merge_with_below: string;
    section_delete: string;
    section_is_last: string;
    section_must_be_at_least_one: string;
    elevation_smooth: string;
    elevation_settings: string;
    elevation_smooth_description: string;
    elevation_smooth_level: string;
    elevation_smooth_level_reset: string;
    elevation_smooth_failed_by_no_original_elevations: string;
    elevation_chart_waypoints: string;
    elevation_reload: string;
    elevation_chart_save_as_image: string;
    elevation_chart_generate_image: string;
    elevation_chart_generate_image_description: string;
    elevation_chart_show_all: string;
    climbs: string;
    climb_category: string;
    climb_start: string;
    climb_finish: string;
    climb_name: string;
    climbs_not_found: string;
    climb_select: string;
    climb_select_zoom: string;
    elevation_gain: string;
    average_slope: string;
    length: string;
    position_start: string;
    position_finish: string;
    map_settings: string;
    add_waypoint: string;
    add_waypoint_type_format: string;
    split_section: string;
    draw_line_to_this_point: string;
    view_kakao_map_this_point: string;
    view_kakao_map_road_view_this_point: string;
    confirm_message_for_delete_course_format: string;
    course_deleted_format: string;
    login_required: string;
    retry_after_save_course: string;
    course_saving: string;
    course_name_required: string;
    course_saved: string;
    course_id_required: string;
    course_is_too_short: string;
    all_fileds_required: string;
    failed_to_route: string;
    failed_to_load_course: string;
    failed_to_load_file: string;
    failed_to_delete_course: string;
    failed_to_save_course: string;
    failed_to_login: string;
    failed_to_login_with_facebook: string;
    failed_to_logout_with_facebook: string;
    failed_to_login_with_kakao: string;
    failed_to_logout_with_kakao: string;
    failed_to_logout: string;
    failed_to_save_user_info: string;
    failed_to_find_user_in_facebook: string;
    failed_to_download: string;
    failed_to_my_location: string;
    failed_to_load_course_list: string;
    failed_to_reload_elevation: string;
    not_logged_in: string;
    invalid_request: string;
    course_not_owner: string;
    course_private_not_owner: string;
    course_url_private_not_owner_format: string;
    course_url_is_not_found_format: string;
    course_url_is_not_supported_file_type_format: string;
    user_nick: string;
    user_nick_setting: string;
    user_nick_setting_description: string;
    user_nick_required: string;
    user_nick_placeholder: string;
    user_nick_conflict: string;
    user_nick_changed: string;
    anonymous: string;
    ad_manager: string;
    ad_element_info: string;
    ad_manager_new: string;
    ad_manager_edit_ad: string;
    ad_manager_current_showing: string;
    ad_manager_position: string;
    ad_manager_image_file: string;
    ad_manager_image_size_info: string;
    ad_manager_image_size_warning: string;
    ad_manager_image_ratio_warning: string;
    ad_manager_selected_image: string;
    ad_manager_current_image: string;
    ad_manager_background_color: string;
    ad_manager_background_color_placeholder: string;
    ad_manager_start_time: string;
    ad_manager_start_time_placeholder1: string;
    ad_manager_start_time_placeholder2: string;
    ad_manager_link: string;
    ad_manager_link_placeholder: string;
    ad_manager_delete: string;
    ad_manager_delete_confirm: string;
    ad_manager_no_ad: string;
    ad_manager_submitting: string;
    ad_manager_submitted: string;
    ad_manager_deleting: string;
    ad_manager_deleted: string;
    ad_manager_error_on_save: string;
    ad_manager_error_on_delete: string;
    ad_manager_invalid_link: string;
    ad_manager_image_not_found: string;
    ad_manager_invalid_position: string;
    ad_manager_invalid_start_time: string;
    ad_manager_start_time_must_future: string;
    advertisement_position_new_dialog: string;
    advertisement_position_save_dialog: string;
    advertisement_position_import_dialog: string;
    advertisement_position_export_dialog: string;
    advertisement_position_login_dialog: string;
    advertisement_position_unknown: string;

    ridingazua_editor_description: string;
    ridingazua_editor_title: string;
    ridingazua_editor_keywords: string;
    ridingazua_course_description_format: string;
    ridingazua_course_list_count_format: string;
}

Resources.textResourcesKo = {
    ok: '확인',
    cancel: '취소',
    yes: '예',
    no: '아니오',
    apply: '적용',
    close: '닫기',
    enabled: '켬',
    disabled: '끔',
    settings: '설정',
    error: '오류',
    error_occured: '오류가 발생하였습니다.',
    not_found_404: '404 - 앗! 요청한 항목이 존재하지 않습니다!',
    important: '중요사항',
    caution: '주의사항',
    warning: '경고',
    readme: '반드시 읽어주십시오',
    dont_show_again: '다시 보지 않기',
    delete: '삭제',
    help: '도움말',
    link: '링크',
    copy: '복사',
    copied: '복사되었습니다.',
    failed_to_copy: '복사를 실패하였습니다.',
    prev: '이전',
    next: '다음',
    go_to_page: '페이지 이동:',
    page: '페이지',
    course: '코스',
    section: '섹션',
    not_saved: '저장되지 않음',
    selected_range: '선택된 구간',
    selected_map_section: '선택된 구간',
    auto_route: '자동 경로',
    auto_route_profile: '자동 경로 프로파일',
    auto_route_profile_cycling_recommended: '자전거',
    auto_route_profile_cycling_mountain: '산악자전거',
    auto_route_profile_road: '일반 도로',
    auto_left_right: '자동 좌/우회전 표시',
    map_type: '지도 유형',
    map_type_osm: '오픈 스트리트 지도',
    map_type_google_road: '구글 지도(도로)',
    map_type_google_terrain: '구글 지도(지형)',
    map_type_google_satellite: '구글 지도(위성)',
    map_type_google_hybrid: '구글 지도(위성+도로)',
    map_type_overlay_image_map: '오버레이 이미지 맵',
    overlay_image_map_sttings: '오버레이 이미지 맵 설정',
    overlay_image_map_url_template: 'URL 템플릿',
    overlay_image_map_url_template_description: 'https:// 또는 http:// 로 시작하며 "{zoom}", "{x}", "{y}" 문자열을 포함하는 URL 형식을 입력해주십시오.',
    overlay_image_map_url_template_invalid: 'URL 템플릿이 올바르지 않습니다.',
    new_course: '새 코스',
    open: '열기',
    save: '저장',
    import: '불러오기',
    download: '다운로드',
    undo: '실행취소',
    redo: '다시실행',
    preview: '미리보기',
    more_menus: '더 보기',
    editor_locked: '편집 잠김',
    editor_unlocked: '편집 가능',
    editor_did_locked_message: '이제 코스를 편집할 수 없습니다.',
    editor_did_unlocked_message: '이제 코스를 편집할 수 있습니다.',
    editor_unlock_info_message: '코스를 편집할 수 없습니다. 코스를 편집하려면 우측의 자물쇠 버튼을 클릭해주십시오.',
    search_place_by_google: '구글 장소 검색',
    search_place_by_kakao: '카카오 장소 검색',
    search_place_clear: '검색결과 초기화',
    search_place_list: '검색결과 목록',
    search_place_current_bounds: '현재 지도에서 검색',
    search_place_korea_only: '한국 장소만 검색',
    failed_to_search_places: '검색 중 오류가 발생하였습니다.',
    no_place_search_results: '검색 결과가 없습니다.',
    load_course: '코스 불러오기',
    confirm_message_for_lost: '저장되지 않은 데이터는 유실됩니다. 계속하시겠습니까?',
    course_list_search_keyword_placeholder: '태그 또는 코스명으로 검색',
    course_list_my: '내 코스',
    course_list_public: '공개 코스',
    course_list_all_if_superuser: '전체 코스(for Admin)',
    course_list_user_format: '[%s]님의 공개코스',
    course_list_suffix_search_result_format: '"%s"에 대한 검색결과',
    course_list: '코스 목록',
    search: '검색',
    distance: '거리',
    position: '위치',
    elevation: '고도',
    slope: '경사도',
    course_noname: '이름없음',
    course_start: '시작',
    course_finish: '종료',
    course_name: '코스명',
    course_owner_name: '작성자',
    course_length: '코스 길이',
    course_elevations: '누적 상승',
    course_info: '코스 정보',
    course_created: '생성',
    course_updated: '수정',
    course_permission: '공개여부',
    course_permission_link: '일부 공개',
    course_permission_private: '비공개',
    course_permission_link_public_read_only: '일부 공개',
    course_permission_all_public_read_only: '전체 공개',
    course_permission_description_private: '작성자만 코스를 보거나 편집할 수 있습니다. 코스를 다른 사람들과 공유하지 않으려면 이 옵션을 선택해주십시오.',
    course_permission_description_link_public_read_only: '코스의 링크(URL)을 소유한 사람이 코스를 볼 수 있습니다. 제한된 사람들에게만 코스를 공유하려면 이 옵션을 선택해주십시오.',
    course_permission_description_all_public_read_only: '모든 사용자가 코스를 볼 수 있으며, 코스의 정보가 사이트 내에 게시될 수 있습니다. 많은 사람들과 코스를 공유하려면 이 옵션을 선택해주십시오.',
    course_more: '기타',
    course_tag: '태그',
    course_tag_placeholder: '태그를 입력해주십시오.',
    course_tag_overflow_message: '태그는 %d자 이내로 입력해주십시오.',
    course_tag_not_found: '태그가 없습니다.',
    course_description: '설명',
    course_description_not_found: '설명이 없습니다.',
    course_description_link_http_required: '링크 주소는 http:// 또는 https:// 를 포함해주십시오.',
    course_description_placeholder: '간단한 코스 설명을 입력해주십시오.\n코스를 소개하는 웹페이지의(블로그 등의 게시물) 주소를 포함해도 좋습니다.',
    course_description_overflow_message: '설명은 %d자 이내로 입력해주십시오.',
    save_as_new: '새 코스로 저장',
    new_course_from_file: '새 코스로 불러오기',
    new_section_from_file: '새 섹션으로 불러오기',
    message_for_select_file_type1: '파일 유형을 선택해주십시오.',
    message_for_select_file_type2: '사이클링 컴퓨터(Garmin Edge, Wahoo Element 등)에서 사용할 경우, tcx를 선택하는 것이 좋습니다. gpx는 웨이포인트가 표시되지 않을 수 있습니다.',
    message_for_course_name_empty: '* 코스명이 입력되지 않았습니다. 코스를 저장해주세요.',
    no_course_list: '검색된 코스가 없습니다.',
    loading_course_list: '코스 목록을 로드 중입니다.',
    sections: '섹션 목록',
    waypoint: '웨이포인트',
    waypoints: '웨이포인트 목록',
    waypoint_type: '유형',
    waypoint_name: '이름',
    waypoint_note: '메모',
    waypoint_position: '위치',
    waypoint_elevation: '고도',
    waypoint_edit: '편집',
    waypoint_deleted: '웨이포인트가 삭제되었습니다.',
    waypoint_set_visible_in_elevation_chart: '고도차트 표시',
    waypoint_set_all_default_visible_in_elevation_chart: '기본값으로',
    waypoint_show_all_default_visible_in_elevation_chart: '모두 표시',
    waypoint_hide_all_default_visible_in_elevation_chart: '모두 숨기기',
    waypoint_type_generic: '일반',
    waypoint_type_summit: '정상',
    waypoint_type_valley: '계곡',
    waypoint_type_water: '물',
    waypoint_type_food: '보급',
    waypoint_type_danger: '위험',
    waypoint_type_left: '좌회전',
    waypoint_type_right: '우회전',
    waypoint_type_straight: '직진',
    waypoint_type_first_aid: '응급처치',
    waypoint_type_category_1: '1등급 오르막',
    waypoint_type_category_2: '2등급 오르막',
    waypoint_type_category_3: '3등급 오르막',
    waypoint_type_category_4: '4등급 오르막',
    waypoint_type_category_h: '무제한급 오르막',
    waypoint_type_sprint: '스프린트',
    no_waypoints: '웨이포인트가 없습니다.',
    course_instruction_sharp_left: '약간 좌회전',
    course_instruction_sharp_right: '약간 우회전',
    course_instruction_slight_left: '약간 좌회전',
    course_instruction_slight_right: '약간 우회전',
    find_climbs_in_course: '코스에서 오르막 구간 찾기',
    find_climbs_in_course_short: '오르막 구간',
    login: '로그인',
    login_with_facebook: '페이스북 계정으로 로그인',
    login_with_kakao: '카카오 계정으로 로그인',
    login_with_google: '구글 계정으로 로그인',
    facebook_login_deprecated: 'Facebook Meta의 비즈니스 정책으로 인해 Facebook 계정으로 로그인 기능은 더이상 사용할 수 없습니다.\n\n동일한 이메일 주소를 사용하는 다른 서비스의 계정으로 로그인을 해주시기 바랍니다.\n\n다른 이메일 주소를 사용하는 계정으로 이전을 원하시는 사용자께서는 ridingazua@ridingazua.cc 로 메일을 보내주시기 바랍니다.',
    privacy_policy: '개인정보 취급 및 보호정책',
    hello_format: '안녕하세요! "%s"님!',
    goodbye: '안녕히가십시오.',
    logout: '로그아웃',
    zoom_in_map: '지도 확대',
    zoom_out_map: '지도 축소',
    my_location: '현 위치',
    finding_my_location: '현 위치를 탐색 중입니다.',
    elevation_chart: '고도차트',
    elevation_chart_image: '고도차트 이미지',
    image_width: '너비(px)',
    image_height: '높이(px)',
    opacity: '불투명도',
    no_data_for_elevation_chart: '코스가 아직 존재하지 않습니다.',
    section_new: '새 섹션 추가',
    section_paste: '섹션 붙여넣기',
    section_rename: '섹션명 변경',
    section_up: '위로 이동',
    section_down: '아래로 이동',
    section_duplicate: '복제',
    section_copy_to_clipboard: '복사(클립보드)',
    section_copied_to_clipboard: '섹션이 복사되었습니다. 다른 곳에서 붙여넣기하십시오.',
    section_copy_failed_by_empty: '비어있는 섹션은 복사할 수 없습니다.',
    section_copy_failed: '섹션을 복사하지 못하였습니다.',
    section_pasted: '섹션을 붙여넣기하였습니다.',
    section_paste_failed: '섹션을 붙여넣을 수 없습니다. 클립보드에 섹션 정보가 존재하지 않을 수 있습니다.',
    section_reverse: '뒤집기',
    section_merge_with_below: '아래 섹션과 병합',
    section_delete: '삭제',
    section_is_last: '마지막 섹션입니다.',
    section_must_be_at_least_one: '섹션은 한개 이상 존재해야합니다.',
    elevation_smooth: '보정',
    elevation_settings: '고도보정 설정',
    elevation_smooth_description: '* 경로 데이터에 포함된 고도 데이터는 실제와는 다소 차이가 있을 수 있습니다. 따라서 보정이 필요합니다.',
    elevation_smooth_level: '보정 레벨',
    elevation_smooth_level_reset: '기본값으로 변경',
    elevation_smooth_failed_by_no_original_elevations: '고도를 보정할 수 없습니다. 고도 새로고침을 실행한 후 다시 시도해주십시오.',
    elevation_chart_waypoints: '웨이포인트 표시/해제',
    elevation_reload: '고도 새로고침',
    elevation_chart_save_as_image: '이미지로 저장',
    elevation_chart_generate_image: '이미지 생성',
    elevation_chart_generate_image_description: '* 이미지는 차트의 모든 요소를 포함하지 못할 수도 있습니다. 그런 경우, 크기를 더 크게 설정한 후, 이미지를 다시 생성해 주십시오.',
    elevation_chart_show_all: '전체 보기',
    climbs: '오르막 구간',
    climb_category: '등급',
    climb_start: '시작 위치',
    climb_finish: '종료 위치',
    climb_name: '이름',
    climbs_not_found: '오르막 구간을 찾을 수 없습니다.',
    climb_select: '오르막 선택',
    climb_select_zoom: '오르막 선택 및 확대',
    elevation_gain: '상승 고도',
    length: '길이',
    average_slope: '평균 경사도',
    position_start: '시작점',
    position_finish: '종료점',
    map_settings: '지도/경로 설정',
    add_waypoint: '여기에 웨이포인트 추가',
    add_waypoint_type_format: '여기에 [%s] 웨이포인트 추가',
    split_section: '분할',
    draw_line_to_this_point: '여기로 경로 그리기',
    view_kakao_map_this_point: '카카오맵에서 보기',
    view_kakao_map_road_view_this_point: '카카오맵 로드뷰 보기',
    confirm_message_for_delete_course_format: '"%s" 코스를 삭제하시겠습니까?',
    course_deleted_format: '"%s" 코스가 삭제되었습니다.',
    login_required: '로그인이 필요합니다.',
    retry_after_save_course: '코스를 저장한 후에 다시 시도해주세요.',
    course_saving: '코스를 저장 중입니다.',
    course_name_required: '코스명을 입력해주십시오.',
    course_saved: '코스가 저장되었습니다.',
    course_id_required: '코스 ID가 필요합니다.',
    course_is_too_short: '코스가 너무 짧습니다.',
    all_fileds_required: '모든 값을 입력해주십시오.',
    failed_to_route: '경로를 생성하지 못하였습니다.',
    failed_to_load_course: '코스 로드를 실패하였습니다.',
    failed_to_load_file: '파일을 로드하지 못하였습니다.',
    failed_to_delete_course: '코스 삭제를 실패하였습니다.',
    failed_to_save_course: '코스 저장을 실패하였습니다.',
    failed_to_login: '로그인을 실패하였습니다.',
    failed_to_login_with_facebook: '페이스북 계정으로 로그인을 실패하였습니다.',
    failed_to_logout_with_facebook: '페이스북 로그아웃을 실패하였습니다.',
    failed_to_login_with_kakao: '카카오 계정으로 로그인을 실패하였습니다.',
    failed_to_logout_with_kakao: '카카오 로그아웃을 실패하였습니다.',
    failed_to_logout: '로그아웃을 실패하였습니다.',
    failed_to_save_user_info: '사용자 정보를 저장하지 못했습니다.',
    not_logged_in: '로그인되지 않았습니다.',
    invalid_request: '부정확한 요청입니다.',
    failed_to_find_user_in_facebook: '페이스북에서 사용자 정보를 찾지 못하였습니다.',
    failed_to_download: '다운로드를 실패하였습니다.',
    failed_to_my_location: '현 위치를 찾을 수 없습니다.',
    failed_to_load_course_list: '코스 목록을 로드하지 못하였습니다.',
    failed_to_reload_elevation: '고도 새로고침을 실패하였습니다.',
    course_not_owner: '현재 사용자는 코스의 소유자가 아닙니다.',
    course_private_not_owner: '로드하려는 코스는 비공개 상태이며, 현재 사용자는 코스의 소유자가 아닙니다.',
    course_url_private_not_owner_format: '로드하려는 코스는 비공개 상태이며, 현재 사용자는 코스의 소유자가 아닙니다.(%s)',
    course_url_is_not_found_format: '코스가 존재하지 않습니다.(%s)',
    course_url_is_not_supported_file_type_format: '지원하지 않는 파일 형식입니다.(%s)',
    user_nick: '닉네임',
    user_nick_setting: '닉네임 설정',
    user_nick_setting_description:
        '"라이딩가즈아" 서비스에서 사용할 닉네임을 설정해주십시오.<br><br>사용자가 작성한 컨텐츠 중 공개가 가능하도록 설정된 컨텐츠의 작성자 이름을 표기하는데 사용됩니다.<br><br>(전체공개 설정된 코스의 작성자 이름을 표기할 때에도 사용됩니다.)',
    user_nick_conflict: '"%s" 닉네임은 다른 사용자가 사용중입니다.',
    user_nick_placeholder: '닉네임을 입력해주십시오.',
    user_nick_required: '닉네임을 입력해주십시오.',
    user_nick_changed: '닉네임이 변경되었습니다.',
    anonymous: '익명',
    ad_manager: '광고 관리자',
    ad_element_info: '광고영역입니다.',
    ad_manager_new: '새 광고',
    ad_manager_edit_ad: '광고 편집',
    ad_manager_position: '광고 위치',
    ad_manager_current_showing: '현재 노출중',
    ad_manager_image_file: '이미지 파일',
    ad_manager_image_size_info: '가로 1000px 이상의 가로로 긴 이미지를 선택해주십시오.(width가 1000px일 경우, height는 500px 이내 권장)',
    ad_manager_image_size_warning: 'width 1000px 미만의 이미지는 고해상도 기기에서 화질 저하가 발생할 수 있습니다.',
    ad_manager_image_ratio_warning: 'width:height 비율이 1:0.5 초과 이미지는 광고가 노출되는 다이얼로그에 스크롤을 발생시킬 수 있습니다.',
    ad_manager_selected_image: '선택된 이미지',
    ad_manager_current_image: '현재 이미지',
    ad_manager_background_color: '배경 색상',
    ad_manager_background_color_placeholder: 'RGB Hex Code로 입력해주십시오.(예: "FFFFFF"는 흰색, "000000"은 검은색입니다.)',
    ad_manager_start_time: '광고 게시 시작 시간',
    ad_manager_start_time_placeholder1: '년.월.일 시(24시):분 형식으로 입력해주십시오.(예: 현재시각은 "%s" 입니다.) ',
    ad_manager_start_time_placeholder2: '생략할 경우, 즉시 노출 시작.',
    ad_manager_link: '링크',
    ad_manager_link_placeholder: '광고 클릭시 연결될 웹페이지의 URL을 입력해주십시오.',
    ad_manager_delete: '삭제',
    ad_manager_delete_confirm: '광고를 삭제하시겠습니까?',
    ad_manager_no_ad: '등록된 광고가 없습니다.',
    ad_manager_submitting: '등록 중입니다.',
    ad_manager_submitted: '광고가 등록되었습니다.',
    ad_manager_deleting: '삭제 중입니다.',
    ad_manager_deleted: '광고가 삭제되었습니다.',
    ad_manager_error_on_save: '광고 등록을 실패하였습니다.',
    ad_manager_error_on_delete: '광고 삭제를 실패하였습니다.',
    ad_manager_invalid_link: '올바른 링크가 설정되지 않았습니다.',
    ad_manager_image_not_found: '이미지가 첨부되지 않았습니다.',
    ad_manager_invalid_position: '광고 게시 위치가 설정되지 않았습니다.',
    ad_manager_invalid_start_time: '광고 시작시간이 올바르지 않습니다.',
    ad_manager_start_time_must_future: '광고 시작시각은 현재보다 이후이어야합니다.',
    advertisement_position_new_dialog: '[새 코스] 다이얼로그',
    advertisement_position_save_dialog: '[저장] 다이얼로그',
    advertisement_position_import_dialog: '[불러오기] 다이얼로그',
    advertisement_position_export_dialog: '[다운로드] 다이얼로그',
    advertisement_position_login_dialog: '[로그인] 다이얼로그',
    advertisement_position_unknown: '알수없음',

    ridingazua_editor_description: '사이클링, 라이딩을 위한 코스 편집기 - 라이딩가즈아!!!',
    ridingazua_editor_title: '코스 편집기 - 라이딩가즈아!',
    ridingazua_editor_keywords: '자전거, 사이클링, 라이딩, 코스편집기, gpx, tcx',
    ridingazua_course_description_format: '길이: %skm, 상승고도: %sm, Course edited by Ridingazua editor.',
    ridingazua_course_list_count_format: '%d개',
};

Resources.textResourcesEn = {
    ok: 'OK',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    apply: 'Apply',
    close: 'Close',
    enabled: 'Enabled',
    disabled: 'Disabled',
    settings: 'Settings',
    error: 'Error',
    error_occured: 'Error occured.',
    not_found_404: '404 - Not found! T_T',
    delete: 'Delete',
    important: 'Important Message',
    caution: 'Caution',
    warning: 'Warning',
    readme: 'Read me',
    dont_show_again: "Don't Show Again",
    help: 'Help',
    link: 'Link',
    copy: 'Copy',
    copied: 'Copied!',
    failed_to_copy: 'Failed to Copy.',
    prev: 'Prev',
    next: 'Next',
    go_to_page: 'Go to:',
    page: 'Page',
    course: 'Course',
    section: 'Section',
    not_saved: 'Not saved',
    selected_range: 'Selected Range',
    selected_map_section: 'Selected Range',
    auto_route: 'Auto Route',
    auto_route_profile: 'Auto Route Profile',
    auto_route_profile_cycling_recommended: 'Cycling',
    auto_route_profile_cycling_mountain: 'MTB',
    auto_route_profile_road: 'Road',
    auto_left_right: 'Auto Add Turn Left/Right',
    map_type: 'Map Type',
    map_type_osm: 'Open Street Map',
    map_type_google_road: 'Google(Road)',
    map_type_google_terrain: 'Google(Terrain)',
    map_type_google_satellite: 'Google(Satellite)',
    map_type_google_hybrid: 'Google(Hybrid)',
    map_type_overlay_image_map: 'Overlay Image Map',
    overlay_image_map_sttings: 'Overlay Image Map Settings',
    overlay_image_map_url_template: 'URL Template',
    overlay_image_map_url_template_invalid: 'Invalid URL template.',
    overlay_image_map_url_template_description: 'URL must start with "https://" or "http://" and must contain the strings "{zoom}", "{x}", "{y}".',
    new_course: 'New Course',
    open: 'Open',
    save: 'Save',
    import: 'Import',
    download: 'Download',
    undo: 'Undo',
    redo: 'Redo',
    preview: 'Preview',
    more_menus: 'More Menus',
    editor_locked: 'Editor Locked',
    editor_unlocked: 'Editor Unlocked',
    editor_did_locked_message: 'Now, edit course available.',
    editor_did_unlocked_message: 'Now, edit course unavailable.',
    editor_unlock_info_message: 'Editor locked. To edit, click unlock button right of course name.',
    search_place_by_google: 'Search Place by Google',
    search_place_by_kakao: 'Search Place by Kakao',
    search_place_clear: 'Clear Search Results',
    search_place_list: 'Search Results',
    search_place_current_bounds: 'In Current Bounds',
    search_place_korea_only: 'In Korea',
    failed_to_search_places: 'Failed to search places.',
    no_place_search_results: 'No search results',
    load_course: 'Load Course',
    confirm_message_for_lost: 'Unsaved data will be lost. Do you want continue?',
    course_list_search_keyword_placeholder: 'Search by tag or course name',
    course_list_my: 'My Courses',
    course_list_public: 'Public Courses',
    course_list_all_if_superuser: 'All Courses(for Admin)',
    course_list_user_format: "[%s]'s Public courses",
    course_list_suffix_search_result_format: 'Search results for "%s"',
    course_list: 'Courses',
    search: 'Search',
    distance: 'Distance',
    position: 'Position',
    elevation: 'Elevation',
    slope: 'Slope',
    course_noname: 'Noname',
    course_start: 'Start',
    course_finish: 'Finish',
    course_name: 'Name',
    course_owner_name: 'Author',
    course_length: 'Length',
    course_elevations: 'Elevations',
    course_info: 'Course Info',
    course_created: 'Created',
    course_updated: 'Updated',
    course_permission: 'Public',
    course_permission_link: 'Link',
    course_permission_private: 'Private',
    course_permission_link_public_read_only: 'Allow via Link',
    course_permission_all_public_read_only: 'Public',
    course_permission_description_private: 'Only owner can read and edit.',
    course_permission_description_link_public_read_only: 'Can be read by anyone has link(URL).',
    course_permission_description_all_public_read_only: 'Anyone can read. Course may be posted within the service. If you want share to everyone, choose this option.',
    course_more: 'etc',
    course_tag: 'Tags',
    course_tag_placeholder: 'Input tags.',
    course_tag_overflow_message: 'Input tag within %d characters.',
    course_tag_not_found: 'No tags.',
    course_description: 'Description',
    course_description_not_found: 'No course description.',
    course_description_link_http_required: 'Link address must start with http:// or https://.',
    course_description_placeholder: 'Input course description.\nYou can also include the webpage(a content such as a blog) URL that introduces this course.',
    course_description_overflow_message: 'Input description within %d characters.',
    save_as_new: 'Save as New Course',
    new_course_from_file: 'New course from file',
    new_section_from_file: 'New section from file',
    message_for_select_file_type1: 'Select file type.',
    message_for_select_file_type2: 'For use on cycling computers(Garmin Edge, Wahoo Element, etc...), "tcx" is recommended. Waypoints may not be displayed when using "gpx".',
    message_for_course_name_empty: '* Course name is empty. Save course.',
    no_course_list: 'No course list.',
    loading_course_list: 'Loading course list.',
    sections: 'Sections',
    waypoint: 'Waypoint',
    waypoints: 'Waypoints',
    waypoint_type: 'Type',
    waypoint_name: 'Name',
    waypoint_note: 'Note',
    waypoint_position: 'Position',
    waypoint_elevation: 'Elevation',
    waypoint_edit: 'Edit',
    waypoint_deleted: 'Waypoint deleted.',
    waypoint_set_visible_in_elevation_chart: 'Show In Elevation Chart',
    waypoint_set_all_default_visible_in_elevation_chart: 'Set Defaults',
    waypoint_show_all_default_visible_in_elevation_chart: 'Show All',
    waypoint_hide_all_default_visible_in_elevation_chart: 'Hide All',
    waypoint_type_generic: 'Generic',
    waypoint_type_summit: 'Summit',
    waypoint_type_valley: 'Valley',
    waypoint_type_water: 'Water',
    waypoint_type_food: 'Food',
    waypoint_type_danger: 'Danger',
    waypoint_type_left: 'Turn Left',
    waypoint_type_right: 'Turn Right',
    waypoint_type_straight: 'Go Straight',
    waypoint_type_first_aid: 'First Aid',
    waypoint_type_category_1: '1st Category',
    waypoint_type_category_2: '2nd Category',
    waypoint_type_category_3: '3rd Category',
    waypoint_type_category_4: '3th Category',
    waypoint_type_category_h: 'Hors Category',
    waypoint_type_sprint: 'Sprint',
    no_waypoints: 'No waypoints.',
    course_instruction_sharp_left: 'Sharp Left',
    course_instruction_sharp_right: 'Sharp Right',
    course_instruction_slight_left: 'Slight Left',
    course_instruction_slight_right: 'Slight Right',
    find_climbs_in_course: 'Find Climbs in Course',
    find_climbs_in_course_short: 'Climbs',
    login: 'Login',
    login_with_facebook: 'Login with Facebook',
    login_with_kakao: 'Login with Kakao',
    login_with_google: 'Login with Google',
    facebook_login_deprecated: 'Due to Facebook Meta\'s business policies, "Login with Facebook" is not available. \n\nPlease log in with other service using the same email address. \n\nIf you want to migrate your account to other email address, please send an email to "ridingazua@ridingazua.cc".',
    privacy_policy: 'Privacy Policy',
    hello_format: 'Hello! "%s"!',
    goodbye: 'Goodbye!',
    logout: 'Log Out',
    zoom_in_map: 'Zoom In',
    zoom_out_map: 'Zoom Out',
    my_location: 'My Location',
    finding_my_location: 'Finding my location.',
    elevation_chart: 'Elevation Chart',
    elevation_chart_image: 'Elevation Chart Image',
    image_width: 'Width(px)',
    image_height: 'Height(px)',
    opacity: 'Opacity',
    no_data_for_elevation_chart: 'No data for elevation chart.',
    section_new: 'New section',
    section_paste: 'Paste section',
    section_rename: 'Rename',
    section_up: 'Move Up',
    section_down: 'Move Down',
    section_duplicate: 'Duplicate',
    section_copy_to_clipboard: 'Copy(to clipboard)',
    section_copied_to_clipboard: 'Section copied. You can paste section to other course.',
    section_copy_failed_by_empty: "Can't copy empty section.",
    section_copy_failed: 'Failed to copy section.',
    section_pasted: 'Section pasted.',
    section_paste_failed: 'Failed to paste section. Section data may not exist on the clipboard.',
    section_reverse: 'Reverse',
    section_merge_with_below: 'Merge with Below',
    section_delete: 'Delete',
    section_is_last: 'The section is last.',
    section_must_be_at_least_one: 'There must be at least one section.',
    elevation_smooth: 'Smooth',
    elevation_settings: 'Elevation Settings',
    elevation_smooth_description: '* The elevation data in the route data may differ from the actual. Therefore, smoothing is required.',
    elevation_smooth_level: 'Smooth Level',
    elevation_smooth_level_reset: 'Reset Level',
    elevation_smooth_failed_by_no_original_elevations: 'Elevation smooth failed. Please retry after reload elevations',
    elevation_chart_waypoints: 'Show/Hide Waypoints',
    elevation_reload: 'Reload',
    elevation_chart_save_as_image: 'Save as Image',
    elevation_chart_generate_image: 'Generate Image',
    elevation_chart_generate_image_description: '* Chart image may not contain all elements. In that case, resize to larger and re-generate the image.',
    elevation_chart_show_all: 'Show All',
    climbs: 'Climbs',
    climb_category: 'Cat.',
    climb_start: 'Start',
    climb_finish: 'Finish',
    climb_name: 'Name',
    climbs_not_found: 'Climbs not found.',
    climb_select: 'Select Climb',
    climb_select_zoom: 'Select And Zoom Climb',
    elevation_gain: 'Gain',
    length: 'Length',
    average_slope: 'Slope',
    position_start: 'Start',
    position_finish: 'Finish',
    map_settings: 'Map/Route Settings',
    add_waypoint: 'Add Waypoint',
    add_waypoint_type_format: 'Add [%s] Waypoint',
    split_section: 'Split',
    draw_line_to_this_point: 'Draw Line to This Point',
    view_kakao_map_this_point: 'Kakapmap',
    view_kakao_map_road_view_this_point: 'Kakaomap Roadview',
    confirm_message_for_delete_course_format: 'Delete course "%s"?',
    course_deleted_format: 'Course "%s" deleted.',
    login_required: 'Login required.',
    retry_after_save_course: 'Please retry after save course.',
    course_saving: 'Course is saving...',
    course_name_required: 'Course name is required.',
    course_saved: 'Course saved.',
    course_id_required: 'Course ID required',
    course_is_too_short: 'Course is too short.',
    all_fileds_required: 'Values of all fields are required.',
    failed_to_route: 'Failed to route.',
    failed_to_load_course: 'Failed to load course.',
    failed_to_load_file: 'Failed to load file.',
    failed_to_delete_course: 'Failed to delete course.',
    failed_to_save_course: 'Failed to save course.',
    failed_to_login: 'Failed to login.',
    failed_to_login_with_facebook: 'Failed to login with facebook.',
    failed_to_logout_with_facebook: 'Failed to log out with facebook.',
    failed_to_login_with_kakao: 'Failed to login with kakao.',
    failed_to_logout_with_kakao: 'Failed to log out with kakao.',
    failed_to_logout: 'Failed to log out.',
    failed_to_save_user_info: 'Failed to save user info.',
    failed_to_find_user_in_facebook: 'Failed to find user in facebook.',
    failed_to_download: 'Failed to download.',
    failed_to_my_location: 'Failed to find my location.',
    failed_to_load_course_list: 'Failed to load course list.',
    failed_to_reload_elevation: 'Failed to reload elevations.',
    not_logged_in: 'Not logged in',
    invalid_request: 'Invalid request.',
    course_not_owner: 'You are not course owner.',
    course_private_not_owner: 'The course trying to load is private. And you are not course owner.',
    course_url_private_not_owner_format: 'The course trying to load is private. And you are not course owner.(%s)',
    course_url_is_not_found_format: 'Course is not found.(%s)',
    course_url_is_not_supported_file_type_format: 'Not supported file format.(%s)',
    user_nick: 'Nickname',
    user_nick_setting: 'Change Nickname',
    user_nick_setting_description: 'Please set nickname  to be used in the "ridingazua.cc".<br><br>It is used to display the name of the author of the content set to be public.',
    user_nick_conflict: '"%s" is being used by another user.',
    user_nick_placeholder: 'Input nickname.',
    user_nick_required: 'Input nickname.',
    user_nick_changed: 'Nickname changed.',
    anonymous: 'Anonymous',
    ad_manager: 'AD Manager',
    ad_element_info: 'This is AD area.',
    ad_manager_new: 'New ad',
    ad_manager_edit_ad: 'Edit ad',
    ad_manager_position: 'Advertisement position',
    ad_manager_current_showing: 'Showing',
    ad_manager_image_file: 'Image file',
    ad_manager_image_size_info: 'Please select a horizontally long image with a width of 1000px or more. (If the width is 1000px, the height is 500px or less)',
    ad_manager_image_size_warning: 'Images with a width of less than 1000px may cause degradation on high-resolution devices.',
    ad_manager_image_ratio_warning: 'Images with a width:height ratio greater than 1:0.5 may cause scrolling in the dialog where the ad is displayed.',
    ad_manager_selected_image: 'Selected image',
    ad_manager_current_image: 'Current image',
    ad_manager_background_color: 'Background color',
    ad_manager_background_color_placeholder: 'Input RGB Hex Code format.(eg. "FFFFFF" is white, "000000" is black.)',
    ad_manager_start_time: 'Start time',
    ad_manager_start_time_placeholder1: 'Input Year.Month.Day 24Hour:Minute format.(eg. Current time is "%s".)',
    ad_manager_start_time_placeholder2: "If you don't enter it, the ad will be displayed right now.",
    ad_manager_link: 'Link',
    ad_manager_link_placeholder: 'Input URL to be opened when click advertisement.',
    ad_manager_delete: 'Delete',
    ad_manager_delete_confirm: 'Do you want delete advertisement?',
    ad_manager_no_ad: 'No ads.',
    ad_manager_submitting: 'Saving ad...',
    ad_manager_submitted: 'Advertisement added.',
    ad_manager_deleting: 'Deleting ad...',
    ad_manager_deleted: 'Ad deleted.',
    ad_manager_error_on_save: 'Failed to save ad.',
    ad_manager_error_on_delete: 'Failed to delete ad.',
    ad_manager_invalid_link: 'Invalid link URL.',
    ad_manager_image_not_found: 'Advertisement image is not found.',
    ad_manager_invalid_position: 'Invalid advertisement position.',
    ad_manager_invalid_start_time: 'Invalid start time.',
    ad_manager_start_time_must_future: 'Start time must be future.',
    advertisement_position_new_dialog: '[New Course] Dialog',
    advertisement_position_save_dialog: '[Save] Dialog',
    advertisement_position_import_dialog: '[Import] Dialog',
    advertisement_position_export_dialog: '[Download] Dialog',
    advertisement_position_login_dialog: '[Login] Dialog',
    advertisement_position_unknown: 'Unknown',

    ridingazua_editor_description: 'Course editor for cycling, riding - RIDINGAZUA!!!',
    ridingazua_editor_title: 'Ridingazua! Editor',
    ridingazua_editor_keywords: 'cycling, riding, course, editor, gpx, tcx',
    ridingazua_course_description_format: 'Distance: %skm, Elevation: %sm, Course edited by Ridingazua editor.',
    ridingazua_course_list_count_format: '%d',
};
