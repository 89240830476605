import { SmoothElevationTask } from "./Ridingazua.ElevationChartController";
import { Resources } from "./Ridingazua.Resources";
import { ApplicationState, ApplicationEvent } from "./Ridingazua.ApplicationState";

export class ElevationSettingsDialogController {
    private static instance: ElevationSettingsDialogController;
    private dialog: JQuery;
    private sliderSmoothLevel: JQuery;
    private divSliderSmoothLevelHandle: HTMLDivElement;

    private constructor() {
        let div = document.createElement('div');

        let dl = document.createElement('dl');
        div.appendChild(dl);

        let dt = document.createElement('dt');
        dl.appendChild(dt);

        let labelLevel = document.createElement('label');
        labelLevel.textContent = Resources.text.elevation_smooth_level;
        let sliderSmoothLevel = 'slider-smooth-level';
        labelLevel.setAttribute('for', sliderSmoothLevel);
        dt.appendChild(labelLevel);

        let dd = document.createElement('dd');
        dd.classList.add('center');
        dl.appendChild(dd);

        let divSliderSmoothLevel = document.createElement('div');
        divSliderSmoothLevel.style.width = '80%';
        divSliderSmoothLevel.style.marginTop = '10px';
        divSliderSmoothLevel.classList.add('slider');
        dd.appendChild(divSliderSmoothLevel);

        let divSliderSmoothLevelHandle = document.createElement('div');
        divSliderSmoothLevelHandle.classList.add('handle');
        divSliderSmoothLevelHandle.classList.add('ui-slider-handle');
        divSliderSmoothLevel.appendChild(divSliderSmoothLevelHandle);
        this.divSliderSmoothLevelHandle = divSliderSmoothLevelHandle;

        this.sliderSmoothLevel = $(divSliderSmoothLevel).slider({
            min: 5,
            max: 50,
            value: SmoothElevationTask.elevationSmoothLevel,
            slide: (_, sliderParams) => {
                this.divSliderSmoothLevelHandle.textContent = `${sliderParams.value}`;
            }
        });

        let pSmoothLevelDescription = document.createElement('p');
        pSmoothLevelDescription.style.marginTop = '20px';
        pSmoothLevelDescription.textContent = Resources.text.elevation_smooth_description;
        div.appendChild(pSmoothLevelDescription);

        this.dialog = $(div).dialog({
            title: Resources.text.elevation_settings,
            modal: true,
            width: 250,
            buttons: [
                {
                    text: Resources.text.elevation_smooth_level_reset,
                    click: () => {
                        this.sliderSmoothLevel.slider('value', SmoothElevationTask.defaultElevationSmoothLevel);
                        this.divSliderSmoothLevelHandle.textContent = `${SmoothElevationTask.defaultElevationSmoothLevel}`;
                    }
                },
                {
                    text: Resources.text.ok,
                    click: () => {
                        this.save();
                        this.dialog.dialog('close');
                    }
                }
            ]
        });
    }

    static show() {
        if (!this.instance) {
            this.instance = new ElevationSettingsDialogController();
        }

        this.instance.show();
    }

    private show() {
        this.update();
        this.dialog.dialog('open');
    }

    private save() {
        SmoothElevationTask.elevationSmoothLevel = this.sliderSmoothLevel.slider('value');
        ApplicationState.course.smoothElevations(SmoothElevationTask.elevationSmoothLevel);
        ApplicationState.executeListeners(ApplicationEvent.COURSE_ELEVATIONS_SMOOTHED);
    }

    private update() {
        this.divSliderSmoothLevelHandle.textContent = `${SmoothElevationTask.elevationSmoothLevel}`;
        this.sliderSmoothLevel.slider('value', SmoothElevationTask.elevationSmoothLevel);
    }
}