import { StorageController } from './Ridingazua.StorageController';
import { Resources } from './Ridingazua.Resources';
import { Statics } from '../common/Ridingazua.Statics';
import { CommonDialogController } from './Ridingazua.CommonDialogController';

export class ReadmeDialogController {
    private static get dontShowAgain() {
        return StorageController.get('readme_dont_show_again') === 'true';
    }

    private static set dontShowAgain(value: boolean) {
        StorageController.set('readme_dont_show_again', value ? 'true' : 'false');
    }

    static async showIfRequired() {
        if (this.dontShowAgain) {
            return;
        }

        let currentURL = new URL(location.href);
        let readmeURL = new URL(`${currentURL.protocol}//${currentURL.hostname}${Statics.editorPathFor('readme.html')}`);
        readmeURL.port = currentURL.port;

        CommonDialogController.showConfirm(
            Resources.text.readme,
            readmeURL,
            [
                {
                    text: Resources.text.close,
                    action: () => {
                        // do nothing
                    },
                },
                {
                    text: Resources.text.dont_show_again,
                    action: () => {
                        ReadmeDialogController.dontShowAgain = true;
                    },
                }
            ]
        );
    }
}
