import { HTMLUtility } from './Ridingazua.HTMLUtility';

export class MenuController {
    static zIndexForContextMenu = 10000;
    
    private static showings: MenuController[] = [];

    private _menuItems: MenuItem[];

    get menuItems(): MenuItem[] {
        return this._menuItems;
    }

    set menuItems(value: MenuItem[]) {
        this._menuItems = value;
        this.update();
    }

    readonly div: HTMLDivElement;

    get isShowing(): boolean {
        return this.div.parentElement != null;
    }

    menu?: JQuery;

    onMouseover?: () => void;
    onDismiss?: () => void;

    constructor(menuItems: MenuItem[], menuStyleClass?: string) {
        if (!menuStyleClass) {
            menuStyleClass = 'contextmenu';
        }

        let div = document.createElement('div');
        if (menuStyleClass) {
            div.classList.add(menuStyleClass);
        }
        div.onmouseover = () => {
            if (this.onMouseover) {
                this.onMouseover();
            }
        };
        this.div = div;

        this.menuItems = menuItems;
    }

    private update() {
        this.menu?.menu('destroy');
        this.div.innerHTML = '';
        let ul = this.addSubmenuListAndItems(this.div, this.menuItems);

        let options = {
            items: '> :not(.ui-widget-header)',
            select: (_, ui) => {
                let itemId = ui.item.attr('item-id');
                for (let menuItem of this.menuItems) {
                    this.itemById(menuItem, itemId)?.action();
                }
                this.dismiss();
            },
        };

        this.menu = $(ul).menu(options);
    }

    private addSubmenuListAndItems(targetElement: HTMLElement, menuItems: MenuItem[]): HTMLUListElement {
        if (!menuItems?.length) {
            return;
        }

        let ul = document.createElement('ul');
        targetElement.appendChild(ul);

        for (let menuItem of menuItems) {
            let li = document.createElement('li');
            li.setAttribute('item-id', menuItem.id);

            if (menuItem.isHeader) {
                li.classList.add('ui-widget-header');
            }

            if (menuItem.isSelected) {
                li.classList.add('selected');
            }

            let titleDiv = document.createElement('div');
            titleDiv.innerText = menuItem.name;
            li.appendChild(titleDiv);
            ul.appendChild(li);

            this.addSubmenuListAndItems(li, menuItem.subMenuItems);
        }

        return ul;
    }

    private itemById(rootMenuItem: MenuItem, itemId: string): MenuItem | null {
        if (rootMenuItem.id === itemId) {
            return rootMenuItem;
        }

        let subMenuItems = rootMenuItem.subMenuItems;
        if (subMenuItems) {
            for (let subMenuItem of subMenuItems) {
                let result = this.itemById(subMenuItem, itemId);
                if (result) {
                    return result;
                }
            }
        }

        return null;
    }
    
    /**
     * 메뉴를 노출한다.
     * @param targetElement 메뉴를 띄우기 위해 클릭된 element
     */
    show(targetElement: HTMLElement, options?: MenuControllerOptions) {
        MenuController.dismissAll();

        let body = document.body;
        this.div.style.position = 'absolute';
        this.div.style.zIndex = `${MenuController.zIndexForContextMenu}`;
        this.div.style.visibility = 'hidden';
        body.appendChild(this.div);

        let menuRect = this.div.getBoundingClientRect();
        let bodyRect = body.getBoundingClientRect();

        let targetElementRect = targetElement.getBoundingClientRect();

        if (!options?.position) {
            let targetX = targetElementRect.right - 5 + (options?.offsetX || 0);
            let targetY = targetElementRect.bottom - 5 + (options?.offsetY || 0);

            if (targetX + menuRect.width > bodyRect.right) {
                targetX = targetElementRect.left - menuRect.width + 5 - (options?.offsetX || 0);
            }

            if (targetY + menuRect.height > bodyRect.bottom) {
                targetY = targetElementRect.top - menuRect.height + 5 - (options?.offsetY || 0);
            }

            let overX = -targetX;
            let overY = -targetY;

            if (overX > 0) {
                targetX = 0;
            }

            if (overY > 0) {
                targetY = 0;
            }

            this.div.style.left = `${targetX}px`;
            this.div.style.top = `${targetY}px`;
        } else if (options?.position == 'bottom') {
            let targetX = targetElementRect.left;
            let targetY = targetElementRect.bottom;
            let targetWidth = targetElementRect.width;

            if (targetY + menuRect.height > bodyRect.bottom) {
                targetY = targetElementRect.top - menuRect.height;
            }

            this.div.style.left = `${targetX}px`;
            this.div.style.width = `${targetWidth}px`;
            this.div.style.top = `${targetY}px`;
        }

        this.div.style.visibility = '';

        MenuController.showings.push(this);
    }

    /**
     * 메뉴를 특정한 element 내부에 노출한다.
     * @param containerElement
     * @param x
     * @param y
     * @param margin
     */
    showIn(containerElement: Element, x: number, y: number, margin: number = 0) {
        MenuController.dismissAll();
        HTMLUtility.showElementInContainer(this.div, containerElement, x, y, MenuController.zIndexForContextMenu, margin);
        MenuController.showings.push(this);
    }

    /**
     * 제거한다.
     */
    dismiss() {
        this.div.remove();
        this.onDismiss && this.onDismiss();

        let index = MenuController.showings.indexOf(this);
        if (index >= 0) {
            MenuController.showings.splice(index, 1);
        }
    }

    static dismissAll() {
        // item의 dismiss 함수에서 this.showings의 변형이 일어나므로, 역순 루프로 돌린다.
        for (let i = this.showings.length - 1; i >= 0; i--) {
            this.showings[i].dismiss();
        }
    }

    static get isShowing(): boolean {
        return this.showings.length > 0;
    }
}

export interface MenuControllerOptions {
    position?: null | 'bottom';
    offsetX?: number;
    offsetY?: number;
}

export interface MenuItem {
    id: string;
    name: string;
    isHeader?: boolean;
    isSelected?: boolean;
    forEditing?: boolean;
    subMenuItems?: MenuItem[];
    action?: () => void;
}

window.onkeydown = (event) => {
    let key = event.key.toLowerCase();
    switch (key) {
        case 'escape':
        case 'esc':
            MenuController.dismissAll();
            break;
    }
};
