import { MapSection, Point } from "../common/Ridingazua.Model";
import { HTMLUtility } from "./Ridingazua.HTMLUtility";
import { ApplicationState, ApplicationEvent, ApplicationEventListener } from "./Ridingazua.ApplicationState";
import { MapController } from "./Ridingazua.MapController";
import { Resources } from "./Ridingazua.Resources";

export class SelectedMapSectionController implements ApplicationEventListener {
    private static instance: SelectedMapSectionController;
    private div: HTMLDivElement
    private pDescription: HTMLParagraphElement;
    private selectedMapSection?: MapSection;

    static set selectedMapSection(value: MapSection | null) {
        if (this.selectedMapSection === value) {
            return;
        }

        this.instance.selectedMapSection = value;
        ApplicationState.executeListeners(
            value ? ApplicationEvent.SELECT_MAP_SECTION : ApplicationEvent.DESELECT_MAP_SECTION,
            value
        )
    }

    static get selectedMapSection(): MapSection | null {
        return this.instance?.selectedMapSection;
    }

    private constructor() {
        this.createDivSelectedMapSection();
        ApplicationState.addListener(this);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.SELECT_MAP_SECTION:
            case ApplicationEvent.DESELECT_MAP_SECTION:
                this.updateDivSelectedMapSection();
                this.updateMapObjects();
                break;
        }
    }

    static createInstance(): SelectedMapSectionController {
        if (!this.instance) {
            this.instance = new SelectedMapSectionController();
        }

        return this.instance;
    }

    private createDivSelectedMapSection(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('box');
        div.classList.add('middle');
        div.classList.add('children-spacing')
        this.div = div;

        let label = document.createElement('label');
        label.textContent = `${Resources.text.selected_map_section}: `;
        div.appendChild(label);

        let p = document.createElement('p');
        p.style.display = 'inline';
        div.appendChild(p);
        this.pDescription = p;

        let removeButton = HTMLUtility.createIconButton('Remove', 'close', () => {
            this.selectedMapSection = null;
            ApplicationState.executeListeners(ApplicationEvent.DESELECT_MAP_SECTION);
        });
        removeButton.classList.add('small');
        div.appendChild(removeButton);

        return div;
    }

    private updateDivSelectedMapSection() {
        this.div.remove();

        if (!this.selectedMapSection) {
            return;
        }

        let lengthKm = (this.selectedMapSection.lengthMeter / 1000).toFixed(1) + 'km';
        let avgSlopePercent = (this.selectedMapSection.averageSlope * 100).toFixed(1) + '%';
        this.pDescription.textContent = `${lengthKm}, ${avgSlopePercent}`;

        let divRightBottom = document.getElementById('div-right-bottom');
        if (divRightBottom.children.length) {
            divRightBottom.insertBefore(this.div, divRightBottom.children.item(0));
        } else {
            divRightBottom.appendChild(this.div);
        }
    }

    private line?: google.maps.Polyline;
    private markers: google.maps.Marker[];

    private updateMapObjects() {
        this.line?.setMap(null);

        this.markers?.forEach(marker => {
            marker.setMap(null);
        });

        this.line = null;
        this.markers = [];

        let mapSection = this.selectedMapSection;
        if (!mapSection) {
            return;
        }

        let map = ApplicationState.map;
        let points = mapSection.points;
        let path = points.map(point => { return new google.maps.LatLng(point.latitude, point.longitude) });

        let line = new google.maps.Polyline({
            geodesic: true,
            strokeColor: '#990099',
            strokeOpacity: 1.0,
            strokeWeight: 4,
            zIndex: MapController.zIndexForSelectedMapSection,
            map: map,
            path: path,
            draggable: false,
            editable: false,
        });

        line.addListener('mousemove', event => {
            let latLng = event.latLng as google.maps.LatLng;
            // TODO
        });

        line.addListener('mouseout', event => {
            ApplicationState.executeListeners(ApplicationEvent.REMOVE_CURSOR);
        });

        line.addListener('click', (event) => {
            let latLng = event.latLng as google.maps.LatLng;
            // TODO
        });

        line.addListener('rightclick', (event) => {
            let latLng = event.latLng as google.maps.LatLng;
            // TODO
        });

        this.line = line;

        this.markers.push(
            MapController.createStartOrEndPointMarker(true, points[0])
        );

        this.markers.push(
            MapController.createStartOrEndPointMarker(false, points[points.length - 1])
        );

        MapController.setVisibleAllPoints(points);
    }
}