export function isNothing(value: any | null | undefined): boolean {
    return value === null || value === undefined || (typeof value === 'number' && isNaN(value));
}

export class Utility {
    private static notAllowedFilenameRegexr = /[$&+,:;=?@#|'<>.^*()%!~\/\\\s\n]/g;

    /**
     * path 이름으로 사용하기에 적합한지 검사 (허용되지 않는 문자가 들어있는지를 검사한다.)
     * @param text
     */
    static isSafeTextForPathName(text: string): boolean {
        let matches = text.match(this.notAllowedFilenameRegexr);
        return !matches || matches.length == 0;
    }

    static replaceNotAllowedFilenameCharactors(filename: string): string {
        return filename.replace(this.notAllowedFilenameRegexr, '_');
    }

    static encodeRFC5987ValueChars(text: string) {
        return (
            encodeURIComponent(text)
                // Note that although RFC3986 reserves "!", RFC5987 does not,
                // so we do not need to escape it
                .replace(/['()]/g, escape) // i.e., %27 %28 %29
                .replace(/\*/g, '%2A')
                // The following are not required for percent-encoding per RFC5987,
                // so we can allow for a little better readability over the wire: |`^
                .replace(/%(?:7C|60|5E)/g, unescape)
        );
    }

    /**
     * 두 좌표 지점간의 거리
     * @param latitude1
     * @param longitude1
     * @param latitude2
     * @param longitude2
     */
    static distanceMeterBetween(latitude1: number, longitude1: number, latitude2: number, longitude2: number): number {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(latitude2 - latitude1); // deg2rad below
        var dLon = this.deg2rad(longitude2 - longitude1);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(latitude1)) * Math.cos(this.deg2rad(latitude2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d * 1000; // Distance in meter
    }

    static deg2rad(deg: number): number {
        return deg * (Math.PI / 180);
    }

    /**
     * 점과 선분의 직교 거리를 구한다.
     * @param x 점의 x
     * @param y 점의 y
     * @param x1 선분 시작점의 x
     * @param y1 선분 시작점의 y
     * @param x2 선분 끝점의 x
     * @param y2 선분 끝점의 y
     */
    static pointLineDistance(x: number, y: number, x1: number, y1: number, x2: number, y2: number): number {
        var A = x - x1;
        var B = y - y1;
        var C = x2 - x1;
        var D = y2 - y1;

        var dot = A * C + B * D;
        var len_sq = C * C + D * D;
        var param = -1;
        if (len_sq != 0)
            //in case of 0 length line
            param = dot / len_sq;

        var xx, yy;

        if (param < 0) {
            xx = x1;
            yy = y1;
        } else if (param > 1) {
            xx = x2;
            yy = y2;
        } else {
            xx = x1 + param * C;
            yy = y1 + param * D;
        }

        var dx = x - xx;
        var dy = y - yy;

        return Math.sqrt(dx * dx + dy * dy);
    }

    /**
     * 점이 도형안에 포함되는지를 반환
     * @param point
     * @param polygon
     */
    static pointInPolygin(point: number[], polygon: number[][]) {
        var x = point[0],
            y = point[1];

        var inside = false;
        for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            var xi = polygon[i][0],
                yi = polygon[i][1];
            var xj = polygon[j][0],
                yj = polygon[j][1];

            var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) inside = !inside;
        }

        return inside;
    }

    /**
     * 정해진 속도로 정해진 거리에 도달하는데 걸리는 소요 시간
     * @param speedKph
     * @param distanceMeter
     */
    static computeDurationMillisec(speedKph: number, distanceMeter: number): number {
        var r = distanceMeter / (speedKph * 1000);
        return r * (60 * 60 * 1000);
    }

    static parseCourseXml(text: string): CourseXml | null {
        let parser = new DOMParser();
        let document: Document = null;
        let type: string = null;
        try {
            document = parser.parseFromString(text, 'text/xml');
            if (!document) {
                return null;
            }

            let rootNode = document.getRootNode();
            let firstTagName = (rootNode.childNodes[0] as Element).tagName;

            if (firstTagName == 'gpx') {
                type = 'gpx';
            } else if (firstTagName == 'TrainingCenterDatabase') {
                type = 'tcx';
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }

        return { type: type, document: document };
    }

    static encodeForElementTextContent(text: string): string {
        let findReplace: any[][] = [
            [/&/g, '&amp;'],
            [/</g, '&lt;'],
            [/>/g, '&gt;'],
            [/"/g, '&quot;'],
        ];
        let result = text;
        for (let item in findReplace) {
            result = result.replace(findReplace[item][0], findReplace[item][1]);
        }
        return result;
    }

    static encodeForElementAttributeValue(text: string): string {
        let findReplace: any[][] = [
            [/'/g, '&#x27;'],
            [/"/g, '&#x22;'],
        ];
        let result = text;
        for (let item in findReplace) {
            result = result.replace(findReplace[item][0], findReplace[item][1]);
        }
        return result;
    }
}

export interface CourseXml {
    type: string;
    document: Document;
}