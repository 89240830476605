export class KeyState {
    static shiftKey = false;
    static ctrlKey = false;
    static metaKey = false;
    
    static update(event: KeyboardEvent) {
        this.shiftKey = event.shiftKey;
        this.ctrlKey = event.ctrlKey;
        this.metaKey = event.metaKey;
    }
}