import { MenuController, MenuItem } from './Ridingazua.MenuController';
import { ClimbListDialogController } from './Ridingazua.ClimbListDialogController';
import { Resources } from './Ridingazua.Resources';
import { WaypointListDialogController, WaypointListDialogType } from './Ridingazua.WaypointListDialogController';
import { SectionListDialogController } from './Ridingazua.SectionListDialogController';
import { ApplicationState, ApplicationEvent } from './Ridingazua.ApplicationState';
import { SessionController } from './Ridingazua.SessionController';
import { LoginDialogController } from './Ridingazua.LoginDialogController';
import { CourseAnalyzer } from '../common/Ridingazua.CourseAnalyzer';
import { SegmentListInMapController } from './Ridingazua.SegmentListInMapController';
import { CommonDialogController } from './Ridingazua.CommonDialogController';
import { APIManager } from './Ridingazua.APIManager';
import { Utility } from '../common/Ridingazua.Utility';
import { Statics } from '../common/Ridingazua.Statics';
import { CourseListDialogController } from './Ridingazua.CourseListDialogController';
import { AdManagerDialogController } from './Ridingazua.AdManagerDialogController';
import { UserNickDialogController } from './Ridingazua.UserNickDialogController';
import { CourseListConfiguration } from '../common/Ridingazua.Model';

/**
 * 상단 툴바에 추가적으로 로드할 메뉴들의 컨트롤러
 */
export class MoreMenuController {
    private static menuController = new MenuController([]);

    private static defaultMenuItemsForMoreMenu: MenuItem[] = [
        {
            id: 'sections',
            name: Resources.text.sections,
            action: () => {
                SectionListDialogController.toggle();
            },
        },
        {
            id: 'waypoints',
            name: Resources.text.waypoints,
            action: () => {
                WaypointListDialogController.toggle(WaypointListDialogType.NORMAL);
            },
        },
        {
            id: 'climbs',
            name: Resources.text.find_climbs_in_course,
            action: () => {
                ClimbListDialogController.show();
            },
        },
    ];

    private static menuItemForLogin: MenuItem = {
        id: 'login',
        name: Resources.text.login,
        action: () => {
            LoginDialogController.show();
        },
    };

    private static menuItemForUserNick: MenuItem = {
        id: 'userNick',
        name: Resources.text.user_nick_setting,
        action: () => {
            UserNickDialogController.show();
        },
    };

    private static menuItemForLogout: MenuItem = {
        id: 'logout',
        name: Resources.text.logout,
        action: () => {
            SessionController.logout();
        },
    };

    /**
     * 실험적인 기능들, 관리자 권한이 있는자만이 사용 가능
     */
    private static menuItemsForLabs: MenuItem[] = [
        {
            id: 'labs',
            name: 'Labs',
            isHeader: true,
        },
        {
            id: 'adManager',
            name: 'AD Manager',
            action: () => {
                AdManagerDialogController.show();
            },
        },
        {
            id: 'allCourseList',
            name: 'All Courses',
            action: () => {
                let configuration = CourseListConfiguration.defaultConfiguration();
                configuration.loadAllIfSuperuser = true;
                CourseListDialogController.showWithConfiguration(configuration);
            },
        },
        {
            id: 'anlayzeCourse',
            name: 'Analyze Course',
            action: () => {
                let analyzer = new CourseAnalyzer(ApplicationState.course);
                analyzer.analyzeClimbs();
            },
        },
        {
            id: 'segmentList',
            name: 'Segments in Map',
            action: () => {
                SegmentListInMapController.load();
            },
        },
        {
            id: 'setPathnameToCourse',
            name: 'Set Path Name to Course',
            action: () => {
                MoreMenuController.showCoursePathNameDialog();
            },
        },
        {
            id: 'saveForce',
            name: 'Save Force',
            action: async () => {
                let course = ApplicationState.course.clone();
                if (!course.id) {
                    toastr.error('This course is not saved before.');
                    return;
                }

                try {
                    await APIManager.saveCourse(course, true);
                    toastr.success(Resources.text.course_saved);
                    ApplicationState.isSaveRequired = false;
                    ApplicationState.executeListeners(ApplicationEvent.COURSE_SAVED, course);
                } catch (error) {
                    let message = APIManager.messageFromResponseError(error) || Resources.text.failed_to_save_course;
                    toastr.error(message);
                }
            },
        },
    ];

    /**
     * 광고 관리자
     */
    private static menuItemsForAdManager: MenuItem[] = [
        {
            id: 'adManager',
            name: 'AD Manager',
            action: () => {
                AdManagerDialogController.show();
            },
        },
    ];

    static toggle(triggerButton: HTMLElement) {
        let menuItems = this.defaultMenuItemsForMoreMenu.map((item) => {
            return item;
        });

        let user = ApplicationState.user;
        if (user) {
            menuItems.push(this.menuItemForUserNick);
            menuItems.push(this.menuItemForLogout);
        } else {
            menuItems.push(this.menuItemForLogin);
        }

        if (user && user.isSuperuser) {
            this.menuItemsForLabs.forEach((menuItem) => {
                menuItems.push(menuItem);
            });
        } else if (user && user.isAdManager) {
            this.menuItemsForAdManager.forEach((menuItem) => {
                menuItems.push(menuItem);
            });
        }

        this.menuController.menuItems = menuItems;

        if (this.menuController.isShowing) {
            MenuController.dismissAll();
        } else {
            this.menuController.show(triggerButton);
        }
    }

    private static showCoursePathNameDialog() {
        let course = ApplicationState.course;
        if (!course.id) {
            toastr.error('Please save course first.');
            return;
        }

        CommonDialogController.showInput('Set Path Name to Course', `${Statics.courseUrlString()}[pathName_is_used_here]`, ApplicationState.course.pathName || '', '', [
            {
                text: Resources.text.ok,
                action: async (text: string) => {
                    if (!Utility.isSafeTextForPathName(text)) {
                        toastr.error('Path name contains unsafe charactor.');
                        return false;
                    }

                    try {
                        await APIManager.setCoursePathName(course.id, text);
                        course.pathName = text;
                        ApplicationState.replaceWindowHistoryStateBySavedCourse();
                        toastr.success('Path name changed.');
                        return true;
                    } catch (error) {
                        let message = APIManager.messageFromResponseError(error) || 'Failed to set path name.';
                        toastr.error(message);
                        return false;
                    }
                },
            },
            {
                text: Resources.text.cancel,
            },
        ]);
    }
}
