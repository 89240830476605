import { WaypointType } from '../common/Ridingazua.Model';
import { Statics } from '../common/Ridingazua.Statics';

export class WaypointImagesForChart {
    private static instance: WaypointImagesForChart;
    private imageElements: { [key: string]: HTMLImageElement } = {};
    private pinImageElements: { [key: string]: HTMLImageElement } = {};

    private constructor() {
        WaypointType.types.forEach((waypointType) => {
            let imageElement = document.createElement('img');
            let pinImageElement = document.createElement('img');

            imageElement.src = Statics.image(waypointType.relativeImageSrc);
            pinImageElement.src = Statics.image(waypointType.relativePinImageSrc);

            imageElement.width = 50;
            imageElement.height = 50;
            pinImageElement.width = 50;
            pinImageElement.height = 50;

            this.imageElements[waypointType.tag] = imageElement;
            this.pinImageElements[waypointType.tag] = pinImageElement;
        });
    }

    static createInstance(): WaypointImagesForChart {
        if (!this.instance) {
            this.instance = new WaypointImagesForChart();
        }
        return this.instance;
    }

    static imageElementForType(waypointType: WaypointType): HTMLImageElement {
        this.createInstance();
        return this.instance.imageElements[waypointType.tag];
    }

    static pinImageElementForType(waypointType: WaypointType): HTMLImageElement {
        this.createInstance();
        return this.instance.pinImageElements[waypointType.tag];
    }
}
