import { ApplicationState, ApplicationEventListener, ApplicationEvent } from "./Ridingazua.ApplicationState";
import { CourseRange, WaypointType } from "../common/Ridingazua.Model";
import { SelectedRangeController } from "./Ridingazua.SelectedRangeController";
import { Resources } from "./Ridingazua.Resources";
import { ElevationChartController } from "./Ridingazua.ElevationChartController";
import { HTMLUtility } from "./Ridingazua.HTMLUtility";
import { isNothing } from "../common/Ridingazua.Utility";
import { WaypointListDialogController } from "./Ridingazua.WaypointListDialogController";

export class ClimbListDialogController implements ApplicationEventListener {
    private static instance: ClimbListDialogController;

    private dialog: JQuery;
    private div: HTMLDivElement;
    private tbody: HTMLTableSectionElement;

    private columnNames = [
        `${Resources.text.climb_category}`,
        `${Resources.text.climb_start}(km)`,
        `${Resources.text.climb_finish}(km)`,
        `${Resources.text.elevation_gain}(m)`,
        `${Resources.text.length}(km)`,
        `${Resources.text.average_slope}(%)`,
        '',
    ];

    private constructor() {
        let div = document.createElement('div');
        this.div = div;

        this.update();

        this.dialog = $(div).dialog({
            title: Resources.text.climbs,
            width: 'auto',
            height: 'auto',
            maxWidth: window.innerWidth * 0.9,
            maxHeight: window.innerHeight * 0.9,
            open: () => {
                if (!this.dialog) {
                    return;
                }
                this.dialog.dialog('option', 'width', 'auto');
                this.dialog.dialog('option', 'height', 'auto');
                this.dialog.dialog('option', 'maxWidth', window.innerHeight * 0.9);
                this.dialog.dialog('option', 'maxHeight', window.innerHeight * 0.9);
                this.dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });
            }
        });

        ApplicationState.addListener(this);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.SELECT_RANGE:
            case ApplicationEvent.DESELECT_RANGE:
            case ApplicationEvent.SELECT_MAP_SECTION:
            case ApplicationEvent.DESELECT_MAP_SECTION:
                if (this.dialog.dialog('isOpen')) {
                    this.updateSelectedClimb();
                }
                break;
        }
    }

    private static createInstance(): ClimbListDialogController {
        if (!this.instance) {
            this.instance = new ClimbListDialogController();
        }

        return this.instance
    }

    private createTable(): HTMLElement {
        let table = document.createElement('table');
        table.style.width = '100%';
        table.style.marginBottom = '5px';

        let thead = document.createElement('thead');
        table.appendChild(thead);

        let tr = document.createElement('tr');
        thead.appendChild(tr);

        this.columnNames.forEach((columnName) => {
            let th = document.createElement('th');
            th.textContent = columnName;
            tr.appendChild(th);
        });

        let tbody = document.createElement('tbody');
        table.appendChild(tbody);
        this.tbody = tbody;

        return table;
    }

    private _items: CourseRange[] = [];
    private get items(): CourseRange[] {
        return this._items;
    }
    private set items(value: CourseRange[]) {
        this._items = value;

        this.tbody.innerHTML = '';

        if (!this.items || !this.items.length) {
            this.showPlaceholder(Resources.text.climbs_not_found);
            return;
        }

        this.items.forEach(item => {
            this.addTableRowForItem(item);
        });
    }

    private async update() {
        this.div.innerHTML = '';
        this.div.appendChild(this.createTable());
        this.items = ApplicationState.course.findClimbsAdvanced();
    }

    private addTableRowForItem(item: CourseRange) {
        let tr = document.createElement('tr');
        this.tbody.appendChild(tr);

        if (item.isEqualTo(SelectedRangeController.selectedRange)) {
            tr.classList.add('highlighted');
        }

        let climbWaypointType = item.climbCategoryWaypointType();
        let tdCategory = document.createElement('td');
        tdCategory.style.minWidth = '40px';
        tdCategory.classList.add('center');
        tdCategory.classList.add('middle');
        tdCategory.onclick = () => { SelectedRangeController.selectedRange = item; };
        tr.appendChild(tdCategory);

        if (!isNothing(climbWaypointType)) {
            let imgCategory = WaypointListDialogController.createWaypointIconImgElement(climbWaypointType);
            tdCategory.appendChild(imgCategory);
        }

        let tdTexts = [
            ((item.startPoint?.distanceFromCourseStart || 0) / 1000.0).toFixed(1), // 시작 지점(km)
            ((item.finishPoint?.distanceFromCourseStart || 0) / 1000.0).toFixed(1), // 종료 지점(km)
            item.elevationGain.toFixed(1), // 상승고도(m)
            (item.lengthMeter / 1000.0).toFixed(1), // 길이(km)
            (item.averageSlope * 100.0).toFixed(1), // 평균경사도(%)
        ];

        tdTexts.forEach((text) => {
            let td = document.createElement('td');
            td.classList.add('link');
            td.classList.add('center');
            td.classList.add('middle');
            td.textContent = text;
            td.onclick = () => { SelectedRangeController.selectedRange = item; };
            tr.appendChild(td);
        });

        let tdButtons = document.createElement('td');
        tdButtons.style.minWidth = '70px';
        tdButtons.classList.add('children-spacing');
        tdButtons.classList.add('center');
        tdButtons.classList.add('middle');
        tr.appendChild(tdButtons);

        let buttonSelect = HTMLUtility.createIconButton(Resources.text.climb_select, 'select_all', () => {
            SelectedRangeController.selectedRange = item;
            ElevationChartController.cancelZoom();
        });
        tdButtons.appendChild(buttonSelect);

        let buttonSelectAndZoom = HTMLUtility.createIconButton(Resources.text.climb_select_zoom, 'zoom_in', () => {
            SelectedRangeController.selectedRange = item;
            ElevationChartController.setZoom(
                item.startPoint.distanceFromCourseStart,
                item.finishPoint.distanceFromCourseStart
            );
        });
        tdButtons.appendChild(buttonSelectAndZoom);
    }

    private updateSelectedClimb() {
        let selectedRange = SelectedRangeController.selectedRange;
        let selectedIndex = -1;
        let i = 0;
        for (let item of this.items) {
            if (item.isEqualTo(selectedRange)) {
                selectedIndex = i;
                break;
            }
            i++;
        }

        let trs = this.tbody.getElementsByTagName('tr');
        for (let i = 0; i < trs.length; i++) {
            let tr = trs.item(i);
            if (selectedIndex === i) {
                tr.classList.add('highlighted');
            } else {
                tr.classList.remove('highlighted');
            }
        }
    }

    private showPlaceholder(message: string) {
        this.tbody.innerHTML = '';

        let tr = document.createElement('tr');
        this.tbody.appendChild(tr);

        let td = document.createElement('td');
        td.classList.add('placeholder');
        td.colSpan = this.columnNames.length;
        td.textContent = message;
        tr.appendChild(td);
    }

    static show() {
        this.createInstance();
        this.instance.update();
        this.instance.dialog.dialog('open');
    }
}