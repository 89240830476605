import { CheckboxController, HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { MapController } from './Ridingazua.MapController';
import { StorageController } from './Ridingazua.StorageController';
import { Point } from '../common/Ridingazua.Model';
import Axios from 'axios';
import { ApplicationState } from './Ridingazua.ApplicationState';
import { isNothing, Utility } from '../common/Ridingazua.Utility';

export class PlaceSearchController {
    private static instance: PlaceSearchController;
    private map: google.maps.Map;

    static get isShowing(): boolean {
        return this.instance.div.style.display !== 'none';
    }

    static set isShowing(value: boolean) {
        if (this.isShowing === value) {
            return;
        }
        this.instance.div.style.display = value ? 'block' : 'none';
        if (value) {
            this.instance.focusInput();
        } else {
            this.instance.blurInput();
        }
    }

    static set currentBounds(value: google.maps.LatLngBounds | null) {
        if (!this.instance) {
            return;
        }
        this.instance.currentBounds = value;
    }

    static createInstance(map: google.maps.Map): PlaceSearchController {
        if (!this.instance) {
            this.instance = new PlaceSearchController(map);
        }
        return this.instance;
    }

    private div: HTMLDivElement;
    private divSearchTextResult: HTMLDivElement;

    private inputForGooglePlaces: HTMLInputElement;
    private inputForKakaoPlaces: HTMLInputElement;
    private googlePlacesSearchBox: google.maps.places.SearchBox;

    private checkboxCurrentBounds: CheckboxController;

    // 지도의 중심 좌표를 보고 판단하도록 하자
    // private checkboxKoreaOnly: CheckboxController;

    private _currentBounds?: google.maps.LatLngBounds = null;
    private get currentBounds(): google.maps.LatLngBounds | null {
        return this._currentBounds;
    }
    private set currentBounds(value: google.maps.LatLngBounds) {
        this._currentBounds = value;
        if (this.checkboxCurrentBounds.isChecked) {
            this.googlePlacesSearchBox.setBounds(value);
        } else {
            this.googlePlacesSearchBox.setBounds(null);
        }

        this.updateInputSearchText();
    }

    private constructor(map: google.maps.Map) {
        this.map = map;

        let div = document.createElement('div');
        div.id = 'div-search-place';
        div.classList.add('children-vertical-spacing');
        div.style.textAlign = 'right';
        div.style.display = 'none'; // 처음에는 보여주지 않는다.
        this.div = div;
        HTMLUtility.appendInputHiddenAutofocus(div);
        document.getElementById('div-right-top').appendChild(div);

        let divSearchTextResult = document.createElement('div');
        divSearchTextResult.id = 'div-search-text-result';
        divSearchTextResult.classList.add('children-spacing');
        div.appendChild(divSearchTextResult);
        this.divSearchTextResult = divSearchTextResult;

        this.addInputForGooglePlaces();
        this.addInputForKakaoPlaces();
        this.addDivSearchResultControl();
        this.addCheckboxes();
        this.updateInputSearchText();
        this.currentBounds = map.getBounds();
    }

    private addInputForGooglePlaces() {
        let input = document.createElement('input');
        this.inputForGooglePlaces = input;
        input.type = 'text';
        input.classList.add('controls', 'search_text');
        input.placeholder = Resources.text.search_place_by_google;
        this.divSearchTextResult.appendChild(input);

        let searchBox = new google.maps.places.SearchBox(input);
        this.googlePlacesSearchBox = searchBox;

        searchBox.addListener('places_changed', () => {
            this.clearAllSearchResults(false);

            let places = searchBox.getPlaces();

            if (!places.length) {
                return;
            }

            if (places.length > 20) {
                places.splice(20);
            }

            let i = 0;
            let markers = places.map((place) => {
                return this.createPlaceMarker(place.name, i++, place.geometry.location.lat(), place.geometry.location.lng());
            });

            this.setMapVisibleAllMarkers(markers);

            MapController.getInstance().placeMarkers = markers;
        });
    }

    private addInputForKakaoPlaces() {
        let input = document.createElement('input');
        this.inputForKakaoPlaces = input;
        input.type = 'text';
        input.classList.add('controls', 'search_text');
        input.placeholder = Resources.text.search_place_by_kakao;
        this.divSearchTextResult.appendChild(input);
        input.onkeydown = (event) => {
            switch (event.key.toLowerCase()) {
                case 'enter':
                case 'return':
                    this.searchKakaoPlaces(input.value);
                    return false;
                default:
                    break;
            }
        };
    }

    private addDivSearchResultControl() {
        let div = document.createElement('div');
        div.classList.add('box', 'children-spacing');
        this.divSearchTextResult.appendChild(div);

        let buttonClear = HTMLUtility.createIconButton(Resources.text.search_place_clear, 'clear', (button) => {
            this.clearAllSearchResults(true);
        });

        div.appendChild(buttonClear);

        // 추후에 적용하자.
        /*
        let buttonList = HTMLUtility.createIconButton(Resources.text.search_place_list, 'list', (button) => {
            // TODO
        });

        div.appendChild(buttonList);
        */
    }

    private addCheckboxes() {
        let divCheckboxes = document.createElement('div');
        divCheckboxes.classList.add('box', 'children-spacing');
        divCheckboxes.style.display = 'inline-block';
        divCheckboxes.style.marginTop = '2px';
        this.div.appendChild(divCheckboxes);

        let checkboxCurrentBounds = new CheckboxController();
        checkboxCurrentBounds.title = Resources.text.search_place_current_bounds;

        checkboxCurrentBounds.isChecked = StorageController.get('search_place_current_bounds_checked') == 'true';

        checkboxCurrentBounds.onchange = (checked) => {
            StorageController.set('search_place_current_bounds_checked', checked ? 'true' : 'false');
            this.googlePlacesSearchBox.setBounds(checked ? this.currentBounds : null);
        };
        divCheckboxes.appendChild(checkboxCurrentBounds.div);
        this.checkboxCurrentBounds = checkboxCurrentBounds;

        // 지도의 중심좌표를 보고 판단하도록 하자
        /*
        let checkboxKoreaOnly = new CheckboxController();
        checkboxKoreaOnly.title = Resources.text.search_place_korea_only;
        checkboxKoreaOnly.isChecked = StorageController.get('search_place_korea_only_checked') == 'true';
        checkboxKoreaOnly.onchange = (_) => {
            this.resetInputSearchText();
        };
        divCheckboxes.appendChild(checkboxKoreaOnly.div);
        this.checkboxKoreaOnly = checkboxKoreaOnly;
        */
    }

    private lastKoreaOnly?: boolean = null;

    private get isKoreaOnly(): boolean {
        let mapCenter = this.map.getCenter();
        return Utility.pointInPolygin([mapCenter.lat(), mapCenter.lng()], ApplicationState.boundsSouthKorea);
    }

    /**
     * 지도의 중심 좌표에 따라 search text input을 변경한다.
     * 중심좌표가 한국일 경우, inputForKakaoPlaces를 노출하고, 아닐 경우, inputForGooglePlaces를 노출한다.
     * @returns
     */
    private updateInputSearchText() {
        // let isKoreaOnly = this.checkboxKoreaOnly.isChecked;

        let isKoreaOnly = this.isKoreaOnly;
        if (this.lastKoreaOnly == isKoreaOnly) {
            return;
        }

        // StorageController.set('search_place_korea_only_checked', isKoreaOnly ? 'true' : 'false');
        this.lastKoreaOnly = isKoreaOnly;
        this.inputForGooglePlaces.value = '';
        this.inputForKakaoPlaces.value = '';
        if (isKoreaOnly) {
            this.inputForGooglePlaces.blur();
            $(this.inputForGooglePlaces).hide();
            $(this.inputForKakaoPlaces).show();
        } else {
            this.inputForKakaoPlaces.blur();
            $(this.inputForKakaoPlaces).hide();
            $(this.inputForGooglePlaces).show();
        }
    }

    private searching = false;

    private async searchKakaoPlaces(keyword: string) {
        if (this.searching) {
            return;
        }

        if (!keyword.trim().length) {
            return;
        }

        this.searching = true;

        this.clearAllSearchResults(false);

        let params = {
            query: keyword,
        };

        let isCurrentBounds = this.checkboxCurrentBounds.isChecked

        if (isCurrentBounds) {
            let bounds = this.map.getBounds();
            let north = bounds.getNorthEast().lat();
            let east = bounds.getNorthEast().lng();
            let south = bounds.getSouthWest().lat();
            let west = bounds.getSouthWest().lng();
            params['rect'] = `${west},${north},${east},${south}`;
        }

        let url = 'https://dapi.kakao.com/v2/local/search/keyword.json';
        let kakaoRestApiKey = 'b98c404461ee3414677771f42e9dc4bf';
        try {
            let response = await Axios.get(url, {
                params: params,
                headers: {
                    Authorization: `KakaoAK ${kakaoRestApiKey}`,
                },
            });

            let items = response?.data?.documents;

            if (isNothing(items)) {
                toastr.error(Resources.text.failed_to_search_places);
                return;
            }

            if (!items.length) {
                toastr.error(Resources.text.no_place_search_results);
                return;
            }

            let i = 0;
            let markers = items.map((item) => {
                return this.createPlaceMarker(item.place_name, i++, parseFloat(item.y), parseFloat(item.x));
            });

            if(!isCurrentBounds) {
                this.setMapVisibleAllMarkers(markers);
            }
            
            MapController.getInstance().placeMarkers = markers;
        } catch (error) {
            toastr.error(Resources.text.failed_to_search_places);
            return;
        } finally {
            setTimeout(() => {
                this.searching = false;
            }, 1000);
        }
    }

    private createPlaceMarker(name: string, index: number, latitude: number, longitude: number): google.maps.Marker {
        let position = { lat: latitude, lng: longitude };
        let marker = new google.maps.Marker({
            map: this.map,
            title: name,
            label: { color: '#ffffff', text: `${index + 1}` },
            opacity: 1,
            position: position,
            zIndex: MapController.zIndexForPlaceMarker + 100 - index, // index가 낮을수록 더 높은 zIndex를 가진다.
        });

        marker.addListener('click', () => {
            MapController.getInstance().placeMarkers.forEach((showingMarker) => {
                showingMarker?.setZIndex(MapController.zIndexForPlaceMarker);
            });

            marker.setZIndex(MapController.zIndexForSelectedPlaceMarker);

            let infoWindow = new google.maps.InfoWindow({
                content: name,
            });
            infoWindow.open(this.map, marker);

            ApplicationState.map.panTo(position);
            if(ApplicationState.map.getZoom() < 15) {
                ApplicationState.map.setZoom(15);
            }

            MapController.getInstance().showingInfoWindow = infoWindow;
        });

        return marker;
    }

    private setMapVisibleAllMarkers(markers: google.maps.Marker[]) {
        MapController.setVisibleAllPoints(
            markers.map((marker) => {
                return new Point(marker.getPosition().lat(), marker.getPosition().lng());
            })
        );
    }

    private clearAllSearchResults(clearText: boolean) {
        if (clearText) {
            this.inputForGooglePlaces.value = '';
            this.inputForKakaoPlaces.value = '';
        }

        MapController.getInstance().placeMarkers?.forEach((marker) => {
            marker.setMap(null);
        });

        MapController.getInstance().placeMarkers = null;
    }

    private focusInput() {
        let isKoreaOnly = this.isKoreaOnly;
        (isKoreaOnly ? this.inputForKakaoPlaces : this.inputForGooglePlaces).focus();
    }

    private blurInput() {
        this.inputForGooglePlaces.blur();
        this.inputForKakaoPlaces.blur();
    }
}
