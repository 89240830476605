import { HTMLUtility } from './Ridingazua.HTMLUtility';
import Axios from 'axios';
import { AdsController } from './Ridingazua.AdsController';
import { AdvertisementPosition } from '../common/Ridingazua.Model';

export class CommonDialogController {
    div: HTMLDivElement;
    dialog: JQuery;

    private constructor() {
        let div = document.createElement('div');
        HTMLUtility.appendInputHiddenAutofocus(div);
        this.div = div;
    }

    private static async create(content: string | URL): Promise<CommonDialogController> {
        if (content instanceof URL) {
            return await CommonDialogController.createWithContentURL(content as URL);
        } else {
            return CommonDialogController.createWithMessage(content?.toString());
        }
    }

    private static createWithMessage(message: string): CommonDialogController {
        let dialogController = new CommonDialogController();

        if (message && message.length) {
            let p = document.createElement('p');
            p.style.textAlign = 'left';
            p.style.lineBreak = 'anywhere';
            dialogController.div.appendChild(p);
            p.innerHTML = HTMLUtility.replaceToTextContent(message);
        }

        return dialogController;
    }

    private static async createWithContentURL(url: URL): Promise<CommonDialogController> {
        let dialogController = new CommonDialogController();

        let response = await Axios.get(url.toString());
        if (!response.data || !response.data.length) {
            return;
        }

        let div = document.createElement('div');
        div.classList.add('doc');
        div.innerHTML = response.data;
        dialogController.div.appendChild(div);

        return dialogController;
    }

    static async showConfirm(
        title: string,
        content: string | URL,
        buttons: DialogButton[],
        otherOptions?: JQueryUI.DialogOptions,
        showAds: boolean = false,
        advertisementPosition: AdvertisementPosition | null = null
    ): Promise<CommonDialogController> {
        let dialogController = await CommonDialogController.create(content);
        let options: JQueryUI.DialogOptions = {
            title: title,
            modal: true,
            buttons: buttons.map((button) => {
                return {
                    text: button.text,
                    click: () => {
                        let action = button.action;
                        action && action(null);
                        dialogController.close();
                    },
                };
            }),
            close: () => {
                dialogController.div.remove();
            },
        };

        if (otherOptions) {
            for (let key in otherOptions) {
                options[key] = otherOptions[key];
            }
        }

        dialogController.dialog = $(dialogController.div).dialog(options);

        if (showAds) {
            AdsController.createInstance().replaceAdForDialogWithAdPosition(advertisementPosition, dialogController.div, dialogController.dialog);
            options.width = Math.min(window.innerWidth * 0.95, AdsController.dialogWidthForAds);
            options.maxWidth = window.innerWidth * 0.95;
            HTMLUtility.resetDialogPositionToCenter(dialogController.dialog);
        }

        return dialogController;
    }

    static async showInput(title: string, content: string | URL, defaultValue: string | null, placeholder: string | null, buttons: DialogButton[]): Promise<CommonDialogController> {
        let dialogController = await CommonDialogController.create(content);
        dialogController.div.style.textAlign = 'center';

        let input = document.createElement('input');
        input.style.marginTop = '10px';
        input.style.width = '95%';
        input.type = 'text';
        input.value = defaultValue;
        input.placeholder = placeholder;
        dialogController.div.appendChild(input);

        dialogController.dialog = $(dialogController.div).dialog({
            title: title,
            modal: true,
            buttons: buttons.map((button) => {
                return {
                    text: button.text,
                    click: async () => {
                        let action = button.action;
                        let actionResult = action && (await action(input.value));
                        if (actionResult === false) {
                            // do not close dialog
                        } else {
                            dialogController.close();
                        }
                    },
                };
            }),
            resize: (e, ui) => {
                HTMLUtility.resetDialogPositionToCenter(dialogController.dialog);
            },
            open: () => {
                HTMLUtility.resetDialogPositionToCenter(dialogController.dialog);
            },
            close: () => {
                dialogController.div.remove();
            },
        });

        return dialogController;
    }

    private close() {
        this.dialog.dialog('close');
    }
}

export interface DialogButton {
    text: string;
    action?: (value: any) => boolean | void | Promise<boolean> | Promise<void>;
}
