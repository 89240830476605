export class Log {
    static i(message: string) {
        console.log(`[info] ${message}`);
    }

    static d(message: string) {
        console.log(`[debug] ${message}`);
    }
    static w(message: string) {
        console.log(`[warning] ${message}`);
    }

    static e(error: string | Error) {
        let message: string = '';
        let stack: string = null;

        if (typeof (error) === 'string') {
            message = error as string;
        } else if (error instanceof Error) {
            message = error.message;
            stack = error.stack;
        }

        console.log(`[error] ${message}\n${stack || ''}`);
    }
}