import { ApplicationState, ApplicationEventListener, ApplicationEvent } from './Ridingazua.ApplicationState';
import { APIManager } from './Ridingazua.APIManager';
import { UserAccountType, User } from '../common/Ridingazua.Model';
import { Resources } from './Ridingazua.Resources';
import { sprintf } from 'sprintf-js';
import { AxiosResponse } from 'axios';
import { Log } from '../common/Ridingazua.Log';
import { UserNickDialogController } from './Ridingazua.UserNickDialogController';
import { CourseListDialogController } from './Ridingazua.CourseListDialogController';
import { isNothing } from '../common/Ridingazua.Utility';

export class SessionController implements ApplicationEventListener {
    static instance = new SessionController();

    private constructor() {
        // ApplicationState.addListener(this);
        // 위 코드는 Application(Application.ts)의 constructor로 이동하였습니다.
    }

    private static doNotToastWelcome = false;

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.LOGIN:
                if (!SessionController.doNotToastWelcome) {
                    let user = ApplicationState.user;
                    toastr.info(sprintf(Resources.text.hello_format, user.nick || user.name));
                }
                break;

            case ApplicationEvent.LOGOUT:
                toastr.info(Resources.text.goodbye);
                break;
        }
    }

    /**
     * ridingazua 로그인 session 상태와는 무관하게, facebook 로그인 상태가 connected 일 경우, ridingazua 서버로 다시 로그인을 시킨다.
     * @returns
     */
    static checkFacebookLoginStatus(): Promise<void> {
        let facebook = ApplicationState.facebook;
        if (!facebook) {
            Log.e('Facebook SDK is not loaded.');
            throw new Error('Facebook SDK is not loaded.');
        }

        return new Promise((resolve, _) => {
            facebook.getLoginStatus(async (response) => {
                if (!response) {
                    Log.e('facebook getLoginStatus response not found.');
                    throw new Error('Facebook SDK getLoginStatus failed.');
                }

                if (response.status == 'connected') {
                    let authResponse = response.authResponse;
                    let accessToken = authResponse.accessToken;

                    try {
                        let user = await APIManager.login(UserAccountType.FACEBOOK, accessToken);
                        ApplicationState.user = user;
                        SessionController.checkUserNickRequired();
                    } catch (error) {
                        let message = APIManager.messageFromResponseError(error) || Resources.text.failed_to_login;
                        throw new Error(message);
                    }
                } else if (ApplicationState.user?.accountType == UserAccountType.FACEBOOK) {
                    // ridingazua에는 로그인이 되어있는데, facebook에는 connect 되어있지 않음.
                    // 강제 로그아웃 시킴
                    try {
                        APIManager.logout(); // 요청만 하고 응답은 기다리지 않음.
                    } catch (error) {
                        // do nothing
                    }
                    ApplicationState.user = null;
                } else {
                    // ridingazua에도 로그인이 되어있지 않고, facebook에도 connect 되어있지 않음.
                    // do nothing
                }

                resolve();
            });
        });
    }

    static loginWithFacebook(): Promise<void> {
        return new Promise((resolve, _) => {
            ApplicationState.facebook.login(
                async (response) => {
                    if (!response) {
                        Log.e('facebook login response not found.');
                        throw new Error(Resources.text.failed_to_login_with_facebook);
                    }

                    if (response.status != 'connected') {
                        Log.e('facebook login response.status is not connected.');
                        throw new Error(Resources.text.failed_to_login_with_facebook);
                    }

                    await this.checkFacebookLoginStatus();
                    resolve();
                },
                {
                    scope: 'public_profile,email',
                }
            );
        });
    }

    static checkKakaoLoginStatus() {
        let kakao = ApplicationState.kakao;
        if (!kakao) {
            Log.e('Kakao SDK is not loaded.');
            throw new Error('Kakao SDK is not loaded.');
        }
    }

    static loginWithKakao(): Promise<void> {
        return new Promise((resolve, reject) => {
            ApplicationState.kakao.Auth.login({
                scope: 'account_email,profile',
                success: async (authObj) => {
                    try {
                        let user = await APIManager.login(UserAccountType.KAKAO, authObj.access_token);
                        ApplicationState.user = user;
                        SessionController.checkUserNickRequired();
                        resolve();
                    } catch (error) {
                        let message = APIManager.messageFromResponseError(error) || Resources.text.failed_to_login;
                        let newError = new Error(message);
                        reject(newError);
                    }
                },
                fail: (error) => {
                    Log.e('kakao login error: ' + error);
                    throw new Error(Resources.text.failed_to_login_with_kakao);
                },
            });
        });
    }

    static loginWithGoogle(token: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                let user = await APIManager.login(UserAccountType.GOOGLE, token);
                ApplicationState.user = user;
                SessionController.checkUserNickRequired();
                resolve();
            } catch (error) {
                let message = APIManager.messageFromResponseError(error) || Resources.text.failed_to_login;
                let newError = new Error(message);
                reject(newError);
            }
        });
    }

    static async logout() {
        try {
            let accountType = ApplicationState.user?.accountType;
            await APIManager.logout();
            try {
                switch (accountType) {
                    case UserAccountType.FACEBOOK:
                        ApplicationState.facebook.logout(async (response) => {
                            if (!response) {
                                // 페이스북 로그아웃은 실패하더라도 그냥 지나가자.
                                // toastr.error(Resources.text.failed_to_login_with_facebook);
                                // reject(new Error('facebook response is null.'));
                            }
                        });

                    case UserAccountType.KAKAO:
                    // TODO:
                    case UserAccountType.GOOGLE:
                    // TODO:
                }
            } catch (error) {
                // 페이스북 로그아웃은 실패하더라도 그냥 지나가자.
            }
        } catch (error) {
            let message = APIManager.messageFromResponseError(error) || Resources.text.failed_to_logout;
            toastr.error(message);
            throw new Error(message);
        } finally {
            ApplicationState.user = null;
        }
    }

    private static isInitializeSessionChecked = false;
    private static lastCheckedSessionTime = 0;

    static async checkSession() {
        if (!this.isInitializeSessionChecked) {
            return;
        }

        let currentTime = new Date().getTime();
        let timeAfter = currentTime - this.lastCheckedSessionTime;
        if (timeAfter < 1000 * 60 * 5) {
            // 5분 안지났으면 체크 안함.
            return;
        }

        try {
            let user = await APIManager.checkSession();
            ApplicationState.user = user;
            this.lastCheckedSessionTime = new Date().getTime();
        } catch (error) {
            let response: AxiosResponse = error && error.response;
            if (response && response.status === 404) {
                ApplicationState.user = null;
            }
        }
    }

    static async checkInitializeSession() {
        let initializedSession = (window as any).ridingazuaSession;
        if (initializedSession) {
            ApplicationState.user = User.fromJson(initializedSession);
        }

        if (ApplicationState.user) {
            this.didInitializeSessionChecked();
            return;
        }

        if (!ApplicationState.facebook) {
            Log.d('Facebook SDK is not loaded.');
            return;
        }

        if (!ApplicationState.kakao) {
            Log.d('Kakao SDK is not loaded.');
            return;
        }

        try {
            // 페이스북 로그인 상태 체크
            await SessionController.checkFacebookLoginStatus();
            // 카카오 로그인 상태는 체크하지 않는다. (가능하지 않다.)
            this.didInitializeSessionChecked();
        } catch (error) {
            Log.d(error.message);
        }
    }

    private static didInitializeSessionChecked() {
        CourseListDialogController.showByUrlString(window.location.href);

        this.isInitializeSessionChecked = true;
        this.lastCheckedSessionTime = new Date().getTime();
        this.checkUserNickRequired();
    }

    private static isUserNickChecked = false;

    /**
     * nickname이 없을 경우에 대한 처리
     */
    private static checkUserNickRequired() {
        if (this.isUserNickChecked) {
            return;
        }
        setTimeout(() => {
            let user = ApplicationState.user;
            if (isNothing(user)) {
                return;
            }
            if (!user.nick) {
                UserNickDialogController.show();
            }
            this.isUserNickChecked = true;
        }, 500);
    }
}
