import { Section } from '../common/Ridingazua.Model';
import { CommonDialogController } from './Ridingazua.CommonDialogController';
import { ApplicationState, ApplicationEventListener, ApplicationEvent } from './Ridingazua.ApplicationState';
import { TaskManager, Task } from './Ridingazua.TaskManager';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { MapController } from './Ridingazua.MapController';

export class SectionListDialogController implements ApplicationEventListener {
    private static instance: SectionListDialogController;

    private div: HTMLDivElement;
    private dialog: JQuery;
    private sortable: JQuery;
    private ul: HTMLUListElement;

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        div.style.overflowX = 'hidden';
        div.style.padding = '10px';

        div.appendChild(this.createToolsDiv());

        let ul = document.createElement('ul');
        ul.style.marginTop = '5px';
        div.appendChild(ul);
        this.ul = ul;

        let storedClientIds: number[];

        this.sortable = $(ul).sortable({
            start: () => {
                storedClientIds = this.getClientIds();
            },
            stop: () => {
                let clientIds = this.getClientIds();
                if (JSON.stringify(storedClientIds) == JSON.stringify(clientIds)) {
                    return;
                }

                TaskManager.doTask(new ReorderSectionTask(clientIds));
            },
        });

        this.dialog = $(div).dialog({
            title: Resources.text.sections,
            width: 270,
            maxWidth: 270,
            minWidth: 270,
            maxHeight: window.innerHeight * 0.5,
            open: () => {
                ApplicationState.addListener(this);
            },
            close: () => {
                ApplicationState.removeListener(this);
            },
        });
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.COURSE_LOADED:
            case ApplicationEvent.SECTION_ADDED:
            case ApplicationEvent.SECTION_REMOVED:
            case ApplicationEvent.SECTION_CHANGED:
            case ApplicationEvent.SECTION_NAME_CHANGED:
            case ApplicationEvent.SECTIONS_CHANGED:
            case ApplicationEvent.SELECTED_SECTION_CHANGED:
                this.update();
                this.resetPosition();
                break;
        }
    }

    private createToolsDiv(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('children-spacing');
        div.style.textAlign = 'right';

        let buttonAdd = HTMLUtility.createIconButton(Resources.text.section_new, 'add', () => {
            TaskManager.doTask(new NewSectionTask());
        });
        buttonAdd.classList.add('small');
        div.appendChild(buttonAdd);

        let buttonPaste = HTMLUtility.createIconButton(Resources.text.section_paste, 'content_paste', () => {
            SectionListDialogController.pasteSectionFromClipboard();
        });
        buttonPaste.classList.add('small');
        div.appendChild(buttonPaste);

        return div;
    }

    private resetPosition() {
        let divRightBottom = $('#div-right-bottom');
        this.dialog.dialog('option', 'position', {
            my: `right bottom-10`,
            at: 'right top',
            of: divRightBottom,
        });
    }

    private update() {
        this.ul.innerHTML = '';

        ApplicationState.sections.forEach((section, index) => {
            let li = document.createElement('li');
            this.ul.appendChild(li);
            li.setAttribute('clientId', section.clientId.toString());
            li.classList.add('ui-corder-all');
            li.style.padding = '5px';
            li.style.borderStyle = 'solid';
            li.style.borderWidth = 'thin';
            li.style.borderColor = '#eeeeee';
            li.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';

            if (index > 0) {
                li.style.marginTop = '5px';
            }
            if (section === ApplicationState.selectedSection) {
                li.classList.add('highlighted');
            }

            li.onclick = (event) => {
                event.stopPropagation();
                ApplicationState.selectedSection = section;
            };

            let titleDiv = document.createElement('div');
            titleDiv.style.textOverflow = 'ellipsis';
            titleDiv.textContent = section.name;
            li.appendChild(titleDiv);

            let lengthKm = `${(section.lengthMeter / 1000).toFixed(1)}(km)`;
            let elevationGainMeters = `${section.totalElevationGain.toFixed(1)}(m)`;

            let totalValueDiv = document.createElement('div');
            totalValueDiv.style.textOverflow = 'ellipsis';
            totalValueDiv.style.marginTop = '3px';
            totalValueDiv.style.fontSize = '7pt';
            totalValueDiv.textContent = `${lengthKm}, ${elevationGainMeters}`;
            li.appendChild(totalValueDiv);

            let buttonsDiv = document.createElement('div');
            buttonsDiv.style.marginTop = '5px';
            buttonsDiv.style.textAlign = 'right';
            li.appendChild(buttonsDiv);

            let buttonInfos: SectionItemButtonInfo[] = [
                {
                    title: Resources.text.section_rename,
                    iconName: 'edit',
                    action: () => {
                        CommonDialogController.showInput(Resources.text.section_rename, null, section.name, section.name, [
                            {
                                text: Resources.text.ok,
                                action: (value) => {
                                    value = value.trim();
                                    if (value == section.name) {
                                        return;
                                    }

                                    TaskManager.doTask(new RenameSectionTask(section, value));
                                },
                            },
                            {
                                text: Resources.text.cancel,
                                action: null,
                            },
                        ]);
                    },
                },
                {
                    title: Resources.text.section_up,
                    iconName: 'arrow_upward',
                    action: () => {
                        if (ApplicationState.sections.indexOf(section) <= 0) {
                            return;
                        }
                        TaskManager.doTask(new MoveSectionUpTask(section));
                    },
                },
                {
                    title: Resources.text.section_down,
                    iconName: 'arrow_downward',
                    action: () => {
                        if (ApplicationState.sections.indexOf(section) >= ApplicationState.sections.length - 1) {
                            return;
                        }
                        TaskManager.doTask(new MoveSectionDownTask(section));
                    },
                },
                {
                    title: Resources.text.section_duplicate,
                    iconName: 'content_copy',
                    action: () => {
                        TaskManager.doTask(new DuplicateSectionTask(section));
                    },
                },
                {
                    title: Resources.text.section_copy_to_clipboard,
                    iconName: 'file_copy',
                    action: () => {
                        SectionListDialogController.writeSectionToClipboard(section);
                    },
                },
                {
                    title: Resources.text.section_reverse,
                    iconName: 'swap_horiz',
                    action: () => {
                        TaskManager.doTask(new ReverseSectionTask(section));
                    },
                },
                {
                    title: Resources.text.section_merge_with_below,
                    iconName: 'merge_type',
                    cssClass: 'rotate90deg',
                    action: () => {
                        let course = ApplicationState.course;
                        let sectionIndex = course.sections.indexOf(section);
                        if (sectionIndex >= course.sections.length - 1) {
                            toastr.error(Resources.text.section_is_last);
                            return;
                        }

                        TaskManager.doTask(new MergeSectionTask(section));
                    },
                },
                {
                    title: Resources.text.delete,
                    iconName: 'delete',
                    action: () => {
                        if (ApplicationState.sections.length <= 1) {
                            toastr.error(Resources.text.section_must_be_at_least_one);
                            return;
                        }

                        TaskManager.doTask(new DeleteSectionTask(section));
                    },
                },
            ];

            buttonInfos.forEach((buttonInfo) => {
                let button = HTMLUtility.createIconButton(buttonInfo.title, buttonInfo.iconName, () => {
                    buttonInfo.action();
                });
                let cssClass = buttonInfo.cssClass;
                if (cssClass) {
                    button.classList.add(cssClass);
                }
                buttonsDiv.appendChild(button);
            });
        });

        this.scrollToSelectedSectionVisible(false);
    }

    static show() {
        if (!this.instance) {
            this.instance = new SectionListDialogController();
        }
        this.instance.update();
        this.instance.dialog.dialog('open');
        this.instance.resetPosition();
    }

    static close() {
        if (!this.instance) {
            return;
        }

        this.instance.dialog.dialog('close');
    }

    static toggle() {
        if (!this.instance) {
            this.show();
        } else {
            if (this.instance.dialog.dialog('isOpen')) {
                this.close();
            } else {
                this.show();
            }
        }
    }

    private getClientIds(): number[] {
        let lis = this.ul.children;
        let clientIds: number[] = [];
        for (let i = 0; i < lis.length; i++) {
            let li = lis[i];
            let clientId = parseInt(li.getAttribute('clientId'));
            if (clientId) {
                clientIds.push(clientId);
            }
        }
        return clientIds;
    }

    static isDisableScrollToSelectedSection = false;

    static scrollToSelectedSectionVisible(middle: boolean) {
        this.instance?.scrollToSelectedSectionVisible(middle);
    }

    /**
     * 선택된 섹션이 리스트에 보이도록 한다.
     * @param middle 이 값이 true일 경우, 리스트의 수직 가운데에 보이도록 한다.
     */
    private scrollToSelectedSectionVisible(middle: boolean) {
        if (SectionListDialogController.isDisableScrollToSelectedSection) {
            return;
        }

        let selectedSectionClientId = ApplicationState.selectedSection?.clientId;
        if (!selectedSectionClientId) {
            return;
        }

        let lis = this.ul.children;
        let selectedSectionElement: HTMLElement;
        for (var i = 0; i < lis.length; i++) {
            let li = lis.item(i);
            if (parseInt(li.getAttribute('clientId')) == selectedSectionClientId) {
                selectedSectionElement = li as HTMLElement;
                break;
            }
        }

        if (!selectedSectionElement) {
            return;
        }

        let scrollTop = this.div.scrollTop;
        let divHeight = this.div.getBoundingClientRect().height;
        let scrollBottom = scrollTop + divHeight;

        let offsetTop = selectedSectionElement.offsetTop;
        let elementHeight = selectedSectionElement.getBoundingClientRect().height;
        let offsetBottom = offsetTop + elementHeight;

        let newScrollTop = scrollTop;
        if (middle) {
            let scrollHeight = this.div.scrollHeight;
            let maxScrollTop = scrollHeight - divHeight;
            newScrollTop = offsetTop - (divHeight - elementHeight) / 2;
            newScrollTop = Math.min(maxScrollTop, Math.max(0, newScrollTop));
        } else if (scrollTop > offsetTop - 10) {
            newScrollTop = offsetTop - 10;
        } else if (scrollBottom < offsetBottom + 10) {
            newScrollTop += offsetBottom + 10 - scrollBottom;
        }

        if (newScrollTop != scrollTop) {
            if (this.div.scrollTo) {
                this.div.scrollTo({
                    top: newScrollTop,
                    behavior: 'smooth',
                });
            } else {
                this.div.scrollTop = newScrollTop;
            }
        }
    }

    static async writeSectionToClipboard(section: Section) {
        if (section.points.length < 2) {
            toastr.error(Resources.text.section_copy_failed_by_empty);
            return;
        }
        let newSection = section.clone();
        let sectionJsonString = JSON.stringify(newSection.toJson());
        try {
            await navigator.clipboard.writeText(sectionJsonString);
            toastr.info(Resources.text.section_copied_to_clipboard);
        } catch (e) {
            toastr.info(Resources.text.section_copy_failed);
        }
    }

    static async pasteSectionFromClipboard() {
        let sectionJsonString = await navigator.clipboard.readText();
        try {
            let sectionJson = JSON.parse(sectionJsonString);
            let section = Section.fromJson(sectionJson);
            if (!section.points.length || section.points.length < 2) {
                throw new Error();
            }

            TaskManager.doTask(new PushSectionTask(section));
        } catch (e) {
            toastr.error(Resources.text.section_paste_failed);
            return;
        }
    }
}

class SectionItemButtonInfo {
    title: string;
    iconName: string;
    cssClass?: string;
    action: () => void;
}

class RenameSectionTask implements Task {
    private section: Section;
    private name: string;
    private nameToUndo: string;

    constructor(section: Section, name: string) {
        this.section = section;
        this.name = name;
        this.nameToUndo = section.name;
    }

    do() {
        this.section.name = this.name;
        ApplicationState.executeListeners(ApplicationEvent.SECTION_NAME_CHANGED, this.section);
    }

    undo() {
        this.section.name = this.nameToUndo;
        ApplicationState.executeListeners(ApplicationEvent.SECTION_NAME_CHANGED, this.section);
    }
}

class MoveSectionTask implements Task {
    private section: Section;
    indexToMove: number;
    indexToRestore: number;

    constructor(section: Section) {
        this.section = section;

        let index = ApplicationState.sections.indexOf(this.section);
        this.indexToRestore = index;
    }

    do() {
        ApplicationState.moveSection(this.section, this.indexToMove);
    }

    undo() {
        ApplicationState.moveSection(this.section, this.indexToRestore);
    }
}

class MoveSectionUpTask extends MoveSectionTask {
    constructor(section: Section) {
        super(section);
        this.indexToMove = this.indexToRestore - 1;
    }
}

class MoveSectionDownTask extends MoveSectionTask {
    constructor(section: Section) {
        super(section);
        this.indexToMove = this.indexToRestore + 1;
    }
}

class DuplicateSectionTask implements Task {
    private section: Section;
    private createdSection: Section;

    constructor(section: Section) {
        this.section = section;
    }

    do() {
        let newSection = this.section.clone();
        newSection.name = this.section.name + '(Duplcated)';

        let index = ApplicationState.sections.indexOf(this.section);
        ApplicationState.insertSection(newSection, index + 1);

        this.createdSection = newSection;
    }

    undo() {
        let sections = ApplicationState.sections;
        let index = sections.indexOf(this.createdSection);
        ApplicationState.removeSection(this.createdSection);

        let newSelectedSectionIndex = index - 1;
        if (newSelectedSectionIndex < 0) newSelectedSectionIndex = 0;
        ApplicationState.selectedSection = sections[newSelectedSectionIndex];
    }
}

class DeleteSectionTask implements Task {
    private section: Section;
    private indexToRestore: number;

    constructor(section: Section) {
        this.section = section;
    }

    do() {
        let sections = ApplicationState.sections;
        let index = sections.indexOf(this.section);
        ApplicationState.removeSection(this.section);

        let newSelectedSectionIndex = index - 1;
        if (newSelectedSectionIndex < 0) newSelectedSectionIndex = 0;
        ApplicationState.selectedSection = sections[newSelectedSectionIndex];

        this.indexToRestore = index;
    }

    undo() {
        ApplicationState.insertSection(this.section, this.indexToRestore);
        ApplicationState.selectedSection = this.section;
    }
}

class ReverseSectionTask implements Task {
    private section: Section;

    constructor(section: Section) {
        this.section = section;
    }

    do() {
        this.section.points.reverse();
        ApplicationState.executeListeners(ApplicationEvent.SECTION_CHANGED, this.section);
    }

    undo() {
        this.do();
    }
}

class MergeSectionTask implements Task {
    private section: Section;
    private belowSection: Section;

    constructor(section: Section) {
        this.section = section;
        let course = ApplicationState.course;
        let sectionIndex = course.sections.indexOf(section);
        if (sectionIndex >= course.sections.length - 1) {
            return;
        }

        let belowSection = course.sections[sectionIndex + 1];
        this.belowSection = belowSection;
    }

    do() {
        if (!this.belowSection) {
            return;
        }

        this.belowSection.points.forEach((point) => {
            this.section.points.push(point);
        });

        let course = ApplicationState.course;
        let belowSectionIndex = course.sections.indexOf(this.belowSection);
        course.sections.splice(belowSectionIndex, 1);
        course.updateTotalValues();

        ApplicationState.executeListeners(ApplicationEvent.SECTION_CHANGED, this.section);
        ApplicationState.executeListeners(ApplicationEvent.SECTION_REMOVED, this.belowSection);
        ApplicationState.selectedSection = this.section;
    }

    undo() {
        this.section.points.splice(this.section.points.length - this.belowSection.points.length, this.belowSection.points.length);

        let course = ApplicationState.course;
        let sectionIndex = course.sections.indexOf(this.section);
        course.sections.splice(sectionIndex + 1, 0, this.belowSection);
        course.updateTotalValues();

        ApplicationState.executeListeners(ApplicationEvent.SECTION_CHANGED, this.section);
        ApplicationState.executeListeners(ApplicationEvent.SECTION_ADDED, this.belowSection);
    }
}

class NewSectionTask implements Task {
    private newSection: Section;
    private lastSelectedSection: Section;

    constructor() {
        this.lastSelectedSection = ApplicationState.selectedSection;
    }

    do(): void {
        if (!this.newSection) {
            this.newSection = Section.create(ApplicationState.newSectionName(ApplicationState.course));
        }
        ApplicationState.pushSection(this.newSection);
        ApplicationState.selectedSection = this.newSection;
    }

    undo(): void {
        ApplicationState.removeSection(this.newSection);
        ApplicationState.selectedSection = this.lastSelectedSection;
    }
}

class PushSectionTask implements Task {
    private section: Section;

    private removedFirstSection?: Section;
    private lastSelectedSection: Section;

    constructor(section: Section) {
        this.section = section;
        this.lastSelectedSection = ApplicationState.selectedSection;
    }

    do(): void {
        if (ApplicationState.sections.length == 1 && !ApplicationState.course.allPoints().length) {
            // 섹션이 1개인데, 비어있는 경우에는, 그 섹션을 교체한다.
            ApplicationState.pushSection(this.section);
            this.removedFirstSection = ApplicationState.sections[0];
            ApplicationState.removeSection(this.removedFirstSection);
        } else {
            ApplicationState.pushSection(this.section);
            ApplicationState.selectedSection = this.section;
        }
        toastr.info(Resources.text.section_pasted);
        MapController.setVisibleAllPoints(this.section.points);
    }

    undo(): void {
        ApplicationState.removeSection(this.section);
        if (this.removedFirstSection) {
            ApplicationState.insertSection(this.removedFirstSection, 0);
            this.removedFirstSection = null;
        } else {
            ApplicationState.selectedSection = this.lastSelectedSection;
        }
    }
}

class ReorderSectionTask implements Task {
    private clientIds: number[];
    private restoredClientIds: number[];

    constructor(orderIds: number[]) {
        let course = ApplicationState.course;
        this.clientIds = orderIds;
        this.restoredClientIds = course.sections.map((course) => {
            return course.clientId;
        });
    }

    do(): void {
        let sections: Section[] = [];
        let course = ApplicationState.course;
        for (let clientId of this.clientIds) {
            sections.push(course.sectionByClientId(clientId));
        }
        course.sections = sections;
        ApplicationState.executeListeners(ApplicationEvent.SECTIONS_CHANGED);
    }

    undo(): void {
        let sections: Section[] = [];
        let course = ApplicationState.course;
        for (let clientId of this.restoredClientIds) {
            sections.push(course.sectionByClientId(clientId));
        }
        course.sections = sections;
        ApplicationState.executeListeners(ApplicationEvent.SECTIONS_CHANGED);
    }
}
