import { Point } from "./Ridingazua.Model";
import Axios from "axios";

export class ElevationLoader {
    static apiURL = 'https://oe.ridingazua.cc/api/v1/lookup';

    static async loadElevations(points: Point[]) {
        let copiedPoints = points.map(point => { return point });

        let numberOfPointsPerRequest = 1000;

        // 1000개씩 나눈다.
        let subPointsArray: Point[][] = [];
        while (copiedPoints.length > numberOfPointsPerRequest) {
            subPointsArray.push(copiedPoints.splice(0, numberOfPointsPerRequest));
        }
        if (copiedPoints.length) {
            subPointsArray.push(copiedPoints);
        }

        for (let i = 0; i < subPointsArray.length; i++) {
            let subPoints = subPointsArray[i];

            let locations = subPoints.map((point) => {
                return {
                    latitude: point.latitude,
                    longitude: point.longitude,
                };
            });

            let params = {
                locations: locations,
            };

            let response = await Axios.post(this.apiURL, params);
            let results = response.data.results as any[];
            if (results.length != subPoints.length) {
                throw new Error('Loaded result count is not equal to points count.');
            }

            results.forEach((item, index) => {
                let elevation = item.elevation;
                if (isNaN(elevation)) {
                    elevation = 0;
                }

                let subPoint = subPoints[index];
                if (subPoint.isOriginalElevationFromOSM) {
                    // do nothing
                } else {
                    subPoint.originalElevation = elevation;
                    subPoint.elevation = elevation;
                    subPoint.isForLoadDirection = false;
                }
            });
        }
    }
}