import { ApplicationState } from "./Ridingazua.ApplicationState";
import { APIManager } from "./Ridingazua.APIManager";
import { Resources } from "./Ridingazua.Resources";
import { MapSection } from "../common/Ridingazua.Model";
import { MapController } from "./Ridingazua.MapController";

export class SegmentListInMapController {
    private static instance: SegmentListInMapController;

    private _items: MapSection[] = [];

    private get items(): MapSection[] {
        return this._items;
    }

    private set items(value: MapSection[]) {
        this._items = value || [];
        this.updateMapObjects();
    }

    private selectedItem?: MapSection;

    private static createInstance() {
        if (!this.instance) {
            this.instance = new SegmentListInMapController();
        }
    }

    static load() {
        this.createInstance();
        this.instance.load();
    }

    private async load() {
        try {
            let mapBounds = ApplicationState.map.getBounds();
            let northEast = mapBounds.getNorthEast();
            let southWest = mapBounds.getSouthWest();
            this.items = await APIManager.loadSegmentList(northEast.lat(), northEast.lng(), southWest.lat(), southWest.lng());
        } catch (error) {
            let errorMessage = APIManager.messageFromResponseError(error) || Resources.text.error_occured;
            toastr.error(errorMessage);
        }
    }

    private lines?: google.maps.Polyline[];
    private markers?: google.maps.Marker[];

    private updateMapObjects() {
        this.lines?.forEach(line => {
            line.setMap(null);
        });

        this.markers?.forEach(marker => {
            marker.setMap(null);
        });

        this.lines = [];
        this.markers = [];

        this.items.forEach(item => {
            this.addMapObjectForItem(item);
        });
    }

    private addMapObjectForItem(item: MapSection) {
        let map = ApplicationState.map;

        let points = item.points;
        let path = points.map(point => { return new google.maps.LatLng(point.latitude, point.longitude) });

        let line = new google.maps.Polyline({
            geodesic: true,
            strokeColor: '#990099',
            strokeOpacity: item.isEqualTo(this.selectedItem) ? 1.0 : 0.5,
            strokeWeight: 4,
            zIndex: item.isEqualTo(this.selectedItem) ? MapController.zIndexForSelectedMapSection : MapController.zIndexForMapSection,
            map: map,
            path: path,
            draggable: false,
            editable: false,
        });

        line.addListener('mousemove', event => {
            let latLng = event.latLng as google.maps.LatLng;
            // TODO
        });

        line.addListener('mouseout', event => {
            // TODO
        });

        line.addListener('click', (event) => {
            let latLng = event.latLng as google.maps.LatLng;
            console.log(`segment click = ${item.toString()}`);
        });

        line.addListener('rightclick', (event) => {
            // TODO
        });

        this.lines.push(line);

        this.markers.push(
            MapController.createStartOrEndPointMarker(true, points[0])
        );
        this.markers.push(
            MapController.createStartOrEndPointMarker(false, points[points.length - 1])
        );
    }
}