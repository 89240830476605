import { ApplicationState } from './Ridingazua.ApplicationState';
import { APIManager } from './Ridingazua.APIManager';
import { LoginDialogController } from './Ridingazua.LoginDialogController';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';

export class UserNickDialogController {
    private static instance?: UserNickDialogController;
    private dialog: JQuery;
    private div: HTMLDivElement;
    private dl: HTMLDListElement;
    private inputUserNick: HTMLInputElement;

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        HTMLUtility.appendInputHiddenAutofocus(div);

        let p = document.createElement('p');
        div.appendChild(p);
        p.innerHTML = Resources.text.user_nick_setting_description;

        let dl = document.createElement('dl');
        this.dl = dl;
        div.appendChild(dl);
        this.appendNickElements();

        this.dialog = $(div).dialog({
            title: Resources.text.user_nick_setting,
            modal: true,
            closeOnEscape: false,
            open: () => {
                if (!this.dialog) {
                    return;
                }

                this.dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });
            },
            buttons: [
                {
                    text: Resources.text.ok,
                    click: () => {
                        this.save();
                    },
                },
            ],
        });
    }

    private appendNickElements() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        let idForUserNickInput = 'input-user-nick';
        label.setAttribute('for', idForUserNickInput);
        label.textContent = Resources.text.user_nick;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);

        let inputUserNick = document.createElement('input');
        dd.appendChild(inputUserNick);
        inputUserNick.style.width = '95%';
        inputUserNick.type = 'text';
        inputUserNick.id = idForUserNickInput;
        inputUserNick.placeholder = Resources.text.user_nick_placeholder;
        this.inputUserNick = inputUserNick;
    }

    private update() {
        let user = ApplicationState.user;
        this.inputUserNick.value = user.nick || '';
    }

    private async save() {
        let nick = this.inputUserNick.value.trim();
        if (!nick || nick.length == 0) {
            toastr.error(Resources.text.user_nick_required);
            return;
        }

        try {
            await APIManager.saveUserNick(nick);
            ApplicationState.user.nick = nick;
            this.close();
            toastr.info(Resources.text.user_nick_changed);
        } catch (error) {
            let message = APIManager.messageFromResponseError(error) || Resources.text.error_occured;
            toastr.error(message);
        }
    }

    private close() {
        this.dialog.dialog('close');
    }

    static show() {
        if (!ApplicationState.user) {
            toastr.info(Resources.text.login_required);
            LoginDialogController.show(() => {
                UserNickDialogController.show();
            });
            return;
        }

        if (!this.instance) {
            this.instance = new UserNickDialogController();
        }
        this.instance.update();
        this.instance.dialog.dialog('open');
    }
}
