import { ApplicationState, ApplicationEvent } from './Ridingazua.ApplicationState';
import { Resources } from './Ridingazua.Resources';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Statics } from '../common/Ridingazua.Statics';
import { AdsController } from './Ridingazua.AdsController';
import { AdvertisementPosition } from '../common/Ridingazua.Model';
import LZString from 'lz-string';

export class DownloadDialogController {
    private static instance: DownloadDialogController;

    private dialog: JQuery;
    private div: HTMLDivElement;
    private inputCourseName: HTMLInputElement;

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        HTMLUtility.appendInputHiddenAutofocus(div);

        div.appendChild(this.createCourseNameElement());
        div.appendChild(this.createDownloadElement());

        this.dialog = $(div).dialog({
            title: Resources.text.download,
            modal: true,
            buttons: [
                {
                    text: 'tcx',
                    click: () => {
                        this.createFormAndSubmit('tcx');
                    },
                },
                {
                    text: 'gpx',
                    click: () => {
                        this.createFormAndSubmit('gpx');
                    },
                },
            ],
        });
    }

    private createCourseNameElement(): HTMLElement {
        let dl = document.createElement('dl');
        this.div.appendChild(dl);

        let dt = document.createElement('dt');
        dl.appendChild(dt);

        let idForCourseNameInput = 'input-download-course-name';
        let label = document.createElement('label');
        label.textContent = Resources.text.course_name;
        label.setAttribute('for', idForCourseNameInput);
        dt.appendChild(label);

        let dd = document.createElement('dd');
        dl.appendChild(dd);

        let inputCourseName = document.createElement('input');
        dd.appendChild(inputCourseName);
        inputCourseName.style.width = '90%';
        inputCourseName.type = 'text';
        inputCourseName.id = idForCourseNameInput;
        inputCourseName.placeholder = Resources.text.course_name;
        this.inputCourseName = inputCourseName;

        return dl;
    }

    private createDownloadElement(): HTMLElement {
        let div = document.createElement('div');
        div.style.marginTop = '10px';

        let p = document.createElement('p');
        p.textContent = Resources.text.message_for_select_file_type1;
        div.appendChild(p);

        p = document.createElement('p');
        p.textContent = Resources.text.message_for_select_file_type2;
        div.appendChild(p);

        // 아래의 코드는 서버에 올리지 않고 로컬에서 바로 파일을 생성해낼 때 사용하는 코드이다.
        /*
        let tcxButton = document.createElement('a');
        tcxButton.style.marginTop = '10px';
        tcxButton.textContent = 'tcx';
        tcxButton.classList.add('button');
        tcxButton.style.display = 'block';
        div.appendChild(tcxButton);

        let gpxButton = document.createElement('a');
        gpxButton.style.marginTop = '5px';
        gpxButton.textContent = 'gpx';
        gpxButton.classList.add('button');
        gpxButton.style.display = 'block';
        div.appendChild(gpxButton);

        let courseNameForFilename = Utility.replaceNotAllowedFilenameCharactors(courseName);

        this.link(
            new TcxTemplate().generate(ApplicationState.course),
            `${courseNameForFilename}.tcx`,
            tcxButton
        );

        this.link(
            new GpxTemplate().generate(ApplicationState.course),
            `${courseNameForFilename}.gpx`,
            gpxButton
        );
        */

        return div;
    }

    static show() {
        let course = ApplicationState.course;
        if (course.allPoints().length < 2) {
            toastr.error(Resources.text.course_is_too_short);
            return;
        }

        if (!this.instance) {
            this.instance = new DownloadDialogController();
        }

        AdsController.createInstance().replaceAdForDialogWithAdPosition(AdvertisementPosition.EXPORT_DIALOG, this.instance.div, this.instance.dialog);

        this.instance.resetSize();
        this.instance.dialog.dialog('open');
        this.instance.update();
    }

    private resetSize() {
        if (!this.dialog) {
            return;
        }

        this.dialog.dialog('option', 'width', Math.min(window.innerWidth * 0.95, AdsController.dialogWidthForAds));
        this.dialog.dialog('option', 'maxWidth', window.innerWidth * 0.95);
        this.dialog.dialog('option', 'height', 'auto');
    }

    private update() {
        let courseName = ApplicationState.course.name || '';
        this.inputCourseName.value = courseName;
    }

    /*
    private link(data: string, filename: string, button: HTMLAnchorElement) {
        let blob = new Blob([data], { type: 'text/plain' });
        var iOS =
            !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS) {
            button.onclick = () => {
                let reader = new FileReader();
                reader.onload = () => {
                    window.open(reader.result as string);
                };
                reader.readAsDataURL(blob);
            };
        } else {
            button.href = window.URL.createObjectURL(blob);
            button.setAttribute('download', filename);
        }
    }
    */

    static startDownload(type: string, autoClose: boolean = true) {
        this.instance?.createFormAndSubmit(type, autoClose);
    }

    private createFormAndSubmit(type: string, autoClose: boolean = true) {
        let course = ApplicationState.course;
        course.name = this.inputCourseName.value;

        ApplicationState.executeListeners(ApplicationEvent.COURSE_NAME_CHANGED);

        let form = document.createElement('form');
        form.action = `${Statics.editorPath}/download`;
        // form.target = '_blank';
        form.method = 'POST';

        let inputHiddenCourse = document.createElement('input');
        inputHiddenCourse.type = 'hidden';
        inputHiddenCourse.name = 'course';
        inputHiddenCourse.value = LZString.compressToBase64(JSON.stringify(course.toJson()));
        form.appendChild(inputHiddenCourse);

        let inputHiddenType = document.createElement('input');
        inputHiddenType.type = 'hidden';
        inputHiddenType.name = 'type';
        inputHiddenType.value = type;
        form.appendChild(inputHiddenType);

        document.body.appendChild(form);

        ApplicationState.doNotShowUnloadAlert = true;
        form.submit();

        let dialog = this.dialog;
        setTimeout(() => {
            ApplicationState.doNotShowUnloadAlert = false;
            form.remove();
            
            if(autoClose) {
                dialog.dialog('close');
            }
        }, 0);
    }
}