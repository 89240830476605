import { Point, Waypoint, WaypointType, InstructionType } from '../common/Ridingazua.Model';
import Axios from 'axios';
import { StorageController } from './Ridingazua.StorageController';
import { Resources } from './Ridingazua.Resources';
import { Utility } from '../common/Ridingazua.Utility';
import { ElevationLoader } from '../common/Ridingazua.ElevationLoader';
import { ApplicationEvent, ApplicationState } from './Ridingazua.ApplicationState';
import { SmoothElevationTask } from './Ridingazua.ElevationChartController';

export interface Director {
    readonly name: string;
    loadDirection(point1: Point, point2: Point): Promise<Point[]>;
}

export enum AutoRouteProfile {
    OSM_CYCLING_RECOMMENDED = 11,
    OSM_CYCLING_MOUNTAIN = 12,
    OSM_ROAD = 14
}

export class AutoRouteDirector implements Director {
    static instance = new AutoRouteDirector();

    private static _selectedAutoRouteProfile: AutoRouteProfile = AutoRouteDirector.autoRouteProfileBy(parseInt(StorageController.get('selectedAutoRouteProfile')));

    static get selectedAutoRouteProfile(): AutoRouteProfile {
        return this._selectedAutoRouteProfile || AutoRouteProfile.OSM_CYCLING_RECOMMENDED;
    }

    static set selectedAutoRouteProfile(value: AutoRouteProfile) {
        if (this._selectedAutoRouteProfile == value) {
            return;
        }

        this._selectedAutoRouteProfile = value;
        StorageController.set('selectedAutoRouteProfile', `${value}`);
    }

    static _autoLeftRightWaypointEnabled: boolean = StorageController.get('autoLeftRightWaypointEnabled') === 'true';

    static get autoLeftRightWaypointEnabled(): boolean {
        return this._autoLeftRightWaypointEnabled;
    }

    static set autoLeftRightWaypointEnabled(value: boolean) {
        this._autoLeftRightWaypointEnabled = value;
        StorageController.set('autoLeftRightWaypointEnabled', value ? 'true' : 'false');
    }

    name: string = 'OpenStreetMapDirector';

    loadDirection(point1: Point, point2: Point): Promise<Point[]> {
        let isInSouthKorea =
            Utility.pointInPolygin([point1.latitude, point1.longitude], ApplicationState.boundsSouthKorea) &&
            Utility.pointInPolygin([point2.latitude, point2.longitude], ApplicationState.boundsSouthKorea);

        return isInSouthKorea ? this.loadDirectionForSouthKorea(point1, point2) : this.loadDirectionForPlanet(point1, point2);
    }

    /**
     * 한국지역의 경로를 조회힌다.
     * gh.ridingazua.cc 의 API를 이용한다.
     * @param point1
     * @param point2
     */
    protected async loadDirectionForSouthKorea(point1: Point, point2: Point): Promise<Point[]> {
        let url = 'https://gh.ridingazua.cc/route';
        let params = new URLSearchParams();
        params.append('point', `${point1.latitude},${point1.longitude}`);
        params.append('point', `${point2.latitude},${point2.longitude}`);
        params.append('type', 'json');
        params.append('elevation', 'true');
        params.append('profile', AutoRouteDirector.autoRouteProfileIdForGraphhopper(AutoRouteDirector.selectedAutoRouteProfile));

        let response = await Axios.get(
            url,
            {
                headers: { 'Content-Type': 'application/json' },
                params: params
            }
        );

        if (response.status != 200) {
            throw new Error(Resources.text.failed_to_route);
        }

        return this.createPointsFromGHResponse(response);
    }

    private createPointsFromGHResponse(response: any): Point[] {
        let path = response.data.paths[0];
        let encodedPoints = path.points;
        let instructions = path.instructions;
        let points = this.decodeGraphhopperEncodedPoints(encodedPoints, true);

        for (let instruction of instructions) {
            let pointIndex = instruction.interval[0];
            let point = points[pointIndex];
            let sign = instruction.sign;
            switch (sign) {
                case -3:
                    point.instructionType = InstructionType.SHARP_LEFT;
                    break;
                case -2:
                    point.instructionType = InstructionType.LEFT;
                    break;
                case -1:
                    point.instructionType = InstructionType.SLIGHT_LEFT;
                    break;
                case 1:
                    point.instructionType = InstructionType.SLIGHT_RIGHT;
                    break;
                case 2:
                    point.instructionType = InstructionType.RIGHT;
                    break;
                case 3:
                    point.instructionType = InstructionType.SHARP_RIGHT;
                    break;
            }
        }

        this.setInstructionWaypointsIfRequired(points);

        return points;
    }

    private decodeGraphhopperEncodedPoints(encoded: string, is3D: boolean): Point[] {
        var len = encoded.length;
        var index = 0;
        var array = [];
        var lat = 0;
        var lng = 0;
        var ele = 0;

        while (index < len) {
            var b;
            var shift = 0;
            var result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            var deltaLat = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lat += deltaLat;

            shift = 0;
            result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            var deltaLon = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lng += deltaLon;

            if (is3D) {
                // elevation
                shift = 0;
                result = 0;
                do {
                    b = encoded.charCodeAt(index++) - 63;
                    result |= (b & 0x1f) << shift;
                    shift += 5;
                } while (b >= 0x20);
                var deltaEle = ((result & 1) ? ~(result >> 1) : (result >> 1));
                ele += deltaEle;
                array.push([lng * 1e-5, lat * 1e-5, ele / 100]);
            } else
                array.push([lng * 1e-5, lat * 1e-5]);
        }

        // var end = new Date().getTime();
        // console.log("decoded " + len + " coordinates in " + ((end - start) / 1000) + "s");
        let points = array.map(value => {
            let point = new Point(value[1], value[0]);
            point.elevation = value[2];
            point.originalElevation = value[2];
            point.isOriginalElevationFromOSM = true;
            return point;
        });

        return points;
    }

    /**
     * 한국을 제외한 지역의 경로를 조회한다.
     * openrouteservice.org API를 이용한다.
     * @param point1
     * @param point2
     */
    private async loadDirectionForPlanet(point1: Point, point2: Point): Promise<Point[]> {
        let coordinates = [
            [point1.longitude, point1.latitude],
            [point2.longitude, point2.latitude],
        ];

        let profile = AutoRouteDirector.autoRouteProfileIdForORS(AutoRouteDirector.selectedAutoRouteProfile);

        let preference: string = null;
        switch (AutoRouteDirector.selectedAutoRouteProfile) {
            case AutoRouteProfile.OSM_CYCLING_RECOMMENDED:
                preference = 'recommended';
                break;
            case AutoRouteProfile.OSM_CYCLING_MOUNTAIN:
                preference = 'recommended';
                break;
            case AutoRouteProfile.OSM_ROAD:
                preference = 'recommended';
                break;
        }

        let params = {
            coordinates: coordinates,
            elevation: true,
            preference: preference,
            units: 'km',
            geometry: true,
        };

        let headers = {
            'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json, application/geo+json, application/gpx+xml; charset=utf-8',
            Authorization: '5b3ce3597851110001cf624820f20c447d1b422fb0b3f9f2d3c4ec9d',
        };

        let url = `https://api.openrouteservice.org/v2/directions/${profile}`;
        let response = await Axios.post(url, params, { headers: headers });
        if (response.status != 200) {
            throw new Error(Resources.text.failed_to_route);
        }

        return this.createPointsFromORSResponse(response);
    }

    private createPointsFromORSResponse(response: any): Point[] {
        let route = response.data.routes[0];
        let geometry = route.geometry;
        let polyline = this.decodePolyline(geometry, true);
        let points = this.pointsFromPolyline(polyline, true);
        let segments = route.segments as any[];
        for (let segment of segments) {
            let steps = segment.steps as any[];
            for (let step of steps) {
                let pointIndex = step.way_points[0];
                let point = points[pointIndex];
                switch (step.type) {
                    case InstructionType.LEFT:
                    case InstructionType.RIGHT:
                    case InstructionType.SHARP_LEFT:
                    case InstructionType.SHARP_RIGHT:
                    case InstructionType.SLIGHT_LEFT:
                    case InstructionType.SLIGHT_RIGHT:
                        point.instructionType = step.type;
                        break;
                }
            }
        }

        this.setInstructionWaypointsIfRequired(points);

        return points;
    }

    protected setInstructionWaypointsIfRequired(points: Point[]) {
        for (let point of points) {
            if (AutoRouteDirector.autoLeftRightWaypointEnabled) {
                switch (point.instructionType) {
                    case InstructionType.LEFT:
                        point.waypoint = new Waypoint(WaypointType.Left, Resources.text.waypoint_type_left);
                        break;
                    case InstructionType.SHARP_LEFT:
                        point.waypoint = new Waypoint(WaypointType.Left, Resources.text.course_instruction_sharp_left);
                        break;
                    case InstructionType.SLIGHT_LEFT:
                        point.waypoint = new Waypoint(WaypointType.Left, Resources.text.course_instruction_slight_left);
                        break;
                    case InstructionType.RIGHT:
                        point.waypoint = new Waypoint(WaypointType.Right, Resources.text.waypoint_type_right);
                        break;
                    case InstructionType.SHARP_RIGHT:
                        point.waypoint = new Waypoint(WaypointType.Right, Resources.text.course_instruction_sharp_right);
                        break;
                    case InstructionType.SLIGHT_RIGHT:
                        point.waypoint = new Waypoint(WaypointType.Right, Resources.text.course_instruction_slight_right);
                        break;
                }
            }
        }
    }

    /**
     * Decode an x,y or x,y,z encoded polyline
     * @param encodedPolyline
     * @param includeElevation
     * @returns array of [lat, lng, ele]
     */
    private decodePolyline(encodedPolyline: string, includeElevation: boolean): number[][] {
        // array that holds the points
        let points = [];
        let index = 0;
        const len = encodedPolyline.length;
        let lat = 0;
        let lng = 0;
        let ele = 0;
        while (index < len) {
            let b;
            let shift = 0;
            let result = 0;
            do {
                b = encodedPolyline.charAt(index++).charCodeAt(0) - 63; // finds ascii
                // and subtract it by 63
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);

            lat += (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
            shift = 0;
            result = 0;
            do {
                b = encodedPolyline.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            lng += (result & 1) !== 0 ? ~(result >> 1) : result >> 1;

            if (includeElevation) {
                shift = 0;
                result = 0;
                do {
                    b = encodedPolyline.charAt(index++).charCodeAt(0) - 63;
                    result |= (b & 0x1f) << shift;
                    shift += 5;
                } while (b >= 0x20);
                ele += (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
            }
            try {
                let location = [lat / 1e5, lng / 1e5];
                if (includeElevation) location.push(ele / 100);
                points.push(location);
            } catch (e) {
                console.log(e);
            }
        }
        return points;
    }

    private pointsFromPolyline(polyline: number[][], isElevationFromOSM: boolean): Point[] {
        return polyline.map((coordinate: number[]): Point => {
            let point = new Point(coordinate[0], coordinate[1]);
            if (coordinate.length > 2) {
                point.elevation = coordinate[2];
                point.originalElevation = coordinate[2];
                point.isOriginalElevationFromOSM = isElevationFromOSM;
            }
            return point;
        });
    }

    static get allAutoRouteProfiles(): AutoRouteProfile[] {
        return [
            AutoRouteProfile.OSM_CYCLING_RECOMMENDED,
            AutoRouteProfile.OSM_CYCLING_MOUNTAIN,
            AutoRouteProfile.OSM_ROAD
        ];
    }

    static autoRouteProfileBy(value: number): AutoRouteProfile {
        switch (value) {
            case AutoRouteProfile.OSM_CYCLING_RECOMMENDED:
                return AutoRouteProfile.OSM_CYCLING_RECOMMENDED;
            case AutoRouteProfile.OSM_CYCLING_MOUNTAIN:
                return AutoRouteProfile.OSM_CYCLING_MOUNTAIN;
            case AutoRouteProfile.OSM_ROAD:
                return AutoRouteProfile.OSM_ROAD;
        }
    }

    static autoRouteProfileName(value: AutoRouteProfile): string {
        switch (value) {
            case AutoRouteProfile.OSM_CYCLING_RECOMMENDED:
                return Resources.text.auto_route_profile_cycling_recommended;
            case AutoRouteProfile.OSM_CYCLING_MOUNTAIN:
                return Resources.text.auto_route_profile_cycling_mountain;
            case AutoRouteProfile.OSM_ROAD:
                return Resources.text.auto_route_profile_road;
        }
    }

    static autoRouteProfileIdForGraphhopper(value: AutoRouteProfile): string {
        switch (value) {
            case AutoRouteProfile.OSM_CYCLING_RECOMMENDED:
                return 'bike'
            case AutoRouteProfile.OSM_CYCLING_MOUNTAIN:
                return 'mtb';
            case AutoRouteProfile.OSM_ROAD:
                return 'motorcycle';
        }
    }

    static autoRouteProfileIdForORS(value: AutoRouteProfile): string {
        switch (value) {
            case AutoRouteProfile.OSM_CYCLING_RECOMMENDED:
                return 'cycling-regular';
            case AutoRouteProfile.OSM_CYCLING_MOUNTAIN:
                return 'cycling-mountain';
            case AutoRouteProfile.OSM_ROAD:
                return 'cycling-road'
        }
    }
}

export class DirectDirector implements Director {
    static instance = new DirectDirector();

    private constructor() { }
    name: string = 'DirectDirector';

    async loadDirection(point1: Point, point2: Point): Promise<Point[]> {
        let latLng1 = new google.maps.LatLng(point1.latitude, point1.longitude, null);
        let latLng2 = new google.maps.LatLng(point2.latitude, point2.longitude, null);
        let distance = google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2);

        var numberOfPoint = distance / 5; // 5 미터 단위로 나눈다
        var dLatitude = point2.latitude - point1.latitude;
        var dLongitude = point2.longitude - point1.longitude;
        var uLatitude = dLatitude / numberOfPoint;
        var uLongitude = dLongitude / numberOfPoint;

        let points: Point[] = [];
        for (let i = 0; i < numberOfPoint; i++) {
            let nLatitude = point1.latitude + i * uLatitude;
            let nLongitude = point1.longitude + i * uLongitude;
            let point = new Point(nLatitude, nLongitude);
            // 일단은 point1의 고도에서 변화가 없다고 가정한다.
            point.elevation = point1.elevation
            points.push(point);
        }

        let point = new Point(point2.latitude, point2.longitude);
        // 일단은 point1의 고도에서 변화가 없다고 가정한다.
        point.elevation = point1.elevation;
        points.push(point);

        // 빠르게 그려나갈 수 있도록, 고도 조회는 비동기로 처리한다.
        ElevationLoader.loadElevations(points).then(() => {
            let section = ApplicationState.course.sectionOfPoint(points[0]);
            if (!section) {
                return;
            }

            ApplicationState.course.smoothElevations(SmoothElevationTask.elevationSmoothLevel);
            ApplicationState.executeListeners(ApplicationEvent.SECTION_CHANGED, section);
        });

        points.forEach(point => {
            point.isForLoadDirection = true;
        });

        return points;
    }
}
