import { Course, Point, MapSection } from "./Ridingazua.Model";
import { ElevationLoader } from "./Ridingazua.ElevationLoader";
import { isNothing } from "./Ridingazua.Utility";

export class CourseAnalyzer {
    private course: Course;
    private elevationSmoothLevel = 15;

    constructor(course: Course) {
        this.course = course.clone();
    }

    async analyzeClimbs(): Promise<MapSection[]> {
        let course = this.course;

        await this.refreshElevations();

        course.updateTotalValues();
        course.smoothElevations(this.elevationSmoothLevel);

        let allPoints = course.allPoints();
        let pointsWithWaypoint = allPoints.filter(point => (point.waypoint != null));
        let courseRanges = course.findClimbsAdvanced();
        let climbs = courseRanges.map(item => { return MapSection.fromCourseRange(item, course); });

        climbs.forEach((climb, climbIndex) => {
            let courseRange = courseRanges[climbIndex];
            // 200m 이내에 웨이포인트가 있다면 그것을 오르막 구간의 이름으로 활용해본다.
            let nearestPointWithWaypoint: Point | undefined;
            let nearestPointWithWaypointDistance: number | undefined;
            pointsWithWaypoint.forEach(point => {
                let d = Math.abs(courseRange.finishPoint.distanceFromCourseStart - point.distanceFromCourseStart);
                if (d < 200) {
                    if (
                        isNothing(nearestPointWithWaypointDistance) ||
                        d < nearestPointWithWaypointDistance
                    ) {
                        nearestPointWithWaypoint = point;
                        nearestPointWithWaypointDistance = d;
                    }
                }
            });

            if (nearestPointWithWaypoint) {
                climb.name = nearestPointWithWaypoint.waypoint.name;
            }
        });

        return climbs;
    }

    private refreshElevations(): Promise<void> {
        console.log('start refresh course elevations.');
        let points = this.course.allPoints();
        return ElevationLoader.loadElevations(points);
    }
}