import { ApplicationState, ApplicationEvent, ApplicationEventListener } from './Ridingazua.ApplicationState';
import { Point, Section, Waypoint, WaypointType } from '../common/Ridingazua.Model';
import { Resources } from './Ridingazua.Resources';
import { MenuItem, MenuController } from './Ridingazua.MenuController';
import { WaypointDialogContorller, AddWaypointTask } from './Ridingazua.WaypointDialogController';
import { TaskManager } from './Ridingazua.TaskManager';
import { SplitTask, SectionEditor } from './Ridingazua.SectionEditor';
import { DirectDirector, Director, AutoRouteDirector } from './Ridingazua.Director';
import { StorageController } from './Ridingazua.StorageController';
import { sprintf } from 'sprintf-js';
import { KeyState } from './Ridingazua.KeyState';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Geometry } from '../common/Ridingazua.Geometry';
import { Statics } from '../common/Ridingazua.Statics';
import { Utility } from '../common/Ridingazua.Utility';
import { SectionListDialogController } from './Ridingazua.SectionListDialogController';
import { OverlayImageMapSettingsDialogController } from './Ridingazua.OverlayImageMapSettingsDialogController';

export enum MapType {
    OPEN_STREET_MAP = 1,
    ROADMAP,
    TERRAIN,
    SATELLITE,
    HYBRID,
    OVERLAY_IMAGE_MAP,
}

export class MapController implements ApplicationEventListener {
    static defaultZoomToPoint = 15;
    static zIndexForSelectedRangeRect = 3;
    static zIndexForSelectedSection = 5;
    static zIndexForDeselectedSection = 4;
    static zIndexForSelectedRange = 6;
    static zIndexForMapSection = 7;
    static zIndexForSelectedMapSection = 8;
    static zIndexForStartFinishMarker = 7000;
    static zIndexForInstructionMarker = 7100;
    static zIndexForWaypointMarker = 7200;
    static zIndexForPlaceMarker = 7300;
    static zIndexForMyLocationMarker = 7400;
    static zIndexForCursorMarker = 7500;
    static zIndexForSelectedPlaceMarker = 7600;
    static zIndexForSelectedRangeInfoLayer = 5900;
    static zIndexForCursorInfoLayer = 6000;

    private static _selectedMapType: MapType = MapController.mapTypeBy(parseInt(StorageController.get('selectedMapType')));

    static get selectedMapType(): MapType {
        return this._selectedMapType;
    }

    static set selectedMapType(value: MapType) {
        if (this._selectedMapType == value) {
            return;
        }

        this._selectedMapType = value;
        StorageController.set('selectedMapType', `${value}`);
        this.updateMapType(ApplicationState.map);
        ApplicationState.executeListeners(ApplicationEvent.SELECTED_MAP_TYPE_CHANGED);
    }

    private static _selectedDirector?: Director;

    static get selectedDirector(): Director {
        if (!this._selectedDirector) {
            switch (StorageController.get('selectedDirector')) {
                case AutoRouteDirector.instance.name:
                    this._selectedDirector = AutoRouteDirector.instance;
                    break;
                case DirectDirector.instance.name:
                    this._selectedDirector = DirectDirector.instance;
                    break;
                default:
                    this._selectedDirector = AutoRouteDirector.instance;
                    break;
            }
        }

        return this._selectedDirector || AutoRouteDirector.instance;
    }

    static set selectedDirector(value: Director) {
        this._selectedDirector = value;
        StorageController.set('selectedDirector', value.name);
        ApplicationState.executeListeners(ApplicationEvent.SELECTED_DIRECTOR_CHANGED);
    }

    private static instance: MapController;

    private _placeMarkers?: google.maps.Marker[];

    get placeMarkers(): google.maps.Marker[] | null {
        return this._placeMarkers;
    }

    set placeMarkers(value: google.maps.Marker[] | null) {
        this._placeMarkers?.forEach((marker) => {
            marker.setMap(null);
        });

        this._placeMarkers = value;
    }

    private _myLocationMarker?: google.maps.Marker;

    get myLocationMarker(): google.maps.Marker | null {
        return this._myLocationMarker;
    }

    set myLocationMarker(value: google.maps.Marker | null) {
        this._myLocationMarker?.setMap(null);
        this._myLocationMarker = value;
    }

    private _showingInfoWindow?: google.maps.InfoWindow;

    get showingInfoWindow(): google.maps.InfoWindow | null {
        return this._showingInfoWindow;
    }

    set showingInfoWindow(value: google.maps.InfoWindow) {
        this._showingInfoWindow?.close();
        this._showingInfoWindow = value;
    }

    private constructor() {
        ApplicationState.addListener(this);
    }

    static getInstance(): MapController {
        if (!this.instance) {
            this.instance = new MapController();
        }
        return this.instance;
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        let map = ApplicationState.map;
        if (!map) {
            return;
        }

        switch (event) {
            case ApplicationEvent.SET_CURSOR:
                let point = arg as Point;
                if (!point) {
                    return;
                }
                MapController.setCursorMarker(point);
                MapController.setCursorInfoLayer(point);
                break;

            case ApplicationEvent.REMOVE_CURSOR:
                MapController.removeCursorMarker();
                MapController.removeCursorInfoLayer();
                break;

            case ApplicationEvent.POSITION_WATCH_STARTED:
                toastr.info(Resources.text.finding_my_location);
                break;

            case ApplicationEvent.POSITION_RECEIVED:
                if (!map) {
                    return;
                }

                let position = arg as GeolocationPosition;
                let latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                map.setCenter(latLng);
                map.setZoom(MapController.defaultZoomToPoint);

                let marker = new google.maps.Marker({
                    map: map,
                    title: Resources.text.my_location,
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillColor: '#4285F4',
                        fillOpacity: 1.0,
                        strokeColor: '#ffffff',
                        strokeWeight: 2,
                        scale: 5,
                    },
                    position: latLng,
                    zIndex: MapController.zIndexForMyLocationMarker,
                });

                marker.addListener('click', () => {
                    this.myLocationMarker = null;
                });

                this.myLocationMarker = marker;
                break;

            case ApplicationEvent.POSITION_FAILED:
                let errorMessage = arg && arg.message;
                toastr.error(`${Resources.text.failed_to_my_location}(${errorMessage})`);
                this.myLocationMarker = null;
                break;
        }
    }

    static didMapCenterChange() {
        this.resetDivInfoLayerPositionIfRequired();
    }

    static didMapZoomChange() {
        this.resetDivInfoLayerPositionIfRequired();
    }

    /**
     * 시작점 또는 끝점에 대한 marker를 생성한다.
     * @param isStart
     * @param point
     */
    static createStartOrEndPointMarker(isStart: boolean, point: Point): google.maps.Marker {
        let iconUrl = isStart ? Statics.image('start_marker.png') : Statics.image('end_marker.png');
        let icon = {
            url: iconUrl,
            scaledSize: new google.maps.Size(10, 10),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 5),
        };

        return new google.maps.Marker({
            map: ApplicationState.map,
            position: new google.maps.LatLng(point.latitude, point.longitude),
            icon: icon,
            zIndex: MapController.zIndexForStartFinishMarker,
        });
    }

    private static cursorMarker?: google.maps.Marker;

    /**
     * 코스의 Polyline 상에 현재 커서가 가리키는 지점을 표시하는 marker를 삽입한다.
     * @param point
     */
    static setCursorMarker(point: Point) {
        if (this.cursorMarker) {
            this.cursorMarker.setMap(ApplicationState.map);
            this.cursorMarker.setPosition(new google.maps.LatLng(point.latitude, point.longitude));
            return;
        }

        let icon = {
            url: Statics.image('start_marker.png'),
            scaledSize: new google.maps.Size(10, 10),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 5),
        };

        this.cursorMarker = new google.maps.Marker({
            map: ApplicationState.map,
            position: new google.maps.LatLng(point.latitude, point.longitude),
            icon: icon,
            draggable: false,
            clickable: true,
            zIndex: MapController.zIndexForCursorMarker,
        });
        this.cursorMarker.setCursor('crosshair');

        this.cursorMarker.addListener('click', (event) => {
            let latLng = event.latLng as google.maps.LatLng;

            if (KeyState.shiftKey) {
                SectionEditor.selectedEditor.didMapClick(latLng.lat(), latLng.lng());
                return;
            }

            let menuDidShow = MapController.showMenuForSectionLine(latLng.lat(), latLng.lng(), ApplicationState.selectedSection);
            if (menuDidShow) {
                return;
            }

            for (let section of ApplicationState.course.sections) {
                let virtualPointInfo = section.virtualPointInfoByCoord(latLng.lat(), latLng.lng());
                if (virtualPointInfo) {
                    SectionListDialogController.isDisableScrollToSelectedSection = true;
                    ApplicationState.selectedSection = section;
                    SectionListDialogController.isDisableScrollToSelectedSection = false;
                    SectionListDialogController.scrollToSelectedSectionVisible(true);
                    return;
                }
            }
        });

        this.cursorMarker.addListener('rightclick', (event) => {
            let latLng = event.latLng as google.maps.LatLng;

            let menuDidShow = MapController.showMenuForSectionLine(latLng.lat(), latLng.lng(), ApplicationState.selectedSection);
            if (menuDidShow) {
                return;
            }

            for (let section of ApplicationState.course.sections) {
                menuDidShow = MapController.showMenuForSectionLine(latLng.lat(), latLng.lng(), section);
                if (menuDidShow) {
                    return;
                }
            }
        });
    }

    /**
     * cursor marker 제거
     */
    static removeCursorMarker() {
        if (!this.cursorMarker) {
            return;
        }

        this.cursorMarker.setMap(null);
    }

    private static divInfoLayer: HTMLDivElement;
    private static spanInfoLayerDirection: HTMLSpanElement;
    private static divInfoLayerText: HTMLDivElement;

    private static createDivInfoLayer() {
        if (this.divInfoLayer) {
            return;
        }

        let divInfoLayer = document.createElement('div');
        divInfoLayer.classList.add('cursor-info-layer');
        this.divInfoLayer = divInfoLayer;

        let divInfoLayerDirection = document.createElement('div');
        divInfoLayer.appendChild(divInfoLayerDirection);
        divInfoLayerDirection.style.textAlign = 'center';

        let spanDirection = document.createElement('span');
        divInfoLayerDirection.appendChild(spanDirection);
        this.spanInfoLayerDirection = spanDirection;
        spanDirection.textContent = '→';
        spanDirection.style.display = 'inline-block';
        spanDirection.style.fontWeight = 'bolder';
        spanDirection.style.fontSize = '15pt';
        spanDirection.style.width = '20px';
        spanDirection.style.height = '20px';
        spanDirection.style.lineHeight = '20px';
        spanDirection.style.textAlign = 'center';
        spanDirection.style.verticalAlign = 'middle';

        let divInfoLayerText = document.createElement('div');
        this.divInfoLayerText = divInfoLayerText;
        divInfoLayer.appendChild(divInfoLayerText);
        divInfoLayerText.style.marginTop = '5px';
        divInfoLayerText.style.fontSize = '7pt';
    }

    private static pointForInfoLayer?: Point;

    /**
     * 코스의 Polyline 상에 현재 커서가 가리키는 지점의 정보를 표시하는 레이어를 띄운다.
     * @param point
     */
    private static setCursorInfoLayer(point: Point) {
        let course = ApplicationState.course;
        let nextPoint = course.getNextPointOfPosition(point.distanceFromCourseStart);
        let elevationAndSlope = course.getElevationAndSlopeOfPosition(point.distanceFromCourseStart);
        if (!nextPoint || !elevationAndSlope) {
            return;
        }

        this.pointForInfoLayer = point;
        this.createDivInfoLayer();

        let line = new Geometry.Line(new Geometry.Point(point.longitude, point.latitude), new Geometry.Point(nextPoint.longitude, nextPoint.latitude));
        this.spanInfoLayerDirection.style.transform = `rotate(${(-line.angleDegree).toFixed(0)}deg)`;

        let elevation = elevationAndSlope[0];
        let slope = elevationAndSlope[1];
        let components = [
            `${Resources.text.position}: ${(point.distanceFromCourseStart / 1000).toFixed(2)}km`,
            `${Resources.text.elevation}: ${elevation.toFixed(1)}m`,
            `${Resources.text.slope}: ${(slope * 100).toFixed(1)}%`,
        ];
        this.divInfoLayerText.innerHTML = components.join('<br />');
        let position = this.convertPointToPixelPosition(point.latitude, point.longitude);
        let x = position[0];
        let y = position[1];
        HTMLUtility.showElementInContainer(this.divInfoLayer, ApplicationState.map.getDiv(), x, y, this.zIndexForCursorInfoLayer, 5);
    }

    private static resetDivInfoLayerPositionIfRequired() {
        if (!this.divInfoLayer?.parentElement || !this.pointForInfoLayer) {
            return;
        }
        this.setCursorInfoLayer(this.pointForInfoLayer);
    }

    private static removeCursorInfoLayer() {
        this.pointForInfoLayer = null;
        this.divInfoLayer?.remove();
    }

    /**
     * 경로상의 모든 영역이 모두 보이도록 지도를 조정한다.
     */
    static setVisibleAllPoints(points: Point[]) {
        let bounds = MapUtility.getBoundsFromPoints(points);
        if (!bounds) {
            return;
        }
        ApplicationState.map.fitBounds(bounds, 30);
        if(ApplicationState.map.getZoom() > 15) {
            ApplicationState.map.setZoom(15);
        }
        // Log.d(`show all points = ${north}, ${east}, ${south} ${west}`);
    }

    static convertPointToPixelPosition(latitude: number, longitude: number): [number, number] {
        let map = ApplicationState.map;
        let mapBounds = map.getBounds();
        let mapProjection = map.getProjection();

        var scale = Math.pow(2, map.getZoom());
        var northWest = new google.maps.LatLng(mapBounds.getNorthEast().lat(), mapBounds.getSouthWest().lng());
        var worldCoordinateNorthWest = mapProjection.fromLatLngToPoint(northWest);
        var worldCoordinate = mapProjection.fromLatLngToPoint(new google.maps.LatLng(latitude, longitude));
        var latLngOffset = new google.maps.Point(Math.floor((worldCoordinate.x - worldCoordinateNorthWest.x) * scale), Math.floor((worldCoordinate.y - worldCoordinateNorthWest.y) * scale));

        return [latLngOffset.x, latLngOffset.y];
    }

    static openKakaoMap(latitude: number, longitude: number) {
        window.open(`https://map.kakao.com/link/map/${latitude},${longitude}`, 'ridingazua_kakaomap');
    }

    static openKakaoMapRoadview(latitude: number, longitude: number) {
        window.open(`https://map.kakao.com/link/roadview/${latitude},${longitude}`, 'ridingazua_kakaomap');
    }

    static showMenu(point: Point, menuItems: MenuItem[]) {
        let position = this.convertPointToPixelPosition(point.latitude, point.longitude);
        let x = position[0];
        let y = position[1];

        // Application에 document.onclick에 의해 MenuController.dismissAll() 처리가 있다.
        // MenuController.dismissAll 보다는 menu가 나중에 떠야하므로 지연시키는 처리.
        setTimeout((args) => {
            let menuController = new MenuController(menuItems);
            let mapDiv = ApplicationState.map.getDiv();
            menuController.showIn(mapDiv, x, y);
            menuController.onMouseover = () => {
                ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, point);
            };
            menuController.onDismiss = () => {
                ApplicationState.executeListeners(ApplicationEvent.REMOVE_CURSOR);
            };
            setTimeout(() => {
                ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, point);
            }, 0);
        }, 0);
    }

    /**
     * 경로선(polyline)을 클릭시 노출할 메뉴 항목들
     * @param point
     * @param section
     */
    private static menusForSectionLine(point: Point, section: Section): MenuItem[] {
        let menuItems: MenuItem[] = [
            {
                id: 'addWayPoint',
                name: Resources.text.add_waypoint,
                forEditing: true,
                action: () => {
                    let virtualPointInfo = section.virtualPointInfoByCoord(point.latitude, point.longitude);
                    WaypointDialogContorller.showUsingDistance(virtualPointInfo.point.distanceFromCourseStart);
                },
            },
            {
                id: 'addLeftWayPoint',
                name: sprintf(Resources.text.add_waypoint_type_format, Resources.text.waypoint_type_left),
                forEditing: true,
                action: () => {
                    let virtualPointInfo = section.virtualPointInfoByCoord(point.latitude, point.longitude);
                    TaskManager.doTask(new AddWaypointTask(virtualPointInfo.point.distanceFromCourseStart, new Waypoint(WaypointType.Left, Resources.textForLanguage('en').waypoint_type_left)));
                },
            },
            {
                id: 'addRightWayPoint',
                name: sprintf(Resources.text.add_waypoint_type_format, Resources.text.waypoint_type_right),
                forEditing: true,
                action: () => {
                    let virtualPointInfo = section.virtualPointInfoByCoord(point.latitude, point.longitude);
                    TaskManager.doTask(new AddWaypointTask(virtualPointInfo.point.distanceFromCourseStart, new Waypoint(WaypointType.Right, Resources.textForLanguage('en').waypoint_type_right)));
                },
            },
            {
                id: 'split',
                name: Resources.text.split_section,
                forEditing: true,
                action: () => {
                    TaskManager.doTask(new SplitTask(section, point.latitude, point.longitude));
                },
            },
            {
                id: 'drawLine',
                name: Resources.text.draw_line_to_this_point,
                forEditing: true,
                action: () => {
                    SectionEditor.selectedEditor.didMapClick(point.latitude, point.longitude);
                },
            },
        ];

        let isSouthKorea = Utility.pointInPolygin([point.latitude, point.longitude], ApplicationState.boundsSouthKorea);
        if (isSouthKorea) {
            menuItems.push({
                id: 'kakaoMap',
                name: Resources.text.view_kakao_map_this_point,
                action: () => {
                    this.openKakaoMap(point.latitude, point.longitude);
                },
            });
            menuItems.push({
                id: 'kakaoMapRoadview',
                name: Resources.text.view_kakao_map_road_view_this_point,
                action: () => {
                    this.openKakaoMapRoadview(point.latitude, point.longitude);
                },
            });
        }

        return menuItems.filter((menuItem) => { 
            if(SectionEditor.isLocked) {
                return menuItem.forEditing != true;
            } else {
                return true;
            }
        });
    }

    /**
     * 선택된 지점에 섹션 편집용 메뉴를 표시한다.
     * @param latitude
     * @param longitude
     * @param section
     */
    static showMenuForSectionLine(latitude: number, longitude: number, section: Section): boolean {
        if (!section) {
            return false;
        }

        let virtualPointInfo = ApplicationState.selectedSection.virtualPointInfoByCoord(latitude, longitude);
        if (!virtualPointInfo) {
            return false;
        }

        this.showMenu(virtualPointInfo.point, this.menusForSectionLine(virtualPointInfo.point, section));
        return true;
    }

    static addOsmTypeToMap(map: google.maps.Map) {
        map.mapTypes.set(
            MapController.mapTypeId(MapType.OPEN_STREET_MAP),
            new google.maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                    // "Wrap" x (longitude) at 180th meridian properly
                    // NB: Don't touch coord.x: because coord param is by reference, and changing its x property breaks something in Google's lib
                    var tilesPerGlobe = 1 << zoom;
                    var x = coord.x % tilesPerGlobe;
                    if (x < 0) {
                        x = tilesPerGlobe + x;
                    }
                    // Wrap y (latitude) in a like manner if you want to enable vertical infinite scrolling

                    return `https://tile.openstreetmap.org/${zoom}/${coord.x}/${coord.y}.png`;
                },
                tileSize: new google.maps.Size(256, 256),
                name: 'OpenStreetMap',
                maxZoom: 18,
            })
        );
    }

    static showOverlayImageMapSettings() {
        OverlayImageMapSettingsDialogController.show();
    }

    static addOverlayImageMap(map: google.maps.Map) {
        let isEnabled = StorageController.get('overlay_image_map_enabled') || 'false';
        if (isEnabled != 'true') {
            return;
        }

        let opacity = 5;
        try {
            opacity = parseInt(StorageController.get('overlay_image_map_opacity') || '5');
        } catch (error) {
            // do nothing
        }

        let urlTemplate = StorageController.get(OverlayImageMapSettingsDialogController.keyForImageOverlayUrlTemplate) || '';
        if (!urlTemplate.length) {
            return;
        }

        if (!OverlayImageMapSettingsDialogController.isValidImageMapTypeUrlTemplate(urlTemplate)) {
            return;
        }

        let overlayImageMapType = new google.maps.ImageMapType({
            getTileUrl: (coord, zoom) => {
                let tileUrl = urlTemplate.replace('{zoom}', zoom.toString());
                tileUrl = tileUrl.replace('{x}', coord.x.toString());
                tileUrl = tileUrl.replace('{y}', coord.y.toString());
                return tileUrl;
            },
            tileSize: new google.maps.Size(256, 256),
            name: Resources.text.map_type_overlay_image_map,
            opacity: opacity / 10.0,
            maxZoom: 15,
        });

        map.overlayMapTypes.insertAt(0, overlayImageMapType);

        if (map.getZoom() > overlayImageMapType.maxZoom) {
            map.setZoom(overlayImageMapType.maxZoom);
        }
    }

    static removeOverlayImageMap(map: google.maps.Map) {
        for (let index = 0; index < map.overlayMapTypes.getLength(); index++) {
            let mapType = map.overlayMapTypes.getAt(index);
            if (mapType.name == Resources.text.map_type_overlay_image_map) {
                map.overlayMapTypes.removeAt(index);
                break;
            }
        }
    }

    static updateMapType(map: google.maps.Map) {
        map.setMapTypeId(this.mapTypeId(this.selectedMapType));
    }

    static get allMapTypes(): MapType[] {
        return [MapType.OPEN_STREET_MAP, MapType.ROADMAP, MapType.TERRAIN, MapType.SATELLITE, MapType.HYBRID];
    }

    static mapTypeName(mapType: MapType): string {
        switch (mapType) {
            case MapType.OPEN_STREET_MAP:
                return Resources.text.map_type_osm;
            case MapType.ROADMAP:
                return Resources.text.map_type_google_road;
            case MapType.TERRAIN:
                return Resources.text.map_type_google_terrain;
            case MapType.SATELLITE:
                return Resources.text.map_type_google_satellite;
            case MapType.HYBRID:
                return Resources.text.map_type_google_hybrid;
            case MapType.OVERLAY_IMAGE_MAP:
                return Resources.text.map_type_overlay_image_map;
            default:
                return Resources.text.map_type_osm;
        }
    }

    static mapTypeId(mapType: MapType): string {
        switch (mapType) {
            case MapType.OPEN_STREET_MAP:
                return 'OSM';
            case MapType.ROADMAP:
                return 'roadmap';
            case MapType.TERRAIN:
                return 'terrain';
            case MapType.SATELLITE:
                return 'satellite';
            case MapType.HYBRID:
                return 'hybrid';
            case MapType.OVERLAY_IMAGE_MAP:
                return 'overlay_image_map';
            default:
                return 'OSM';
        }
    }

    static mapTypeBy(value: number): MapType {
        switch (value) {
            case MapType.OPEN_STREET_MAP:
                return MapType.OPEN_STREET_MAP;
            case MapType.ROADMAP:
                return MapType.ROADMAP;
            case MapType.TERRAIN:
                return MapType.TERRAIN;
            case MapType.SATELLITE:
                return MapType.SATELLITE;
            case MapType.HYBRID:
                return MapType.HYBRID;
            case MapType.OVERLAY_IMAGE_MAP:
                return MapType.OVERLAY_IMAGE_MAP;
            default:
                return MapType.OPEN_STREET_MAP;
        }
    }
}

export class MapUtility {
    /**
     * bounds를 반환
     * @param points
     * @param margin 상대적인 값이다. 가로/세로 중 긴 축의 길이에 margin 을 곱해서 나온 값만큼 확장한 영역을 반환하게 된다.
     */
    static getBoundsFromPoints(points: Point[], margin: number = 0): google.maps.LatLngBounds | null {
        if (!points || !points.length) {
            return null;
        }

        let south: number;
        let west: number;
        let north: number;
        let east: number;

        points.forEach((point) => {
            let latitude = point.latitude;
            let longitude = point.longitude;
            if (north == undefined || latitude > north) {
                north = latitude;
            }
            if (south == undefined || latitude < south) {
                south = latitude;
            }
            if (east == undefined || longitude > east) {
                east = longitude;
            }
            if (west == undefined || longitude < west) {
                west = longitude;
            }
        });

        let marginValue = 0;
        if (margin) {
            let longLength = Math.max(Math.abs(east - west), Math.abs(north - south));
            marginValue = longLength * margin;
        }

        return new google.maps.LatLngBounds(new google.maps.LatLng(south - marginValue, west - marginValue), new google.maps.LatLng(north + marginValue, east + marginValue));
    }

    /**
     * Returns the zoom level at which the given rectangular region fits in the map view.
     * The zoom level is computed for the currently selected map type.
     * @param {google.maps.Map} map
     * @param {google.maps.LatLngBounds} bounds
     * @return {Number} zoom level
     **/
    static getZoomByBounds(map: google.maps.Map, bounds: google.maps.LatLngBounds): number {
        // var MAX_ZOOM = map.mapTypes.get(map.getMapTypeId()).maxZoom || 21;
        // var MIN_ZOOM = map.mapTypes.get(map.getMapTypeId()).minZoom || 0;
        let MAX_ZOOM = 21;
        let MIN_ZOOM = 0;

        let ne = map.getProjection().fromLatLngToPoint(bounds.getNorthEast());
        let sw = map.getProjection().fromLatLngToPoint(bounds.getSouthWest());

        let worldCoordWidth = Math.abs(ne.x - sw.x);
        let worldCoordHeight = Math.abs(ne.y - sw.y);

        //Fit padding in pixels
        let FIT_PAD = 40;

        for (let zoom = MAX_ZOOM; zoom >= MIN_ZOOM; --zoom) {
            if (worldCoordWidth * (1 << zoom) + 2 * FIT_PAD < $(map.getDiv()).width() && worldCoordHeight * (1 << zoom) + 2 * FIT_PAD < $(map.getDiv()).height()) return zoom;
        }

        return 0;
    }
}
