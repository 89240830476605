import { APIManager } from './Ridingazua.APIManager';
import { MenuController } from './Ridingazua.MenuController';

/**
 * input text에 검색용 태그 자동완성을 위한 기능을 부여한다.
 */
export class InputTagAutocompleteController {
    private inputTag: HTMLInputElement;
    private doNotLoadAutocomplete = false;

    /**
     * 마지막에 공백 또는 ,(comma)가 입력되었을 경우에 호출됨
     */
    onInputWhitespace?: (value: string) => any;

    /**
     * 공백이 중간에 포함된 텍스트가 입력되었을 경우에 호출됨
     */
    onInputValueIncludesWhitespace?: (value: string) => any;

    /**
     * 엔터키 이벤트 처리
     */
    onEnterKeyDown?: (value: string) => any;

    /**
     * 태그가 선택되었을 때 처리
     */
    onSelectTagItem?: (tag: string) => any;

    constructor(inputTag: HTMLInputElement) {
        this.inputTag = inputTag;
        inputTag.autocomplete = 'off';
        inputTag.onblur = () => {
            this.doNotLoadAutocomplete = true;
            setTimeout(() => {
                this.doNotLoadAutocomplete = false;
            }, 100);
            this.autocompleteMenuController.dismiss();
        };
        inputTag.oninput = (event) => {
            this.onInputTagChange();
        };
        inputTag.onchange = (event) => {
            this.onInputTagChange();
        };
        inputTag.onkeydown = (event) => {
            let key = event.key.toLowerCase();
            switch (key) {
                case 'enter':
                    this.onInputTagEnterKeyDown();
                    break;
                case 'esc':
                case 'escape':
                    // 자동완성 목록이 떠있을 경우, esc 키에 의해 자동완성 목록이 닫힐 수 있는데,
                    // escape 키를 누를 경우, 입력이 끝나지 않은 글자에 대한 처리가 완료되면서, inputTag.onchange를 유발하고, 자동완성이 다시 노출될 수 있다
                    // 따라서 일정 시간동안 자동완성이 뜨는 것을 막아야한다.
                    this.doNotLoadAutocomplete = true;
                    setTimeout(() => {
                        this.doNotLoadAutocomplete = false;
                    }, 500);
                    break;
                case 'arrowdown':
                case 'arrowup':
                    this.onInputTagArrowUpDownKeyDown(key);
                    return false;
            }
        };
    }

    onInputTagChange() {
        setTimeout(() => {
            let value = this.inputTag.value || '';
            value = value.replace(/\\s/gi, ' ');
            while (value.includes(',')) {
                value = value.replace(',', ' ');
            }
            while (value.includes('.')) {
                value = value.replace('.', ' ');
            }

            if (!value.length) {
                this.autocompleteMenuController.dismiss();
                return;
            }

            let lastString = value.substring(value.length - 1, value.length);
            let includesWhitespace = value.search('\\s') >= 0;
            if (!lastString.trim().length) {
                // 마지막 글자가 화이트스페이스: 모든 글자를 태그로 적용
                if (this.onInputWhitespace) {
                    this.onInputWhitespace(value);
                }
            } else if (includesWhitespace) {
                // 화이트스페이스가 포함되어있음: 현재 입력중인 단어 이전까지만 태그 적용
                if (this.onInputValueIncludesWhitespace) {
                    this.onInputValueIncludesWhitespace(value);
                }
            } else {
                this.loadTagListForAutocomplete(value);
            }
        }, 1);
    }

    private onInputTagEnterKeyDown() {
        // 한글 문자 입력이 완전히 종료되지 않은 상태에서, value를 get하면 마지막글자가 두번 나온다.
        // 그래서 지연시키도록 한다.
        setTimeout(() => {
            if (this.autocompleteMenuController.isShowing) {
                if (this.autocompleteMenuController.menu.find('.ui-state-active').length) {
                    this.autocompleteMenuController.menu.menu('select');
                    return;
                }
            }

            if (this.onEnterKeyDown) {
                this.onEnterKeyDown(this.inputTag.value || '');
            }
        }, 1);
    }

    private onInputTagArrowUpDownKeyDown(key: string) {
        setTimeout(() => {
            if (!this.autocompleteMenuController.isShowing) {
                return;
            }

            let add = 0;
            switch (key.toLowerCase()) {
                case 'arrowup':
                    add = -1;
                    break;
                case 'arrowdown':
                    add = 1;
                    break;
                default:
                    return;
            }

            let menu = this.autocompleteMenuController.menu;

            if (this.autocompleteSelectedIndex < 0) {
                menu.menu('previous');
            } else if (add > 0) {
                menu.menu('next');
            } else {
                menu.menu('previous');
            }
        }, 100);
    }

    private autocompleteMenuController = new MenuController([]);
    private autocompleteSelectedIndex = -1;

    private async loadTagListForAutocomplete(keyword: string) {
        if (this.doNotLoadAutocomplete) {
            return;
        }

        let tagCourseCountList = await APIManager.loadTagList(keyword);

        // 자동완성 표시용 태그목록을 로드하는 사이에 입력값이 변경되었을 경우, 진행하지 않는다.
        if (this.inputTag.value.trim() != keyword) {
            return;
        }

        var tags: string[] = [];
        for (let element of tagCourseCountList) {
            tags.push(element.tag);
        }

        let i = 0;
        this.autocompleteMenuController.menuItems = tags.map((tag) => {
            return {
                id: `${i++}`,
                name: tag,
                action: () => {
                    if (this.onSelectTagItem) {
                        this.onSelectTagItem(tag);
                    }
                    this.autocompleteMenuController.dismiss();

                    // onSelectTagItem 호출로 인해 inputTag에 tag가 입력되고, 그로 인해 다시 autocomplete dialog가 표시될 수 있다
                    // 따라서 일정시간동안 조회가 되지 않도록 한다.
                    this.doNotLoadAutocomplete = true;
                    setTimeout(() => {
                        this.doNotLoadAutocomplete = false;
                    }, 500);
                },
            };
        });

        this.autocompleteMenuController.show(this.inputTag, { position: 'bottom' });
    }

    dismiss() {
        this.autocompleteMenuController.menuItems = [];
        this.autocompleteMenuController.dismiss();
    }
}
