export namespace Geometry {
    export class GeoUtility {
        static degreeFromRadian(radian: number): number {
            return radian * (180 / Math.PI);
        }

        static radianFromDegree(degree: number): number {
            return degree * (Math.PI / 180);
        }

        static distance(point1: Point, point2: Point): number {
            return Math.abs(Math.sqrt(Math.pow((point1.x - point2.x), 2) + Math.pow((point1.y - point2.y), 2)));
        }
    }

    export class Point {
        x: number;
        y: number;

        constructor(x: number, y: number) {
            this.x = x;
            this.y = y;
        }

        /**
         * 중심점을 기준으로 회전된 점
         * @param center 중심점
         * @param angle 회전각(radian)
         */
        rotatedPoint(center: Point, angle: number): Point {
            let newX = center.x + (this.x - center.x) * Math.cos(angle) - (this.y - center.y) * Math.sin(angle);
            let newY = center.y + (this.x - center.x) * Math.sin(angle) - (this.y - center.y) * Math.cos(angle);
            return new Point(newX, newY);
        }

        /**
         * 다른 점과의 거리
         * @param point 
         */
        distanceBy(point: Point): number {
            return GeoUtility.distance(this, point);
        }
    }

    export class Line {
        point1: Point;
        point2: Point;

        constructor(point1: Point, point2: Point) {
            this.point1 = point1;
            this.point2 = point2;
        }

        stroke(context: CanvasRenderingContext2D) {
            context.beginPath();
            context.moveTo(this.point1.x, this.point1.y);
            context.lineTo(this.point2.x, this.point2.y);
            context.closePath();
            context.stroke();
        }

        get dx(): number {
            return this.point2.x - this.point1.x;
        }

        get dy(): number {
            return this.point2.y - this.point1.y;
        }

        get length(): number {
            return GeoUtility.distance(this.point1, this.point2);
        }

        get angleRadian(): number {
            return Math.atan2(this.dy, this.dx);
        }

        get angleDegree(): number {
            return GeoUtility.degreeFromRadian(this.angleRadian);
        }

        /**
         * point1 으로부터 선을 따라 distance만큼 떨어져있는 가상의 point를 생성하여 반환
         * @param distance 
         */
        pointInLine(distance: number): Point {
            let angle = this.angleRadian;
            let newX = this.point1.x + distance * Math.cos(angle)
            let newY = this.point1.y + distance * Math.sin(angle)
            return new Point(newX, newY);
        }

        /**
         * Line을 width 만큼 확장하여 Rect로 만든다.
         * @param width 
         */
        extendToRect(width: number): Polygon {
            let radius = width / 2;
            let pointInLine = this.pointInLine(radius);
            let rectPoint1 = pointInLine.rotatedPoint(this.point1, GeoUtility.radianFromDegree(90));
            let rectPoint2 = pointInLine.rotatedPoint(this.point1, GeoUtility.radianFromDegree(270));

            pointInLine = this.pointInLine(this.length - radius);
            let rectPoint3 = pointInLine.rotatedPoint(this.point2, GeoUtility.radianFromDegree(270));
            let rectPoint4 = pointInLine.rotatedPoint(this.point2, GeoUtility.radianFromDegree(90));

            return new Polygon([rectPoint1, rectPoint3, rectPoint4, rectPoint2]);
        }

        /**
         * Line을 연장한다.
         * @param beginDistance 시작점에서 연장할 길이
         * @param endDistance 끝점에서 연장할 길이
         */
        extend(beginDistance: number, endDistance: number): Line {
            let point1 = this.pointInLine(-beginDistance);
            let point2 = this.pointInLine(this.length + endDistance);
            return new Line(point1, point2);
        }
    }

    export class Polyline {
        points: Point[] = [];

        constructor(points: Point[]) {
            this.points = points;
        }

        stroke(context: CanvasRenderingContext2D) {
            this.points.forEach((point, index, points) => {
                if (index == 0) {
                    context.beginPath();
                    context.moveTo(point.x, point.y);
                } else {
                    context.lineTo(point.x, point.y);
                }

                if (index == points.length - 1) {
                    context.stroke();
                }
            });
        }

        extendToPolygon(width: number): Polygon {
            if (!this.points.length) {
                return new Polygon([]);
            }

            if (this.points.length == 1) {
                let point = this.points[0];
                return new Polygon([
                    new Point(point.x - (width / 2), point.y - (width / 2)),
                    new Point(point.x + (width / 2), point.y - (width / 2)),
                    new Point(point.x + (width / 2), point.y + (width / 2)),
                    new Point(point.x - (width / 2), point.y + (width / 2))
                ]);
            }

            let firstLine = new Line(this.points[0], this.points[1]);
            let extendedLineBeforeFirst = firstLine.extend(width / 2, 0);
            let pointBeforeFirst = extendedLineBeforeFirst.point1;

            let lastLine = new Line(this.points[this.points.length - 2], this.points[this.points.length - 1]);
            let extendedLineAfterLast = lastLine.extend(0, width / 2);
            let pointAfterLast = extendedLineAfterLast.point2;

            let pointsToPolygon = [pointBeforeFirst].concat(this.points).concat([pointAfterLast]);

            // polyline의 한쪽으로 지나는 점들
            let points1: Point[] = [];

            // points1의 반대쪽으로 지나는 점들
            let points2: Point[] = [];

            for (let i = 1; i < pointsToPolygon.length; i++) {
                let point1 = pointsToPolygon[i - 1];
                let point2 = pointsToPolygon[i];

                let line = new Line(point1, point2);
                let rect = line.extendToRect(width);
                points1.push(rect.points[0], rect.points[1]);
                points2.push(rect.points[3], rect.points[2]);
            }

            let points = points1.concat(points2.reverse());
            return new Polygon(points);
        }

        /**
         * 점과 점 사이가 minDistancePoint 보다 클 경우, minDistancePoint에 해당하는 지점마다 새 point를 넣는다.
         * @param minDistancePoint 
         */
        addSubPoints(minDistancePoint: number) {
            let cursor = 0;
            while (true) {
                let isLast = (cursor === this.points.length - 1);
                if (isLast) {
                    break;
                }

                let point1 = this.points[cursor];
                let point2 = this.points[cursor + 1];
                let line = new Line(point1, point2);
                if (line.length > minDistancePoint) {
                    let newPoint = line.pointInLine(minDistancePoint);
                    this.points.splice(cursor + 1, 0, newPoint);
                }
                cursor++;
            }
        }

        /**
         * polyline의 sub line들을 extension 만큼 면으로 확장한 후, otherPolyline의 점들을 그것에 넣어보았을 때, 
         * otherPolyline의 point 갯수 중 몇 개의 point가 들어가는지 비율
         * @param otherPolyline 
         * @param extension 
         */
        coverageRate(otherPolyline: Polyline, extension: number): number {
            if (this.points.length <= 1) {
                return 0;
            }

            if (!otherPolyline.points.length) {
                return 0;
            }

            let subPolygons: Polygon[] = [];

            for (let i = 1; i < this.points.length; i++) {
                let point1 = this.points[i - 1];
                let point2 = this.points[i];
                let subPolyline = new Polyline([point1, point2]);
                let subPolygon = subPolyline.extendToPolygon(extension);
                subPolygons.push(subPolygon);
            }

            let result = 0;
            for (let point of otherPolyline.points) {
                for (let subPolygon of subPolygons) {
                    if (subPolygon.contains(point)) {
                        result++;
                        break;
                    }
                }
            }

            return result / otherPolyline.points.length;
        }
    }

    /**
     * 다각형
     */
    export class Polygon {
        points: Point[] = [];

        constructor(points: Point[]) {
            this.points = points;
        }

        fill(context: CanvasRenderingContext2D) {
            this.points.forEach((point, index, points) => {
                if (index == 0) {
                    context.beginPath();
                    context.moveTo(point.x, point.y);
                } else {
                    context.lineTo(point.x, point.y);
                }

                if (index == points.length - 1) {
                    context.closePath();
                    context.fill()
                }
            });
        }

        /**
         * 면적
         */
        get area(): number {
            let result = 0;

            this.points.forEach((point, index, points) => {
                let addX = point.x;
                let addY = points[index == points.length - 1 ? 0 : index + 1].y;
                let subX = points[index == points.length - 1 ? 0 : index + 1].x;
                let subY = point.y;
                result += (addX * addY * 0.5);
                result -= (subX * subY * 0.5);
            });

            return Math.abs(result);
        }

        /**
         * 한 점이 이 다각형 내부에 속하는지 판단
         * @param point 
         */
        contains(point: Point): boolean {
            let result = false;

            for (let i = 0, j = this.points.length - 1; i < this.points.length; j = i++) {
                let xi = this.points[i].x, yi = this.points[i].y;
                let xj = this.points[j].x, yj = this.points[j].y;

                let intersect =
                    ((yi > point.y) != (yj > point.y)) &&
                    (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);

                if (intersect) {
                    result = !result;
                }
            }

            return result;
        }
    }
}