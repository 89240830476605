import { UserAccountType, Course, CourseRange, User, MapSection, AdvertisementPosition, Advertisement, UploadedFileInfo, TagCourseCount } from '../common/Ridingazua.Model';
import Axios, { AxiosResponse } from 'axios';
import { Statics } from '../common/Ridingazua.Statics';
import { StatusCode } from '../common/Ridingazua.StatusCode';

export class APIManager {
    static apiURL(path: APIPath): string {
        let currentUrl = new URL(window.location.href);
        let host = currentUrl.hostname;
        let port = currentUrl.port;
        return `https://${host}:${port}${Statics.apiPath}/${path}`;
    }

    static async requestAPI(method: (url: string, parameters: any) => Promise<AxiosResponse>, apiPath: APIPath, parameters: any): Promise<APIResponse> {
        let response = await method(this.apiURL(apiPath), parameters);
        return APIResponse.fromAxiosResponse(response);
    }

    private static transactionIdCursor = 0;

    static newTransactionId(): number {
        this.transactionIdCursor++;
        return this.transactionIdCursor;
    }

    static messageFromResponseError(error: any): string | null | undefined {
        let response = error && error.response;
        if (!response) {
            return null;
        }

        let apiResponse = APIResponse.fromAxiosResponse(response);
        return apiResponse.message;
    }

    static async checkSession(): Promise<User | null> {
        let response = await this.requestAPI(Axios.get, APIPath.checkSession, null);
        let user = User.fromJson(response.result.user);
        return user;
    }

    static async login(accountType: UserAccountType, accessToken: string): Promise<User | null> {
        let response = await this.requestAPI(Axios.post, APIPath.login, {
            accountType: accountType,
            accessToken: accessToken,
        });
        return response.result.user;
    }

    static async logout(): Promise<void> {
        await this.requestAPI(Axios.post, APIPath.logout, null);
    }

    static async saveUserNick(nick: string): Promise<void> {
        await this.requestAPI(Axios.put, APIPath.userNick, {
            nick: nick,
        });
    }

    static async saveCourse(course: Course, saveForce?: boolean): Promise<number> {
        let method = course.id ? Axios.put : Axios.post;
        let response = await this.requestAPI(method, APIPath.course, {
            course: course.toJson(),
            saveForce: saveForce,
        });
        return response.result.courseId;
    }

    static async loadCourse(courseId: number): Promise<Course> {
        let response = await this.requestAPI(Axios.get, APIPath.course, {
            params: { courseId: courseId },
        });
        return Course.fromJson(response.result.course);
    }

    static async loadCourseList(
        page: number,
        pageSize: number,
        loadPublic: boolean,
        userNick: string | null,
        searchKeyword: string,
        sortColumn: string,
        isSortAsc: boolean,
        loadAllIfSuperuser: boolean
    ): Promise<CourseListResponse> {
        try {
            let response = await this.requestAPI(Axios.get, APIPath.courseList, {
                params: {
                    page: page,
                    pageSize: pageSize,
                    loadPublic: loadPublic,
                    userNick: userNick,
                    searchKeyword: searchKeyword,
                    sortColumn: sortColumn,
                    isSortAsc: isSortAsc,
                    loadAllIfSuperuser: loadAllIfSuperuser,
                },
            });

            let courseList = (response.result.courseList as any[]).map((item) => {
                return Course.fromJson(item);
            });
            let courseCount = response.result.courseCount;
            let maxPage = Math.floor((courseCount - 1) / pageSize + 1);

            return {
                courseList: courseList,
                courseCount: courseCount,
                maxPage: maxPage,
            };
        } catch (error) {
            throw error;
        }
    }

    static async deleteCourse(courseId: number): Promise<void> {
        await this.requestAPI(Axios.delete, APIPath.course, {
            params: { courseId: courseId },
        });
    }

    static async loadSegmentList(north: number, east: number, south: number, west: number): Promise<MapSection[]> {
        let response = await this.requestAPI(Axios.get, APIPath.segmentList, {
            params: { north: north, east: east, south: south, west: west },
        });

        let segmentList = (response.result as any[]).map((item) => {
            return MapSection.fromJson(item);
        });
        return segmentList || [];
    }

    static async setCoursePathName(courseId: number, pathName: string | null): Promise<void> {
        await this.requestAPI(Axios.post, APIPath.coursePathName, {
            courseId: courseId,
            pathName: pathName,
        });
    }

    static async loadAdList(): Promise<Advertisement[]> {
        let response = await this.requestAPI(Axios.get, APIPath.adList, {});
        let adList = (response.result.adList as any[]).map((item) => {
            return Advertisement.fromJson(item);
        });
        return adList;
    }

    static async loadShowingAdList(): Promise<Advertisement[]> {
        let response = await this.requestAPI(Axios.get, APIPath.showingAdList, {});
        let adList = (response.result.adList as any[]).map((item) => {
            return Advertisement.fromJson(item);
        });
        return adList;
    }

    static async uploadFile(file: File): Promise<UploadedFileInfo> {
        let formData = new FormData();
        formData.append('file', file);
        let axiosResponse = await Axios.post(this.apiURL(APIPath.upload), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        let response = APIResponse.fromAxiosResponse(axiosResponse);
        return UploadedFileInfo.fromJson(response.result);
    }

    static async saveAd(item: Advertisement): Promise<number | null> {
        let response = await this.requestAPI(Axios.post, APIPath.ad, { advertisement: item.toJson() });
        if ((response.status = StatusCode.OK)) {
            let id = parseInt(response.result['id']);
            return id;
        }

        return null;
    }

    static async deleteAd(item: Advertisement): Promise<void> {
        await this.requestAPI(Axios.delete, APIPath.ad, {
            params: { adId: item.id },
        });
    }

    static async loadTagList(keyword: string): Promise<TagCourseCount[]> {
        let response = await this.requestAPI(Axios.get, APIPath.tagList, {
            params: { keyword: keyword },
        });
        let tagList = (response.result.tagList as any[]).map((item) => {
            return TagCourseCount.fromJson(item);
        });
        return tagList;
    }
}

/**
 * API path 목록은 여기에 나열한다.
 */
class APIPath {
    static checkSession = 'checkSession';
    static login = 'login';
    static logout = 'logout';
    static userNick = 'userNick';
    static course = 'course';
    static courseList = 'courseList';
    static segmentList = 'segmentList';
    static coursePathName = 'coursePathName';
    static adList = 'adList';
    static showingAdList = 'showingAdList';
    static ad = 'ad';
    static tagList = 'tagList';
    static upload = 'upload';
}

/**
 * axios를 사용하여 API request 후 받게되는 response를 wrapping 한다.
 */
class APIResponse {
    status?: number;
    result: any;
    message?: string;

    static fromAxiosResponse(axiosResponse: AxiosResponse): APIResponse {
        let response = new APIResponse();
        let data = axiosResponse.data;
        if (data) {
            response.result = data.result;
            response.message = data.message;
        }

        return response;
    }
}

interface CourseListResponse {
    courseList: Course[];
    courseCount: number;
    maxPage: number;
}
