import { Chart } from './Ridingazua.Chart';
import { ElevationChartController } from './Ridingazua.ElevationChartController';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';

export class ElevationChartImageDialogController {
    private static instance: ElevationChartImageDialogController;

    private inputWidth: HTMLInputElement;
    private inputHeight: HTMLInputElement;
    private dialog: JQuery;

    private defaultWidth = 2000;
    private defaultHeight = 600;

    private constructor() {
        let div = document.createElement('div');
        HTMLUtility.appendInputHiddenAutofocus(div);

        div.appendChild(this.createInputDiv());
        div.appendChild(this.createDescription());

        this.dialog = $(div).dialog({
            title: Resources.text.elevation_chart_save_as_image,
            modal: true,
            buttons: [
                {
                    text: Resources.text.elevation_chart_generate_image,
                    click: () => {
                        this.generateImage();
                    },
                },
            ],
            close: () => {
                this.chart = null;
            },
        });
    }

    private createInputDiv(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('children-spacing');
        div.classList.add('center');

        let labelWidth = document.createElement('label');
        labelWidth.textContent = Resources.text.image_width;
        labelWidth.setAttribute('for', 'input-elevation-chart-image-width');
        div.appendChild(labelWidth);

        let inputWidth = document.createElement('input');
        inputWidth.style.textAlign = 'center';
        inputWidth.style.width = '50px';
        inputWidth.placeholder = `${this.defaultWidth}`;
        inputWidth.value = `${this.defaultWidth}`;
        inputWidth.id = 'input-elevation-chart-image-width';
        inputWidth.type = 'text';
        inputWidth.maxLength = 4;
        div.appendChild(inputWidth);
        this.inputWidth = inputWidth;

        let spanMultiply = document.createElement('span');
        spanMultiply.textContent = ' × ';
        div.appendChild(spanMultiply);

        let labelHeight = document.createElement('label');
        labelHeight.textContent = Resources.text.image_height;
        labelHeight.setAttribute('for', 'input-elevation-chart-image-height');
        div.appendChild(labelHeight);

        let inputHeight = document.createElement('input');
        inputHeight.style.textAlign = 'center';
        inputHeight.style.width = '50px';
        inputHeight.placeholder = `${this.defaultHeight}`;
        inputHeight.value = `${this.defaultHeight}`;
        inputHeight.id = 'input-elevation-chart-image-height';
        inputHeight.type = 'text';
        inputHeight.maxLength = 4;
        div.appendChild(inputHeight);
        this.inputHeight = inputHeight;

        return div;
    }

    private createDescription(): HTMLParagraphElement {
        let p = document.createElement('p');
        p.style.marginTop = '5px';
        p.textContent = Resources.text.elevation_chart_generate_image_description;
        return p;
    }

    private chart?: Chart;

    static show(chart: Chart) {
        if (!this.instance) {
            this.instance = new ElevationChartImageDialogController();
        }

        this.instance.show(chart);
    }

    private show(chart: Chart) {
        this.dialog.dialog('open');
        this.chart = chart;
    }

    private windowForImage: Window;

    private generateImage() {
        if (this.windowForImage) {
            this.windowForImage.close();
        }

        let sourceChart = this.chart;
        let pixelRatio = 2; // 모든 기기에서 일관되게 그려지도록 하기 위해, window.devicePixelRatio와는 다른 pixelRatio를 사용한다.
        let width = (parseInt(this.inputWidth.value) || this.defaultWidth) / pixelRatio;
        let height = (parseInt(this.inputHeight.value) || this.defaultHeight) / pixelRatio;

        let windowForImage = window.open('about:blank', Resources.text.elevation_chart_image);
        this.windowForImage = windowForImage;
        windowForImage.document.title = Resources.text.elevation_chart_image;

        let div = document.createElement('div');
        div.setAttribute('width', `${width}`);
        div.setAttribute('height', `${height}`);
        div.style.width = `${width}px`;
        div.style.height = `${height}px`;
        windowForImage.document.body.appendChild(div);

        let chartForImage = ElevationChartController.createChart(
            div,
            {
                minWidth: null,
                forImage: true,
                pixelRatio: pixelRatio
            }
        );
        chartForImage.originalData = sourceChart.originalData;
        ElevationChartController.updateChartSizeConfiguration(chartForImage, width, height);
        chartForImage.redrawChart();

        let dataUrl = chartForImage.chartCanvas.toDataURL('image/png');
        let imageElement = document.createElement('img');
        imageElement.src = dataUrl;
        imageElement.alt = Resources.text.elevation_chart;

        // firefox에서 이미지가 보이지 않는 현상이 있어서 100ms timeout 추가
        setTimeout(() => {
            windowForImage.document.body.appendChild(imageElement);
        }, 100);

        div.remove();
    }
}
