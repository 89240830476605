import { ApplicationState, ApplicationEventListener, ApplicationEvent } from './Ridingazua.ApplicationState';
import { Point, WaypointType, Waypoint, CourseExtra } from '../common/Ridingazua.Model';
import { Resources } from './Ridingazua.Resources';
import { WaypointDialogContorller, DeleteWaypointTask } from './Ridingazua.WaypointDialogController';
import { TaskManager } from './Ridingazua.TaskManager';
import { HTMLUtility, CheckboxController } from './Ridingazua.HTMLUtility';
import { MapController } from './Ridingazua.MapController';
import { Statics } from '../common/Ridingazua.Statics';
import { ElevationChartController } from './Ridingazua.ElevationChartController'
import { isNothing } from '../common/Ridingazua.Utility';
import { SectionEditor } from './Ridingazua.SectionEditor';

export enum WaypointListDialogType {
    NORMAL,
    SET_VISIBLE_IN_ELEVATION_CHART
}

export class WaypointListDialogController implements ApplicationEventListener {
    private static instanceForNormal: WaypointListDialogController;
    private static instanceForSetElevationChartVisible: WaypointListDialogController;
    private type: WaypointListDialogType;

    private static getInstance(type: WaypointListDialogType, createIfNull: boolean = true): WaypointListDialogController {
        switch (type) {
            case WaypointListDialogType.NORMAL:
                if (!this.instanceForNormal && createIfNull) {
                    this.instanceForNormal = new WaypointListDialogController(type);
                }
                return this.instanceForNormal;
            case WaypointListDialogType.SET_VISIBLE_IN_ELEVATION_CHART:
                if (!this.instanceForSetElevationChartVisible && createIfNull) {
                    this.instanceForSetElevationChartVisible = new WaypointListDialogController(type);
                }
                return this.instanceForSetElevationChartVisible;
            default:
                return this.getInstance(WaypointListDialogType.NORMAL);
        }
    }

    static show(type: WaypointListDialogType) {
        let instance = this.getInstance(type);
        instance.show()
    }

    static toggle(type: WaypointListDialogType) {
        let instance = this.getInstance(type, false);
        if (!instance) {
            this.show(type);
        } else {
            if (instance.dialog.dialog('isOpen')) {
                instance.close();
            } else {
                instance.show();
            }
        }
    }

    constructor(type: WaypointListDialogType) {
        this.type = type;
        this.updateColumnNames();
        this.updateColumnWidths();
        this.updateDialogButtons();

        let div = document.createElement('div');
        div.appendChild(this.createTable());
        this.dialog = $(div).dialog({
            title: Resources.text.waypoints,
            modal: true,
            width: 'auto',
            maxWidth: window.innerWidth * 0.9,
            maxHeight: window.innerHeight * 0.9,
            open: () => {
                ApplicationState.addListener(this);
                this.resetSize();
            },
            close: () => {
                ApplicationState.removeListener(this);
            },
            buttons: this.dialogButtons
        });
    }

    private show() {
        this.update()
        this.resetSize();
        this.dialog?.dialog('open');
    }

    private close() {
        this.dialog?.dialog('close');
    }

    private dialog?: JQuery;
    private tbody: HTMLTableSectionElement;
    private columnNames: string[];
    private columnWidths: string[];
    private dialogButtons: JQueryUI.DialogButtonOptions[];

    private updateColumnNames() {
        switch (this.type) {
            case WaypointListDialogType.NORMAL:
                this.columnNames = [
                    Resources.text.waypoint_type,
                    Resources.text.waypoint_name,
                    `${Resources.text.waypoint_position}(km)`,
                    `${Resources.text.waypoint_elevation}(m)`,
                    Resources.text.waypoint_edit
                ];
                break;

            case WaypointListDialogType.SET_VISIBLE_IN_ELEVATION_CHART:
                this.columnNames = [
                    Resources.text.waypoint_type,
                    Resources.text.waypoint_name,
                    `${Resources.text.waypoint_position}(km)`,
                    `${Resources.text.waypoint_elevation}(m)`,
                    Resources.text.waypoint_set_visible_in_elevation_chart
                ];
                break;
        }
    }

    private updateColumnWidths() {
        switch (this.type) {
            case WaypointListDialogType.NORMAL:
                this.columnWidths = [
                    '35px', '', '70px', '70px', '70px'
                ];
                break;
            case WaypointListDialogType.SET_VISIBLE_IN_ELEVATION_CHART:
                this.columnWidths = [
                    '35px', '', '70px', '70px', ''
                ];
                break;
        }
    }

    private updateDialogButtons() {
        switch (this.type) {
            case WaypointListDialogType.SET_VISIBLE_IN_ELEVATION_CHART:
                this.dialogButtons = [
                    {
                        text: Resources.text.waypoint_set_all_default_visible_in_elevation_chart,
                        click: () => {
                            this.setAllVisibleInElevationChart(null);
                            this.close();
                        }
                    },
                    {
                        text: Resources.text.waypoint_show_all_default_visible_in_elevation_chart,
                        click: () => {
                            this.setAllVisibleInElevationChart(true);
                            this.close();
                        }
                    },
                    {
                        text: Resources.text.waypoint_hide_all_default_visible_in_elevation_chart,
                        click: () => {
                            this.setAllVisibleInElevationChart(false);
                            this.close();
                        }
                    },
                    {
                        text: Resources.text.close,
                        click: () => {
                            this.close();
                        }
                    },
                ]
                break;
        }
    }

    private setAllVisibleInElevationChart(visible: boolean | null) {
        ApplicationState.course.extra.isVisibleStartInElevationChart = visible;
        ApplicationState.course.extra.isVisibleFinishInElevationChart = visible;
        ApplicationState.course.allPoints().forEach(point => {
            let waypoint = point.waypoint;
            if (!waypoint) {
                return;
            }
            waypoint.isVisibleInElevationChart = visible;
        });
        ElevationChartController.refresh();
        ApplicationState.executeListeners(ApplicationEvent.SAVE_EDITING_COURSE);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.WAYPOINTS_CHANGED:
                this.update();
                break;
        }
    }

    private createTable() {
        let table = document.createElement('table');
        table.style.width = '100%';
        table.style.marginBottom = '5px';

        let thead = document.createElement('thead');
        table.appendChild(thead);

        let tr = document.createElement('tr');
        thead.appendChild(tr);

        this.columnNames.forEach((columnName, index) => {
            let th = document.createElement('th');
            th.style.width = this.columnWidths[index];
            th.style.maxWidth = this.columnWidths[index];
            th.textContent = columnName;
            tr.appendChild(th);
        });

        let tbody = document.createElement('tbody');
        table.appendChild(tbody);
        this.tbody = tbody;

        return table;
    }

    private update() {
        this.tbody.innerHTML = '';

        ApplicationState.course.updateTotalValues();
        let allPoints = ApplicationState.course.allPoints();

        let pointsHasWaypoint: Point[] = [];
        allPoints.forEach((point) => {
            if (point.waypoint) {
                pointsHasWaypoint.push(point);
            }
        });

        if (allPoints.length < 2) {
            this.showPlaceholder(Resources.text.no_waypoints);
            return;
        }

        let startPoint = allPoints[0];
        let lastPoint = allPoints[allPoints.length - 1];
        
        // 시작점 추가
        if(!startPoint.waypoint) {
            this.addTableRowForPoint(startPoint);
        }
        
        // 모든 waypoint 나열
        pointsHasWaypoint.forEach((point) => {
            this.addTableRowForPoint(point);
        });

        // 종료점 추가
        if(!lastPoint.waypoint) {
            this.addTableRowForPoint(lastPoint);
        }
    }

    private showPlaceholder(message: string) {
        this.tbody.innerHTML = '';

        let tr = document.createElement('tr');
        this.tbody.appendChild(tr);

        let td = document.createElement('td');
        td.classList.add('placeholder');
        td.colSpan = this.columnNames.length;
        td.textContent = message;
        tr.appendChild(td);
    }

    private addTableRowForPoint(point: Point) {
        let tr = document.createElement('tr');
        this.tbody.appendChild(tr);

        let waypoint = point.waypoint;
        let allPoints = ApplicationState.course.allPoints();

        let isStartPoint = allPoints[0] == point;
        let isEndPoint = allPoints[allPoints.length - 1] == point;

        if (!waypoint) {
            if (isStartPoint) {
                waypoint = new Waypoint(WaypointType.Start, Resources.text.course_start);
            } else if (isEndPoint) {
                waypoint = new Waypoint(WaypointType.Finish, Resources.text.course_finish);
            }
        }

        if (!waypoint) {
            return;
        }

        let waypointName = waypoint.name;
        let imgIcon = WaypointListDialogController.createWaypointIconImgElement(waypoint.type);

        let elementsForEdit: HTMLElement[];

        switch (this.type) {
            case WaypointListDialogType.NORMAL:
                if (waypoint.type == WaypointType.Start || waypoint.type == WaypointType.Finish) {
                    // 시작, 종료점은 수정 안됨
                    break;
                }

                let modifyButton = HTMLUtility.createIconButton(
                    Resources.text.waypoint_edit,
                    'edit',
                    () => {
                        WaypointDialogContorller.showUsingPoint(point);
                        this.moveMapToPoint(point);
                    }
                );

                let deleteButton = HTMLUtility.createIconButton(
                    Resources.text.delete,
                    'delete',
                    () => {
                        TaskManager.doTask(
                            new DeleteWaypointTask(point)
                        );
                    }
                );

                modifyButton.disabled = SectionEditor.isLocked;
                modifyButton.style.opacity = SectionEditor.isLocked ? "0.2" : null;
                deleteButton.disabled = SectionEditor.isLocked;
                deleteButton.style.opacity = SectionEditor.isLocked ? "0.2" : null;

                elementsForEdit = [
                    modifyButton,
                    deleteButton,
                ]

                break;

            case WaypointListDialogType.SET_VISIBLE_IN_ELEVATION_CHART:
                // 고도차트에 표시/숨김 체크박스
                let visibleCheckboxController = new CheckboxController();
                let isVisibleInElevationChart: boolean;
                if (waypoint.type == WaypointType.Start) {
                    isVisibleInElevationChart = ApplicationState.course.extra?.isVisibleStartInElevationChart;
                    if (isNothing(isVisibleInElevationChart)) {
                        isVisibleInElevationChart = CourseExtra.defaultVisibleStartInElevationChart;
                    }
                } else if (waypoint.type == WaypointType.Finish) {
                    isVisibleInElevationChart = ApplicationState.course.extra?.isVisibleFinishInElevationChart;
                    if (isNothing(isVisibleInElevationChart)) {
                        isVisibleInElevationChart = CourseExtra.defaultVisibleFinishInElevationChart;
                    }
                } else {
                    isVisibleInElevationChart = waypoint?.isVisibleInElevationChart;
                    if (isNothing(isVisibleInElevationChart)) {
                        isVisibleInElevationChart = Waypoint.defaultVisibleInElevationChart(waypoint?.type);
                    }
                }

                visibleCheckboxController.isChecked = isVisibleInElevationChart;
                visibleCheckboxController.onchange = (isChecked) => {
                    if (waypoint.type === WaypointType.Start) {
                        ApplicationState.course.extra.isVisibleStartInElevationChart = isChecked;
                    } else if (waypoint.type === WaypointType.Finish) {
                        ApplicationState.course.extra.isVisibleFinishInElevationChart = isChecked;
                    } else {
                        waypoint.isVisibleInElevationChart = isChecked;
                    }

                    ElevationChartController.refresh();
                    ApplicationState.executeListeners(ApplicationEvent.SAVE_EDITING_COURSE);
                };

                elementsForEdit = [
                    visibleCheckboxController.div
                ]
                break;
        }

        let tdElements: any[] = [
            imgIcon,
            waypointName,
            (point.distanceFromCourseStart / 1000).toFixed(1),
            (point.elevation || 0).toFixed(1),
            elementsForEdit,
        ];

        for (let element of tdElements) {
            let td = document.createElement('td');
            td.classList.add('center');
            td.classList.add('middle');
            td.classList.add('children-spacing')
            tr.appendChild(td);

            if (typeof element == 'string') {
                td.classList.add('link');
                td.textContent = element as string;
                td.onclick = () => {
                    this.moveMapToPoint(point);
                    this.dialog.dialog('close');
                };
            } else if (element instanceof HTMLElement) {
                td.appendChild(element);
            } else if (element instanceof Array) {
                (element as HTMLElement[]).forEach((element) => {
                    td.appendChild(element);
                });
            }
        }
    }

    private moveMapToPoint(point: Point) {
        ApplicationState.map.setCenter(
            new google.maps.LatLng(point.latitude, point.longitude)
        );
        ApplicationState.map.setZoom(MapController.defaultZoomToPoint);
        ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, point);
    }

    private resetSize() {
        this.dialog?.dialog('option', 'width', 'auto');
        this.dialog?.dialog('option', 'maxWidth', window.innerWidth * 0.9);
        this.dialog?.dialog('option', 'maxHeight', window.innerHeight * 0.9);
    }

    static createWaypointIconImgElement(waypointType: WaypointType): HTMLImageElement {
        let iconImageSrc = Statics.image(waypointType.relativeImageSrc);

        let img = document.createElement('img');
        img.alt = waypointType.name;
        img.style.width = '20px';
        img.style.height = '20px';
        img.src = iconImageSrc;

        return img;
    }
}