import Axios from "axios";
import { Resources } from "./Ridingazua.Resources";
import { Statics } from "../common/Ridingazua.Statics";

export class HelpDialogController {
    private static instance: HelpDialogController;
    private dialog: JQuery;

    private constructor(div: HTMLElement) {
        this.dialog = $(div).dialog({
            title: Resources.text.help,
            modal: true,
            width: window.innerWidth * 0.9,
            height: window.innerHeight * 0.9,
            open: () => {
                if (!this.dialog) {
                    return;
                }

                this.dialog.dialog('option', 'width', window.innerWidth * 0.9);
                this.dialog.dialog('option', 'height', window.innerHeight * 0.9);
            }
        });
    }

    static async show() {
        if (this.instance) {
            this.instance.dialog.dialog('open');
            return;
        }

        try {
            let response = await Axios.get(Statics.editorPathFor('help.html'));
            let div = document.createElement('div');
            div.innerHTML = response.data;
            let element = div.children[0] as HTMLElement;
            element.classList.add('doc');
            this.instance = new HelpDialogController(element);
            this.instance.dialog.dialog('open');
        } catch (error) {
            toastr.error(Resources.text.error)
        }
    }
}