import { AdType, Advertisement, AdvertisementPosition } from '../common/Ridingazua.Model';
import { APIManager } from './Ridingazua.APIManager';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';

export class AdsController {
    private static instance: AdsController;
    
    adType: AdType = AdType.GOOGLE;
    localAds: Advertisement[] = [];
    localAdElementForDialog: HTMLElement;
    localAdAnchorElement: HTMLAnchorElement;
    localAdImageElement: HTMLImageElement;
    googleAdElementForDialog: HTMLElement;
    googleAdElementContainer: HTMLElement;

    static createInstance(): AdsController {
        if (!this.instance) {
            this.instance = new AdsController();
        }

        return this.instance;
    }

    private constructor() {
        this.googleAdElementForDialog = document.getElementById('ins-adsense-dialog');

        let googleAdElementContainer = document.createElement('div');
        googleAdElementContainer.style.textAlign = 'center';
        googleAdElementContainer.style.marginBottom = '10px';
        googleAdElementContainer.style.backgroundColor = '#eeeeee';
        // googleAdElementContainer.textContent = Resources.text.ad_element_info;
        this.googleAdElementContainer = googleAdElementContainer;
        
        let divLocalAd = document.createElement('div');
        divLocalAd.classList.add('advertisement');
        this.localAdElementForDialog = divLocalAd;

        let aLocalAd = document.createElement('a');
        divLocalAd.appendChild(aLocalAd);
        this.localAdAnchorElement = aLocalAd;

        let imgLocalAd = document.createElement('img');
        aLocalAd.appendChild(imgLocalAd);
        this.localAdImageElement = imgLocalAd;

        this.refreshShowingAdList();
    }

    refreshShowingAdList() {
        APIManager.loadShowingAdList().then((ads) => {
            this.localAds = ads;
        });
    }

    /**
     * element의 가장 앞에 advertisement element를 삽입한다. 기존 adElement는 제거된다.
     * @param position
     * @param element
     * @param dialog
     * @param imgSrc
     */
    replaceAdForDialogWithAdPosition(position: AdvertisementPosition | null, element: HTMLElement, dialog: JQuery, imgSrc: String | null = null) {
        this.removeAllAdElements();

        switch(this.adType) {
            case AdType.GOOGLE:
                this.insertGoogleAdForDialog(element, dialog);
                break;

            case AdType.LOCAL:
                if (!position) {
                    return;
                }
        
                let ads = this.localAds.filter((ad) => {
                    return ad.position == position;
                });
        
                if (!ads.length) {
                    return;
                }
        
                let ad = ads[0];
                this.insertLocalAdForDialogWithAd(ad, element, dialog);

                break;
        }
    }

    private removeAllAdElements() {
        this.localAdElementForDialog.remove();
        this.googleAdElementContainer.remove();
    }

    private insertGoogleAdForDialog(element: HTMLElement, dialog: JQuery) {
        this.removeAllAdElements();

        
        if(!this.googleAdElementForDialog) {
            return;
        }

        this.googleAdElementContainer.appendChild(this.googleAdElementForDialog);

        if (!element.childElementCount) {
            element.appendChild(this.googleAdElementContainer);
        } else {
            element.insertBefore(this.googleAdElementContainer, element.children.item(0));
        }

        HTMLUtility.resetDialogPositionToCenter(dialog);
    }

    insertLocalAdForDialogWithAd(ad: Advertisement, element: HTMLElement, dialog: JQuery) {
        this.removeAllAdElements();
        
        if (dialog) {
            this.localAdImageElement.onload = () => {
                HTMLUtility.resetDialogPositionToCenter(dialog);
            };

            this.localAdImageElement.onerror = () => {
                HTMLUtility.resetDialogPositionToCenter(dialog);
            };
        } else {
            this.localAdImageElement.onload = null;
        }

        let adImageWidth = Math.min(window.innerWidth, 500);

        this.localAdImageElement.removeAttribute('src');
        this.localAdImageElement.src = ad.imageUrl(adImageWidth, window.devicePixelRatio) || ad.imageUrlForPreview;
        this.localAdImageElement.style.width = '100%';
        this.localAdImageElement.style.height = 'auto';
        this.localAdAnchorElement.href = ad.link;
        this.localAdAnchorElement.target = '_blank';
        this.localAdAnchorElement.style.width = '100%';
        this.localAdElementForDialog.style.marginBottom = '10px';
        if (!element.childElementCount) {
            element.appendChild(this.localAdElementForDialog);
        } else {
            element.insertBefore(this.localAdElementForDialog, element.children.item(0));
        }

        // 구글 광고는 당분간 표시하지 않는다.
        /*
        this.googleAdElementForDialog.style.display = 'block';
        this.googleAdElementForDialog.style.marginBottom = '10px';
        if (!element.childElementCount) {
            element.appendChild(this.googleAdElementForDialog);
        } else {
            element.insertBefore(this.googleAdElementForDialog, element.children.item(0));
        }
        */
    }

    static get dialogWidthForAds(): number {
        if (window.innerWidth > 500) {
            return 420;
        } else {
            return 340;
        }
    }
}
