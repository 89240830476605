import { APIManager } from './Ridingazua.APIManager';
import { Course, CourseListConfiguration, CoursePermission } from '../common/Ridingazua.Model';
import { CommonDialogController } from './Ridingazua.CommonDialogController';
import { CourseLinkDialogController } from './Ridingazua.CourseLinkDialogController';
import { ApplicationState } from './Ridingazua.ApplicationState';
import { LoginDialogController } from './Ridingazua.LoginDialogController';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { sprintf } from 'sprintf-js';
import { Statics } from '../common/Ridingazua.Statics';
import { isNothing } from '../common/Ridingazua.Utility';
import { InputTagAutocompleteController } from './Ridingazua.TagInputAutocompleteController';
import * as timeago from 'timeago.js';
import { CourseInfoDialogController } from './Ridingazua.CourseInfoDialogController';

export class CourseListDialogController {
    private static instance: CourseListDialogController;

    private _configuration: CourseListConfiguration = CourseListConfiguration.defaultConfiguration();

    private get configuration(): CourseListConfiguration {
        return this._configuration;
    }

    private set configuration(value: CourseListConfiguration) {
        this._configuration = value;
        this.loadCourseList();
    }

    private _maxPage = 0;
    private get maxPage(): number {
        return this._maxPage;
    }
    private set maxPage(value: number) {
        this._maxPage = value;
        this.spanMaxPage.textContent = ` / ${this.maxPage} ${Resources.text.page}`;
    }

    private _courseList: Course[];
    private get courseList(): Course[] {
        return this._courseList;
    }
    private set courseList(value: Course[]) {
        this._courseList = value;
        this.updateCourseList();
    }

    private dialog: JQuery;
    private tbody: HTMLTableSectionElement;
    private theadRow: HTMLTableRowElement;
    private inputPage: HTMLInputElement;
    private spanMaxPage: HTMLSpanElement;
    private inputSearchKeyword: HTMLInputElement;
    private radiosCourseListType: HTMLInputElement[] = [];
    private radioMyCourse: HTMLInputElement;
    private radioPublicCourse: HTMLInputElement;
    private labelPublicCourse: HTMLLabelElement;
    private radioMyCourseElements: HTMLElement[] = [];
    private inputTagAutocompleteController: InputTagAutocompleteController;

    private columnNames = [
        Resources.text.course_name,
        Resources.text.course_owner_name,
        `${Resources.text.course_length}(km)`,
        `${Resources.text.course_elevations}(m)`,
        Resources.text.course_created,
        Resources.text.course_updated,
        Resources.text.course_permission,
        Resources.text.course_more,
    ];

    private columnNamesForSort = ['name', null, 'lengthMeter', 'totalElevationGain', 'createdTimestamp', 'modifiedTimestamp', null, null];

    private columnWidths: number[] = [0, 0, 0, 0, 0, 0, 70];

    private constructor() {
        let div = document.createElement('div');
        HTMLUtility.appendInputHiddenAutofocus(div);

        div.appendChild(this.createSearchForm());
        div.appendChild(this.createTable());
        div.appendChild(this.createPagingDiv());

        this.dialog = $(div).dialog({
            title: Resources.text.open,
            modal: true,
            width: window.innerWidth * 0.9,
            height: 'auto',
            close: () => {
                ApplicationState.replaceWindowHistoryStateByCurrent();
            },
        });
    }

    private resetUrl() {
        document.title = this.configuration.titleForDocument(Resources.text);
        window.history.replaceState({}, document.title, this.configuration.urlString);
    }

    private resetTitle(courseCount?: number) {
        let title = this.configuration.titleForDialog(Resources.text);
        if (courseCount) {
            title += `(${courseCount})`;
        }
        this.dialog.dialog('option', 'title', title);
    }

    private resetRadios() {
        for (let radio of this.radiosCourseListType) {
            let radioValue = radio.value == 'true';
            radio.checked = this.configuration.loadPublic == radioValue;

            if (this.configuration.userNick) {
                radio.disabled = true;
            } else {
                radio.disabled = false;
            }
        }

        this.radioMyCourseElements.forEach((element) => {
            element.style.display = this.configuration.userNick ? 'none' : '';
        });

        this.labelPublicCourse.textContent = this.configuration.userNick ? sprintf(Resources.text.course_list_user_format, this.configuration.userNick) : Resources.text.course_list_public;
    }

    private resetSize() {
        this.dialog.dialog('option', 'width', window.innerWidth * 0.9);
        this.dialog.dialog('option', 'maxHeight', window.innerHeight * 0.9);
        this.dialog.dialog('option', 'height', 'auto');
    }

    private createSearchForm(): HTMLElement {
        let searchForm = document.createElement('form');
        searchForm.classList.add('children-spacing');
        searchForm.classList.add('middle');
        searchForm.style.textAlign = 'right';
        searchForm.style.marginBottom = '5px';
        searchForm.onsubmit = () => {
            this.startSearchCourseList();
            setTimeout(() => {
                this.inputTagAutocompleteController.dismiss();
            }, 100);
            return false;
        };

        let radioMyCourse = document.createElement('input');
        searchForm.appendChild(radioMyCourse);
        radioMyCourse.id = 'radio-course-list-owner-my';
        radioMyCourse.type = 'radio';
        radioMyCourse.name = 'loadPublic';
        radioMyCourse.value = 'false';
        radioMyCourse.onchange = () => {
            if (!radioMyCourse.checked) {
                return;
            }

            let changeConfiguration = () => {
                let configuration = CourseListConfiguration.defaultConfiguration();
                configuration.loadPublic = false;
                configuration.searchKeyword = this.configuration.searchKeyword;
                this.changeConfiguration(configuration);
            };

            if (!ApplicationState.user) {
                LoginDialogController.show(() => {
                    changeConfiguration();
                });
                this.resetRadios();
            } else {
                changeConfiguration();
            }
        };
        this.radioMyCourseElements.push(radioMyCourse);
        this.radiosCourseListType.push(radioMyCourse);
        this.radioMyCourse = radioMyCourse;

        let labelForRadio = document.createElement('label');
        searchForm.appendChild(labelForRadio);
        labelForRadio.setAttribute('for', radioMyCourse.id);
        labelForRadio.style.marginInlineStart = '0';
        labelForRadio.textContent = Resources.text.course_list_my;
        this.radioMyCourseElements.push(labelForRadio);

        let radioPublicCourse = document.createElement('input');
        searchForm.appendChild(radioPublicCourse);
        radioPublicCourse.id = 'radio-course-list-owner-public';
        radioPublicCourse.type = 'radio';
        radioPublicCourse.name = 'loadPublic';
        radioPublicCourse.value = 'true';
        radioPublicCourse.style.marginInlineStart = '5px';
        radioPublicCourse.onchange = () => {
            if (!radioPublicCourse.checked) {
                return;
            }

            let configuration = CourseListConfiguration.defaultConfiguration();
            configuration.loadPublic = true;
            configuration.searchKeyword = this.configuration.searchKeyword;
            this.changeConfiguration(configuration);
        };
        this.radiosCourseListType.push(radioPublicCourse);
        this.radioPublicCourse = radioPublicCourse;

        labelForRadio = document.createElement('label');
        searchForm.appendChild(labelForRadio);
        labelForRadio.setAttribute('for', radioPublicCourse.id);
        labelForRadio.style.marginInlineStart = '0';
        labelForRadio.textContent = Resources.text.course_list_public;
        this.labelPublicCourse = labelForRadio;

        let inputSearchKeyword = document.createElement('input');
        searchForm.appendChild(inputSearchKeyword);
        inputSearchKeyword.type = 'text';
        inputSearchKeyword.autofocus = false;
        inputSearchKeyword.placeholder = Resources.text.course_list_search_keyword_placeholder;
        inputSearchKeyword.style.width = '200px';
        inputSearchKeyword.style.marginInlineStart = '10px';
        this.inputSearchKeyword = inputSearchKeyword;

        let searchButton = document.createElement('button');
        searchButton.textContent = Resources.text.search;
        searchForm.appendChild(searchButton);

        let inputTagAutocompleteController = new InputTagAutocompleteController(inputSearchKeyword);
        inputTagAutocompleteController.onSelectTagItem = (tag) => {
            this.inputSearchKeyword.value = tag;
            this.startSearchCourseList();
        };
        this.inputTagAutocompleteController = inputTagAutocompleteController;

        return searchForm;
    }

    private createTable(): HTMLElement {
        let table = document.createElement('table');
        table.style.width = '100%';
        table.style.marginBottom = '5px';

        let thead = document.createElement('thead');
        table.appendChild(thead);

        let tr = document.createElement('tr');
        this.theadRow = tr;
        thead.appendChild(tr);

        this.resetThElements();

        let tbody = document.createElement('tbody');
        table.appendChild(tbody);
        this.tbody = tbody;

        return table;
    }

    private resetThElements() {
        this.theadRow.innerHTML = '';

        this.columnNames.forEach((columnName, index) => {
            let th = document.createElement('th');
            th.classList.add('center');
            th.classList.add('middle');
            th.classList.add('children-spacing');

            let span = document.createElement('span');
            th.appendChild(span);
            span.textContent = columnName;

            let columnNameForSort = this.columnNamesForSort[index];
            if (columnNameForSort) {
                th.classList.add('link');
                if (this.configuration.sortColumn === columnNameForSort) {
                    let iconElement = HTMLUtility.createIconElement(this.configuration.isSortAsc ? 'arrow_upward' : 'arrow_downward');
                    th.appendChild(iconElement);
                }

                th.onclick = () => {
                    this.changeSortColumn(columnNameForSort);
                };
            }

            let width = this.columnWidths[index];
            if (width) {
                th.style.width = `${width}px`;
            }

            this.theadRow.appendChild(th);
        });
    }

    private createPagingDiv(): HTMLElement {
        let pagingDiv = document.createElement('div');
        pagingDiv.classList.add('children-spacing');
        pagingDiv.style.textAlign = 'right';

        let previousPageButton = document.createElement('button');
        previousPageButton.textContent = Resources.text.prev;
        previousPageButton.onclick = () => {
            this.changeToRelativePage(-1);
        };
        pagingDiv.appendChild(previousPageButton);

        let nextPageButton = document.createElement('button');
        nextPageButton.textContent = Resources.text.next;
        nextPageButton.onclick = () => {
            this.changeToRelativePage(+1);
        };
        pagingDiv.appendChild(nextPageButton);

        let gotoPageButton = document.createElement('button');
        gotoPageButton.textContent = Resources.text.go_to_page;
        gotoPageButton.onclick = () => {
            let page = parseInt(this.inputPage.value);
            if (!page) {
                return;
            }
            this.changeToAbsolutePage(page);
        };
        pagingDiv.appendChild(gotoPageButton);

        let inputPage = document.createElement('input');
        inputPage.type = 'text';
        inputPage.value = this.configuration.page.toString();
        inputPage.style.textAlign = 'center';
        inputPage.style.width = '30px';
        pagingDiv.appendChild(inputPage);
        this.inputPage = inputPage;

        let spanMaxPage = document.createElement('span');
        pagingDiv.appendChild(spanMaxPage);
        this.spanMaxPage = spanMaxPage;

        return pagingDiv;
    }

    static showByUrlString(urlString: string) {
        let configuration = CourseListConfiguration.parseUrlString(urlString);
        if (!configuration) {
            return;
        }

        this.showWithConfiguration(configuration);
    }

    static showWithConfiguration(configuration: CourseListConfiguration) {
        let isLoginRequired = !configuration.userNick && !configuration.loadPublic;

        if (isLoginRequired && !ApplicationState.user) {
            toastr.info(Resources.text.login_required);
            LoginDialogController.show(() => {
                CourseListDialogController.showWithConfiguration(configuration);
            });
            ApplicationState.replaceWindowHistoryStateByCurrent();
            return;
        }

        if (!this.instance) {
            this.instance = new CourseListDialogController();
        }
        this.instance.changeConfiguration(configuration);
        this.instance.dialog.dialog('open');
    }

    private changeConfiguration(configuration: CourseListConfiguration) {
        // 현재 사용자의 nick과 동일할 경우에는 nick을 이용한 검색을 하지 않는다.
        // '내 코스' 목록을 로드하게 된다.
        if (configuration.userNick == ApplicationState.user?.nick) {
            configuration.userNick = null;
        }

        this.showPlaceholder(Resources.text.loading_course_list);
        this.maxPage = 0;

        // let configuration = CourseListConfiguration.defaultConfiguration();
        if (configuration.userNick) {
            configuration.loadPublic = true;
        }

        this.configuration = configuration;
        this.resetTitle();
        this.resetUrl();
        this.resetRadios();
        this.resetSize();
    }

    private showPlaceholder(message: string) {
        this.tbody.innerHTML = '';

        let tr = document.createElement('tr');
        this.tbody.appendChild(tr);

        let td = document.createElement('td');
        td.classList.add('placeholder');
        td.colSpan = this.columnNames.length;
        td.textContent = message;
        tr.appendChild(td);
    }

    private changeSortColumn(columnNameForSort: string) {
        let currentColumnNameForSort = this.configuration.sortColumn;
        this.configuration.sortColumn = columnNameForSort;
        this.configuration.page = 1;

        if (currentColumnNameForSort === columnNameForSort) {
            this.configuration.isSortAsc = !this.configuration.isSortAsc;
        } else {
            this.configuration.isSortAsc = CourseListConfiguration.defaultIsSortOrderAsc(columnNameForSort);
        }
        this.resetThElements();
        this.loadCourseList();
    }

    private changeToRelativePage(value: number) {
        let currentPage = this.configuration.page;
        let newPage = currentPage + value;
        this.changeToAbsolutePage(newPage);
    }

    private changeToAbsolutePage(page: number) {
        let currentPage = this.configuration.page;

        if (page < 1) {
            page = 1;
        }

        if (this.maxPage && page > this.maxPage) {
            page = this.maxPage;
        }

        if (currentPage == page) {
            return;
        }

        this.configuration.page = page;
        this.loadCourseList();
    }

    /**
     * form에 입력된 값들을 이용해 configuration을 구성한 후 코스 검색을 시작한다.
     */
    private startSearchCourseList() {
        this.configuration.page = 1;
        this.configuration.searchKeyword = this.inputSearchKeyword.value.trim();
        this.resetRadios();
        this.loadCourseList();
    }

    private async loadCourseList() {
        let page = this.configuration.page;
        let searchKeyword = this.configuration.searchKeyword;
        let loadPublic = this.configuration.loadPublic;
        let userNick = this.configuration.userNick;

        if (page < 1) {
            return;
        }

        if (this.maxPage && page > this.maxPage) {
            return;
        }

        for (let radio of this.radiosCourseListType) {
            let radioValue = radio.value == 'true';
            radio.checked = radioValue == loadPublic;
        }
        this.inputPage.value = page.toString();
        this.inputSearchKeyword.value = searchKeyword || '';

        let pageSize = 10;
        let sortColumn = this.configuration.sortColumn;
        let isSortAsc = this.configuration.isSortAsc;
        let loadAllIfSuperuser = this.configuration.loadAllIfSuperuser;
        let courseCount = 0;

        try {
            let response = await APIManager.loadCourseList(page, pageSize, loadPublic, userNick, searchKeyword, sortColumn, isSortAsc, loadAllIfSuperuser);
            this.courseList = response.courseList;
            this.maxPage = response.maxPage;
            courseCount = response.courseCount;
        } catch (error) {
            let errorMessage = APIManager.messageFromResponseError(error) || Resources.text.failed_to_load_course_list;
            toastr.error(errorMessage);
            this.showPlaceholder(errorMessage);
        }

        this.resetTitle(courseCount);
        this.resetUrl();
        this.resetRadios();
        this.resetSize();
    }

    private updateCourseList() {
        if (!this.courseList || !this.courseList.length) {
            this.showPlaceholder(Resources.text.no_course_list);
            this.resetSize();
            return;
        }

        this.tbody.innerHTML = '';
        this.courseList.forEach((course) => {
            this.addTableRowForCourse(course);
        });
    }

    private addTableRowForCourse(course: Course) {
        let lengthKm = ((course.lengthMeter || 0) / 1000.0).toFixed(1);
        let totalElevations = (course.totalElevationGain || 0).toFixed(1);
        let createdDate = new Date((course.createdTimestamp || 0) * 1000);
        let modifiedDate = new Date((course.modifiedTimestamp || 0) * 1000);
        let isPublicOrOtherUserCourseList = this.configuration.loadPublic || this.configuration.userNick;

        let coursePermissionText = (): string => {
            switch (course.permission) {
                case CoursePermission.PRIVATE:
                    return Resources.text.course_permission_private;
                case CoursePermission.LINK_PUBLIC_READ_ONLY:
                    return Resources.text.course_permission_link;
                case CoursePermission.ALL_PUBLIC_READ_ONLY:
                    return Resources.text.course_permission_all_public_read_only;
            }
        };

        let currentUser = ApplicationState.user;
        let isMyCourse = course.userId && course.userId == currentUser?.id;
        let userNick = isMyCourse ? currentUser?.nick || currentUser?.name : course.user?.nick || Resources.text.anonymous;
        let userMenuEnabled = isPublicOrOtherUserCourseList && (isMyCourse || course.user.nick);

        let tdTexts: string[] = [
            course.name || '',
            userNick,
            lengthKm,
            totalElevations,
            timeago.format(createdDate, navigator.language),
            timeago.format(modifiedDate, navigator.language),
            coursePermissionText(),
        ];

        let userCourseListButton = HTMLUtility.createIconButton(sprintf(Resources.text.course_list_user_format, userNick), 'account_circle', () => {
            if (!userMenuEnabled) {
                return;
            }

            if (isMyCourse) {
                let configuration = CourseListConfiguration.defaultConfiguration();
                configuration.loadPublic = false;
                CourseListDialogController.showWithConfiguration(configuration);
            } else {
                let configuration = CourseListConfiguration.defaultConfiguration();
                configuration.userNick = userNick;
                CourseListDialogController.showWithConfiguration(configuration);
            }
        });
        userCourseListButton.classList.add('small');
        userCourseListButton.style.marginInlineStart = '3px';

        let additionalElements = [null, userMenuEnabled ? userCourseListButton : null, null, null, null, null, null];

        let tr = document.createElement('tr');
        this.tbody.appendChild(tr);

        let i = 0;
        tdTexts.forEach((text) => {
            let td = document.createElement('td');
            tr.appendChild(td);
            td.classList.add('link');
            td.classList.add('center');
            td.classList.add('middle');
            td.classList.add('children-spacing');
            td.textContent = text;
            td.onclick = () => {
                this.loadCourse(course);
            };

            let additionalElement = additionalElements[i];
            if (additionalElement) {
                td.appendChild(additionalElement);
            }

            i++;
        });

        let td = document.createElement('td');
        td.classList.add('center');
        td.classList.add('middle');
        td.classList.add('children-spacing');
        tr.appendChild(td);

        let buttonDescription = HTMLUtility.createIconButton(Resources.text.course_description, 'info', () => {
            CourseInfoDialogController.show(course);
        });
        td.appendChild(buttonDescription);

        let linkButton = HTMLUtility.createIconButton(Resources.text.link, 'link', () => {
            CourseLinkDialogController.show(course);
        });
        td.appendChild(linkButton);

        if (!isNothing(course.userId) && course.userId === ApplicationState.user?.id) {
            let buttonDelete = HTMLUtility.createIconButton(Resources.text.delete, 'delete', () => {
                this.showDeleteConfirm(course);
            });
            td.appendChild(buttonDelete);
        }
    }

    private showDeleteConfirm(course: Course) {
        CommonDialogController.showConfirm(Resources.text.delete, sprintf(Resources.text.confirm_message_for_delete_course_format, course.name || ''), [
            {
                text: Resources.text.delete,
                action: () => {
                    this.deleteCourse(course);
                },
            },
            {
                text: Resources.text.cancel,
            },
        ]);
    }

    private async deleteCourse(course: Course) {
        try {
            await APIManager.deleteCourse(course.id);
            toastr.success(sprintf(Resources.text.course_deleted_format, course.name || ''));
            this.loadCourseList();
        } catch (error) {
            let message = APIManager.messageFromResponseError(error) || Resources.text.failed_to_delete_course;
            toastr.error(message);
        }
    }

    private loadCourse(course: Course) {
        CommonDialogController.showConfirm(Resources.text.load_course, Resources.text.confirm_message_for_lost, [
            {
                text: Resources.text.ok,
                action: () => {
                    window.localStorage && window.localStorage.removeItem('editingCourse');
                    ApplicationState.doNotShowUnloadAlert = true;
                    location.href = Statics.courseUrlString(course.idOrPathName);
                    ApplicationState.doNotShowUnloadAlert = false;
                },
            },
            {
                text: Resources.text.cancel,
                action: () => {
                    // do nothing
                },
            },
        ]);
    }
}
