import { Point, WaypointType, Waypoint } from '../common/Ridingazua.Model';
import { TaskManager, Task } from './Ridingazua.TaskManager';
import { ApplicationState, ApplicationEvent } from './Ridingazua.ApplicationState';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { isNothing } from '../common/Ridingazua.Utility';
import { SectionEditor } from './Ridingazua.SectionEditor';

export class WaypointDialogContorller {
    private dialog: JQuery;

    private _point: Point;
    private set point(value: Point) {
        this._point = value;
    }
    private get point(): Point {
        return this._point;
    }

    private distance?: number;

    private selectWaypointType: HTMLSelectElement;
    private inputWaypointName: HTMLInputElement;
    private inputWaypointNote: HTMLInputElement;

    private buttonOk: JQueryUI.DialogButtonOptions;
    private buttonDelete: JQueryUI.DialogButtonOptions;

    private static instance: WaypointDialogContorller;

    private constructor() {
        let div = document.createElement('div');
        HTMLUtility.appendInputHiddenAutofocus(div);

        let dl = document.createElement('dl');
        div.appendChild(dl);

        let dt = document.createElement('dt');
        dl.appendChild(dt);

        let label = document.createElement('label');
        label.textContent = Resources.text.waypoint_type;
        label.setAttribute('for', 'select-waypoint-type');
        dt.appendChild(label);

        let dd = document.createElement('dd');
        dd.style.display = 'flex';
        dl.appendChild(dd);

        let selectWaypointType = document.createElement('select');
        selectWaypointType.style.width = '90%';
        selectWaypointType.id = 'select-waypoint-type';
        dd.appendChild(selectWaypointType);

        WaypointType.selectableTypes.forEach((waypointType) => {
            let option = document.createElement('option');
            option.value = waypointType.tag;
            option.textContent = waypointType.name;
            selectWaypointType.appendChild(option);
        });

        dt = document.createElement('dt');
        dl.appendChild(dt);

        label = document.createElement('label');
        label.textContent = Resources.text.waypoint_name;
        label.setAttribute('for', 'input-waypoint-name');
        dt.appendChild(label);

        dd = document.createElement('dd');
        dd.style.display = 'flex';
        dl.appendChild(dd);

        let inputWaypointName = document.createElement('input');
        inputWaypointName.style.width = '90%';
        inputWaypointName.id = 'input-waypoint-name';
        inputWaypointName.type = 'text';
        inputWaypointName.placeholder = Resources.text.waypoint_name;
        inputWaypointName.maxLength = 50;
        dd.appendChild(inputWaypointName);

        dt = document.createElement('dt');
        dl.appendChild(dt);

        label = document.createElement('label');
        label.textContent = Resources.text.waypoint_note;
        label.setAttribute('for', 'input-waypoint-note');
        dt.appendChild(label);

        dd = document.createElement('dd');
        dd.style.display = 'flex';
        dl.appendChild(dd);

        let inputWaypointNote = document.createElement('input');
        inputWaypointNote.style.width = '90%';
        inputWaypointNote.id = 'input-waypoint-note';
        inputWaypointNote.type = 'text';
        inputWaypointNote.placeholder = Resources.text.waypoint_note;
        inputWaypointNote.maxLength = 100;
        dd.appendChild(inputWaypointNote);

        this.selectWaypointType = selectWaypointType;
        this.inputWaypointName = inputWaypointName;
        this.inputWaypointNote = inputWaypointNote;

        let buttonOk: JQueryUI.DialogButtonOptions = {
            text: Resources.text.ok,
            click: () => {
                let waypoint =
                    this.point?.waypoint?.clone() ||
                    new Waypoint(
                        WaypointType.getTypeByTag(selectWaypointType.value),
                        inputWaypointName.value?.trim() || '',
                        inputWaypointNote.value?.trim() || ''
                    );

                waypoint.type = WaypointType.getTypeByTag(selectWaypointType.value);
                waypoint.name = inputWaypointName.value?.trim() || '';
                waypoint.note = inputWaypointNote.value?.trim() || '';

                if (!isNothing(this.distance)) {
                    ApplicationState.course.updateTotalValues();
                    TaskManager.doTask(
                        new AddWaypointTask(
                            this.distance,
                            waypoint
                        )
                    );
                } else if (this.point) {
                    ApplicationState.course.updateTotalValues();
                    TaskManager.doTask(
                        new ModifyWaypointTask(
                            this.point,
                            waypoint
                        )
                    );
                } else {
                    // 이런 상황은 있으면 안됨.
                }

                this.dialog.dialog('close');
            },
        };
        this.buttonOk = buttonOk;

        let buttonDelete: JQueryUI.DialogButtonOptions = {
            text: Resources.text.delete,
            click: () => {
                TaskManager.doTask(
                    new DeleteWaypointTask(this.point)
                );
                this.dialog.dialog('close');
            },
        };
        this.buttonDelete = buttonDelete;

        this.dialog = $(div).dialog({
            title: Resources.text.waypoint,
            modal: true,
            close: () => {
                this.point = null;
                this.distance = null;
                ApplicationState.executeListeners(ApplicationEvent.REMOVE_CURSOR);
            },
        });
    }

    private update() {
        let waypoint = this.point && this.point.waypoint;
        if (!waypoint) {
            this.selectWaypointType.selectedIndex = 0;
            this.inputWaypointName.value = null;
            this.inputWaypointNote.value = null;
        } else {
            this.selectWaypointType.value = waypoint.type.tag;
            this.inputWaypointName.value = waypoint.name || '';
            this.inputWaypointNote.value = waypoint.note || '';
        }

        let buttons: JQueryUI.DialogButtonOptions[] = [this.buttonOk];
        if (this.point) {
            buttons.push(this.buttonDelete);
        }

        this.selectWaypointType.disabled = SectionEditor.isLocked;
        this.inputWaypointName.disabled = SectionEditor.isLocked;
        this.inputWaypointNote.disabled = SectionEditor.isLocked;
        this.buttonOk.disabled = SectionEditor.isLocked;
        this.buttonDelete.disabled =  SectionEditor.isLocked;

        this.dialog.dialog('option', 'buttons', buttons);
    }

    static showUsingDistance(distance: number) {
        if (this.instance == null) {
            this.instance = new WaypointDialogContorller();
        }

        this.instance.point = null;
        this.instance.distance = distance;
        this.instance.dialog.dialog('open');
        this.instance.update();

        let virtualPoint = ApplicationState.course.getVirtualPointByDistance(distance);
        if (virtualPoint) {
            setTimeout(() => {
                ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, virtualPoint);
            }, 0);
        }
    }

    static showUsingPoint(point: Point) {
        if (this.instance == null) {
            this.instance = new WaypointDialogContorller();
        }

        this.instance.distance = null;
        this.instance.point = point;
        this.instance.dialog.dialog('open');
        this.instance.update();

        setTimeout(() => {
            ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, point);
        }, 0);
    }
}

export class AddWaypointTask implements Task {
    private distance: number;
    private waypoint: Waypoint;
    private createdPoint?: Point;

    constructor(distance: number, waypoint: Waypoint) {
        this.distance = distance;
        this.waypoint = waypoint;
    }

    do() {
        let course = ApplicationState.course;
        course.updateTotalValues();
        let virtualPoint = course.getVirtualPointByDistance(this.distance);
        if (virtualPoint) {
            let section = course.sections[virtualPoint.sectionIndex];
            let newPointInfo = section.virtualPointInfoByDistance(this.distance);
            let createdPoint = newPointInfo.point;
            let indexToInsert = newPointInfo.indexToInsert;
            createdPoint.waypoint = this.waypoint;
            section.points.splice(indexToInsert, 0, createdPoint);
            course.updateTotalValues();
            this.createdPoint = createdPoint;

            ApplicationState.executeListeners(
                ApplicationEvent.SECTION_CHANGED,
                section
            );

            ApplicationState.executeListeners(
                ApplicationEvent.WAYPOINTS_CHANGED,
                this.createdPoint
            );
        }
    }

    undo() {
        if (!this.createdPoint) {
            return;
        }

        let course = ApplicationState.course;
        course.updateTotalValues();
        let section = course.sections[this.createdPoint.sectionIndex];
        let indexOfPoint = section.points.indexOf(this.createdPoint);
        section.points.splice(indexOfPoint, 1);
        course.updateTotalValues();

        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            section
        );

        ApplicationState.executeListeners(
            ApplicationEvent.WAYPOINTS_CHANGED,
            this.createdPoint
        );
    }
}

class ModifyWaypointTask implements Task {
    private point: Point;
    private waypoint: Waypoint;
    private waypointToRestore?: Waypoint;

    constructor(point: Point, waypoint: Waypoint) {
        this.point = point;
        this.waypoint = waypoint;
        this.waypointToRestore = point.waypoint;
    }

    do() {
        let course = ApplicationState.course;
        let section = course.sections[this.point.sectionIndex];
        this.point.waypoint = this.waypoint;
        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            section
        );
        ApplicationState.executeListeners(
            ApplicationEvent.WAYPOINTS_CHANGED,
            this.point
        );
    }

    undo() {
        let course = ApplicationState.course;
        let section = course.sections[this.point.sectionIndex];
        this.point.waypoint = this.waypointToRestore;
        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            section
        );
        ApplicationState.executeListeners(
            ApplicationEvent.WAYPOINTS_CHANGED,
            this.point
        );
    }
}

export class DeleteWaypointTask implements Task {
    private point: Point;
    private waypoint: Waypoint;

    constructor(point: Point) {
        this.point = point;
        this.waypoint = point.waypoint;
    }

    do(): void {
        let course = ApplicationState.course;
        let section = course.sections[this.point.sectionIndex];
        this.point.waypoint = null;
        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            section
        );
        ApplicationState.executeListeners(
            ApplicationEvent.WAYPOINTS_CHANGED,
            this.point
        );
        toastr.success(Resources.text.waypoint_deleted);
    }

    undo(): void {
        let course = ApplicationState.course;
        let section = course.sections[this.point.sectionIndex];
        this.point.waypoint = this.waypoint;
        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            section
        );
        ApplicationState.executeListeners(
            ApplicationEvent.WAYPOINTS_CHANGED,
            this.point
        );
    }
}
