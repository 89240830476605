import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { AdsController } from './Ridingazua.AdsController';
import { CourseListDialogController } from './Ridingazua.CourseListDialogController';
import { Course, CourseListConfiguration } from '../common/Ridingazua.Model';

export class CourseInfoDialogController {
    private static instance?: CourseInfoDialogController;
    private dialog: JQuery;
    private div: HTMLDivElement;
    private dl: HTMLDListElement;

    private ddCourseName: HTMLElement;
    private ddCourseLength: HTMLSpanElement;
    private ddCourseElevationGain: HTMLSpanElement;
    private divTagButtons: HTMLDivElement;
    private pDescription: HTMLParagraphElement;

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        HTMLUtility.appendInputHiddenAutofocus(div);

        let dl = document.createElement('dl');
        div.appendChild(dl);
        this.dl = dl;

        this.appendNameElement();
        this.appendCourseProfileElement();
        this.appendTagsElement();
        this.appendDescriptionElement();

        this.dialog = $(div).dialog({
            title: Resources.text.course_info,
            modal: true,
            closeOnEscape: true,
            buttons: [
                {
                    text: Resources.text.close,
                    click: () => {
                        this.close();
                    },
                },
            ],
        });
    }

    private appendNameElement() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.course_name;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);

        this.ddCourseName = dd;
    }

    private appendCourseProfileElement() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.course_length;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);
        this.ddCourseLength = dd;

        dt = document.createElement('dt');
        this.dl.appendChild(dt);

        label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.course_elevations;

        dd = document.createElement('dd');
        this.dl.appendChild(dd);
        this.ddCourseElevationGain = dd;
    }

    private appendTagsElement() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.course_tag;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);
        dd.classList.add('children-vertical-spacing');

        let divTagButtons = document.createElement('div');
        dd.appendChild(divTagButtons);
        this.divTagButtons = divTagButtons;
    }

    private appendDescriptionElement() {
        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        label.textContent = Resources.text.course_description;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);

        let p = document.createElement('p');
        dd.appendChild(p);
        p.style.textAlign = 'left';
        p.style.lineBreak = 'anywhere';
        this.pDescription = p;
    }

    private update(course: Course) {
        this.ddCourseName.textContent = course.name;

        let lengthKilometers = course.lengthMeter / 1000;
        this.ddCourseLength.textContent = `${lengthKilometers.toFixed(1)}(km)`;

        let elevationGain = course.totalElevationGain;
        this.ddCourseElevationGain.textContent = `${elevationGain.toFixed(1)}(m)`;

        this.pDescription.innerHTML = HTMLUtility.replaceToTextContent(course.description || Resources.text.course_description_not_found);

        this.updateTagButtons(course);

        this.resetSize();
    }

    private updateTagButtons(course: Course) {
        this.divTagButtons.innerHTML = '';
        for (let tag of course.tags) {
            let button = document.createElement('button');
            this.divTagButtons.appendChild(button);
            button.style.marginInlineEnd = '3px';
            button.style.marginBottom = '3px';
            button.textContent = tag;
            button.onclick = () => {
                this.close();
                let configuration = CourseListConfiguration.defaultConfiguration();
                configuration.loadPublic = true;
                configuration.searchKeyword = tag;
                CourseListDialogController.showWithConfiguration(configuration);
            };
        }

        if (!course.tags.length) {
            this.divTagButtons.textContent = Resources.text.course_tag_not_found;
        }
    }

    private resetSize() {
        if (!this.dialog) {
            return;
        }

        this.dialog.dialog('option', 'width', Math.min(window.innerWidth * 0.95, AdsController.dialogWidthForAds));
        this.dialog.dialog('option', 'maxWidth', window.innerWidth * 0.95);
        this.dialog.dialog('option', 'height', 'auto');
    }

    private close() {
        this.dialog.dialog('close');
    }

    static getInstance(): CourseInfoDialogController {
        if (!this.instance) {
            this.instance = new CourseInfoDialogController();
        }
        return this.instance;
    }

    static show(course: Course) {
        let instance = this.getInstance();
        instance.update(course);
        instance.dialog.dialog('open');
    }
}
