import { ApplicationState } from './Ridingazua.ApplicationState';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { MapController } from './Ridingazua.MapController';
import { Resources } from './Ridingazua.Resources';
import { StorageController } from './Ridingazua.StorageController';

export class OverlayImageMapSettingsDialogController {
    private static instance?: OverlayImageMapSettingsDialogController;
    private dialog: JQuery;
    private div: HTMLDivElement;
    private dl: HTMLDListElement;
    private textAraeUrlTemplate: HTMLTextAreaElement;
    private sliderOpacity: JQuery;
    private divSliderOpacityHandle: HTMLDivElement;
    private buttonEnabled: JQueryUI.DialogButtonOptions;
    private buttonDisabled: JQueryUI.DialogButtonOptions;

    static keyForImageOverlayUrlTemplate = 'image_overlay_map_url_template';

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        HTMLUtility.appendInputHiddenAutofocus(div);

        let dl = document.createElement('dl');
        div.appendChild(dl);
        this.dl = dl;
        this.addUrlTemplateField();
        this.addSliderOpacity();

        this.buttonEnabled = {
            text: Resources.text.apply,
            click: () => {
                this.enableImageMapOverlay();
            },
        };

        this.buttonDisabled = {
            text: Resources.text.disabled,
            click: () => {
                this.disableOverlayImageMap();
            },
        };

        this.dialog = $(div).dialog({
            title: Resources.text.overlay_image_map_sttings,
            modal: true,
            closeOnEscape: false,
            width: window.innerWidth * 0.7,
            open: () => {
                if (!this.dialog) {
                    return;
                }

                this.dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });
            },
            buttons: [this.buttonEnabled, this.buttonDisabled],
        });
    }

    private addUrlTemplateField() {
        let key = OverlayImageMapSettingsDialogController.keyForImageOverlayUrlTemplate;
        let description = Resources.text.overlay_image_map_url_template_description;
        let idForTextArea = `input_${key}`;
        let fieldName = Resources.text.overlay_image_map_url_template;

        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let label = document.createElement('label');
        dt.appendChild(label);
        label.setAttribute('for', idForTextArea);
        label.textContent = fieldName;

        let dd = document.createElement('dd');
        this.dl.appendChild(dd);

        let textarea = document.createElement('textarea');
        dd.appendChild(textarea);
        textarea.value = StorageController.get(key) || '';
        textarea.style.width = '95%';
        textarea.style.height = '100px';
        textarea.id = idForTextArea;
        textarea.setAttribute('storage_key', key);
        this.textAraeUrlTemplate = textarea;

        let pDescription = document.createElement('p');
        pDescription.textContent = description;
        dd.appendChild(pDescription);
    }

    private addSliderOpacity() {
        let opacity = 5;
        try {
            opacity = parseInt(StorageController.get('overlay_image_map_opacity') || '5');
        } catch (error) {
            // do nothing
        }

        let dt = document.createElement('dt');
        this.dl.appendChild(dt);

        let labelOpacity = document.createElement('label');
        labelOpacity.textContent = Resources.text.opacity;
        let idForSliderOpacity = 'overlay-image-map-opacity';
        labelOpacity.setAttribute('for', idForSliderOpacity);
        dt.appendChild(labelOpacity);

        let dd = document.createElement('dd');
        dd.classList.add('center');
        this.dl.appendChild(dd);

        let divSliderOpacity = document.createElement('div');
        divSliderOpacity.style.width = '80%';
        divSliderOpacity.style.marginTop = '10px';
        divSliderOpacity.classList.add('slider');
        dd.appendChild(divSliderOpacity);

        let divSliderOpacityHandle = document.createElement('div');
        divSliderOpacityHandle.textContent = opacity.toString();
        divSliderOpacityHandle.classList.add('handle');
        divSliderOpacityHandle.classList.add('ui-slider-handle');
        divSliderOpacity.appendChild(divSliderOpacityHandle);
        this.divSliderOpacityHandle = divSliderOpacityHandle;

        this.sliderOpacity = $(divSliderOpacity).slider({
            min: 2,
            max: 10,
            value: opacity,
            slide: (_, sliderParams) => {
                this.divSliderOpacityHandle.textContent = `${sliderParams.value}`;
            },
        });
    }

    private enableImageMapOverlay() {
        let invalid = false;
        let urlTemplate = this.textAraeUrlTemplate.value.trim();
        if (!OverlayImageMapSettingsDialogController.isValidImageMapTypeUrlTemplate(urlTemplate)) {
            toastr.error(Resources.text.overlay_image_map_url_template_invalid);
            return;
        }
        StorageController.set(OverlayImageMapSettingsDialogController.keyForImageOverlayUrlTemplate, urlTemplate);

        let opacity = this.sliderOpacity.slider('value') || 5;
        StorageController.set('overlay_image_map_opacity', opacity.toString());

        StorageController.set('overlay_image_map_enabled', 'true');
        MapController.removeOverlayImageMap(ApplicationState.map);
        MapController.addOverlayImageMap(ApplicationState.map);

        this.close();
    }

    private disableOverlayImageMap() {
        StorageController.set('overlay_image_map_enabled', 'false');
        MapController.removeOverlayImageMap(ApplicationState.map);
        this.close();
    }

    static isValidImageMapTypeUrlTemplate(urlTemplate: string): boolean {
        if (!urlTemplate.includes('{zoom}') || !urlTemplate.includes('{x}') || !urlTemplate.includes('{y}')) {
            return false;
        }

        let replaced = urlTemplate.replace('{zoom}', '0');
        replaced = urlTemplate.replace('{x}', '0');
        replaced = urlTemplate.replace('{y}', '0');

        try {
            let url = new URL(replaced);
            return url.protocol == 'http:' || url.protocol == 'https:';
        } catch {
            return false;
        }
    }

    static show() {
        if (!this.instance) {
            this.instance = new OverlayImageMapSettingsDialogController();
        }
        this.instance.dialog.dialog('open').dialog('option', 'width', window.innerWidth * 0.7);
    }

    private close() {
        this.dialog.dialog('close');
    }
}
