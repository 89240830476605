import { CourseRange, Point, Section, MapSection } from "../common/Ridingazua.Model";
import { HTMLUtility } from "./Ridingazua.HTMLUtility";
import { ApplicationState, ApplicationEvent, ApplicationEventListener } from "./Ridingazua.ApplicationState";
import { MapController, MapUtility } from "./Ridingazua.MapController";
import { Resources } from "./Ridingazua.Resources";
import { KeyState } from "./Ridingazua.KeyState";
import { SectionEditor } from "./Ridingazua.SectionEditor";
import { isNothing } from "../common/Ridingazua.Utility";

export class SelectedRangeController implements ApplicationEventListener {
    private static instance: SelectedRangeController;
    private div: HTMLDivElement
    private pDescription: HTMLParagraphElement;
    private selectedRange?: CourseRange;
    private selectedRangeBounds?: google.maps.LatLngBounds;

    static set selectedRange(value: CourseRange | null) {
        if (this.selectedRange == value) {
            return;
        }

        this.instance.selectedRange = value;

        if (value) {
            let points = ApplicationState.course.getPointsBetween(
                value.startPoint.distanceFromCourseStart,
                value.finishPoint.distanceFromCourseStart
            );
            this.instance.selectedRangeBounds = MapUtility.getBoundsFromPoints(points, 0.02);
        } else {
            this.instance.selectedRangeBounds = null;
        }

        ApplicationState.executeListeners(
            value ? ApplicationEvent.SELECT_RANGE : ApplicationEvent.DESELECT_RANGE,
            value
        )
    }

    static get selectedRange(): CourseRange | null {
        return this.instance?.selectedRange;
    }

    private constructor() {
        this.createDivSelectedRange();
        ApplicationState.addListener(this);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.SELECT_RANGE:
            case ApplicationEvent.DESELECT_RANGE:
                this.updateDivSelectedRange();
                this.updateMapObjects();
                break;
        }
    }

    static createInstance(): SelectedRangeController {
        if (!this.instance) {
            this.instance = new SelectedRangeController();
        }

        return this.instance;
    }

    private createDivSelectedRange(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('selected-range-info-layer');
        this.div = div;

        let divRemoveButton = document.createElement('div');
        div.appendChild(divRemoveButton);
        divRemoveButton.style.textAlign = 'right';

        let removeButton = HTMLUtility.createIconButton('Remove', 'close', () => {
            this.selectedRange = null;
            ApplicationState.executeListeners(ApplicationEvent.DESELECT_RANGE);
        });
        divRemoveButton.appendChild(removeButton);
        removeButton.classList.add('tiny');

        let p = document.createElement('p');
        div.appendChild(p);
        p.style.marginTop = '5px';
        this.pDescription = p;

        return div;
    }

    private updateDivSelectedRange() {
        this.div.remove();

        if (!this.selectedRange) {
            return;
        }

        let startPoint = this.selectedRange.startPoint;
        let finishPoint = this.selectedRange.finishPoint;
        let startPosition = `${(startPoint.distanceFromCourseStart / 1000).toFixed(1)}km`;
        let finishPosition = `${(finishPoint.distanceFromCourseStart / 1000).toFixed(1)}km`;
        let lengthKm = `${(this.selectedRange.lengthMeter / 1000).toFixed(1)}km`;
        let gainMeter = `${(finishPoint.elevation - startPoint.elevation).toFixed(1)}m`;
        let avgSlopePercent = `${(this.selectedRange.averageSlope * 100).toFixed(1)}%`;
        let components = [
            `${Resources.text.position_start}: ${startPosition}`,
            `${Resources.text.position_finish}: ${finishPosition}`,
            `${Resources.text.length}: ${lengthKm}`,
            `${Resources.text.elevation_gain}: ${gainMeter}`,
            `${Resources.text.average_slope}: ${avgSlopePercent}`,
        ];

        let climbWaypointType = this.selectedRange.climbCategoryWaypointType();
        if (!isNothing(climbWaypointType)) {
            components.push(
                `${Resources.text.climb_category}: ${climbWaypointType.name}`
            );
        }

        this.pDescription.innerHTML = components.join('<br />');
    }

    private linesForRange: google.maps.Polyline[];
    private markersForRange: google.maps.Marker[];
    private rectForRange?: google.maps.Rectangle;

    private updateMapObjects() {
        if (this.linesForRange) {
            this.linesForRange.forEach(line => {
                line.setMap(null);
            });
        }

        if (this.markersForRange) {
            this.markersForRange.forEach(marker => {
                marker.setMap(null);
            });
        }

        this.rectForRange?.setMap(null);

        this.linesForRange = [];
        this.markersForRange = [];
        this.rectForRange = null;

        let range = this.selectedRange;
        if (!range) {
            return;
        }

        let map = ApplicationState.map;
        let course = ApplicationState.course;

        let pathInfos: any[] = [];
        let pathPoints = course.getPointsBetween(
            range.startPoint.distanceFromCourseStart,
            range.finishPoint.distanceFromCourseStart
        );

        let pathInfo: any;
        for (let point of pathPoints) {
            if (pathInfo) {
                let lastPoint = pathInfo.points[pathInfo.points.length - 1];
                if (lastPoint.sectionIndex !== point.sectionIndex) {
                    pathInfo = null;
                }
            }

            if (!pathInfo) {
                pathInfo = {};

                if (!isNothing(point.sectionIndex)) {
                    pathInfo.section = course.sections[point.sectionIndex];
                }

                pathInfo.path = new Array<google.maps.LatLng>();
                pathInfo.points = new Array<Point>();
                pathInfos.push(pathInfo);
            }

            pathInfo.path.push(new google.maps.LatLng(point.latitude, point.longitude));
            pathInfo.points.push(point);
        };

        pathInfos.forEach(pathInfo => {
            let path = pathInfo.path as google.maps.LatLng[];
            let section = pathInfo.section as Section;

            let line = new google.maps.Polyline({
                geodesic: true,
                strokeColor: '#990099',
                strokeOpacity: 1.0,
                strokeWeight: 4,
                zIndex: MapController.zIndexForSelectedRange,
                map: map,
                path: path,
                draggable: false,
                editable: false,
            });

            line.addListener('mousemove', event => {
                let latLng = event.latLng as google.maps.LatLng;
                let virtualPointInfo = section.virtualPointInfoByCoord(
                    latLng.lat(),
                    latLng.lng()
                );
                ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, virtualPointInfo.point);
            });

            line.addListener('mouseout', event => {
                ApplicationState.executeListeners(ApplicationEvent.REMOVE_CURSOR);
            });

            line.addListener('click', (event) => {
                let latLng = event.latLng as google.maps.LatLng;

                if (KeyState.shiftKey) {
                    SectionEditor.selectedEditor.didMapClick(latLng.lat(), latLng.lng());
                    return;
                }

                MapController.showMenuForSectionLine(latLng.lat(), latLng.lng(), section);
            });

            line.addListener('rightclick', (event) => {
                let latLng = event.latLng as google.maps.LatLng;
                MapController.showMenuForSectionLine(latLng.lat(), latLng.lng(), section);
            });

            this.linesForRange.push(line);
        });

        this.markersForRange.push(
            MapController.createStartOrEndPointMarker(true, pathPoints[0])
        );

        this.markersForRange.push(
            MapController.createStartOrEndPointMarker(false, pathPoints[pathPoints.length - 1])
        );

        this.rectForRange = new google.maps.Rectangle({
            map: map,
            bounds: this.selectedRangeBounds,
            strokeColor: 'rgb(255, 165, 0)',
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillOpacity: 0,
            zIndex: MapController.zIndexForSelectedRangeRect
        });

        MapController.setVisibleAllPoints(pathPoints);
    }

    static updateSelectedRangeInfoLayerPosition() {
        if (!this.selectedRange) {
            return;
        }

        let bounds = this.instance.selectedRangeBounds;
        if (!bounds) {
            return;
        }

        let northEast = bounds.getNorthEast();
        let southWest = bounds.getSouthWest();
        let pxPosition = MapController.convertPointToPixelPosition(
            northEast.lat(),
            southWest.lng()
        );

        HTMLUtility.showElementInContainer(
            this.instance.div,
            ApplicationState.map.getDiv(),
            pxPosition[0],
            pxPosition[1],
            MapController.zIndexForSelectedRangeInfoLayer,
            5,
            'right'
        );
    }
}