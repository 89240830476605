import { Course } from "../common/Ridingazua.Model";
import { Statics } from "../common/Ridingazua.Statics";
import { HTMLUtility } from "./Ridingazua.HTMLUtility";
import { Resources } from "./Ridingazua.Resources";

export class CourseLinkDialogController {
    private static instance: CourseLinkDialogController;
    private dl: HTMLDListElement;
    private dialog: JQuery;
    private courseUrlString: string;

    private constructor() {
        let div = document.createElement('div');

        this.dl = document.createElement('dl');
        div.appendChild(this.dl);
        
        this.dialog = $(div).dialog({
            title: Resources.text.link,
            modal: true,
            width: 'auto',
            open: (event, ui) => {
                setTimeout(() => {
                    $(div).dialog(
                        'option',
                        'position', 
                        {
                            my: 'center', 
                            at: 'center',
                            of: window
                        }
                    );
                }, 10)
            }
        });
    }

    static show(course: Course) {
        let courseId = course.id;
        if (!courseId) {
            toastr.info(Resources.text.retry_after_save_course);
            return;
        }

        if (!this.instance) {
            this.instance = new CourseLinkDialogController();
        }

        this.instance.update(course.idOrPathName);
        this.instance.dialog.dialog('open');
    }

    private update(courseIdOrPathName: number | string) {
        this.dl.innerHTML = '';

        this.courseUrlString = Statics.courseUrlString(courseIdOrPathName);
        
        let elements = [
            [Resources.text.course, this.courseUrlString],
            [Resources.text.download + '(gpx)', this.courseUrlString + '/gpx'],
            [Resources.text.download + '(tcx)', this.courseUrlString + '/tcx'],
        ];

        elements.forEach(element => {
            let dt = document.createElement('dt');
            this.dl.appendChild(dt);
            dt.textContent = element[0];

            let dd = document.createElement('dd');
            dd.classList.add('children-spacing');
            this.dl.appendChild(dd)

            let anchor = document.createElement('a');
            dd.appendChild(anchor);
            anchor.href = element[1];
            anchor.textContent = element[1];
            anchor.target = '_blank';

            let copyButton = HTMLUtility.createIconButton(Resources.text.copy, 'content_copy', (button) => {
                this.copyUrl(element[1]);
            });
            copyButton.classList.add('middle');
            
            dd.appendChild(copyButton);
        });
    }

    private async copyUrl(url: string) {
        try {
            await navigator.clipboard.writeText(url)
            toastr.success(Resources.text.copied);
        } catch (error) {
            toastr.error(Resources.text.failed_to_copy);
        }
    }
}