import { Resources } from '../front/Ridingazua.Resources';

export class Statics {
    /**
     * 사이트의 host 값
     * front에서 사용시 Application constructor에서 변경될 수 있다.
     */
    static siteHost = 'ridingazua.cc';
    static editorPath = '/editor';
    static coursePath = '/editor/course';
    static courseListPath = '/editor/courseList'
    static coursePathAlt = '/c';
    static apiPath = '/editor/api';

    static editorPathFor(name: string) {
        return `${this.editorPath}/${name}`;
    }

    static apiPathFor(name: string) {
        return `${this.apiPath}/${name}`;
    }

    static get siteUrl(): string {
        return `https://${this.siteHost}`;
    }

    static get editorUrl(): string {
        return `${this.siteUrl}${this.editorPath}`;
    }

    static image(name: string): string {
        return `${this.editorPathFor('img')}/${name}`;
    }

    static uploadedFile(id: number, thumbnail: boolean = false): string {
        return `${this.editorPathFor('uploaded')}/${id}${thumbnail ? '.th' : ''}`;
    }

    /**
     * 업로드된 이미지 파일의 주소
     * @param id
     * @param width
     * @param scale
     */
    static uploadedImageFile(id: number, width: number, scale: number): string {
        let targetWidth = width * scale;
        let extension = 300;
        if (targetWidth <= 300) {
            extension = 300;
        } else if (targetWidth <= 500) {
            extension = 500;
        } else if (targetWidth <= 700) {
            extension = 700;
        } else {
            extension = 1000;
        }
        return `${this.editorPathFor('uploaded')}/${id}.${extension}`;
    }

    static localizedPageUrl(pageName: string): string {
        return `${this.siteUrl}${this.editorPath}/localized/${Resources.acceptedLanguage()}/${pageName}.html`;
    }

    static courseUrlString(idOrPathName?: number | string): string {
        return `${this.siteUrl}${this.coursePathAlt}/${idOrPathName || ''}`;
    }

    static courseListUrlString() {
        return `${this.siteUrl}${this.courseListPath}`;
    }

    /**
     * URL encode 처리된 코스 URL
     * @param idOrPathName
     */
    static courseUrlStringEncoded(idOrPathName?: number | string): string {
        return `${this.siteUrl}${this.coursePathAlt}/${encodeURIComponent(idOrPathName || '')}`;
    }
}
