import { ApplicationState, ApplicationEvent } from "./Ridingazua.ApplicationState";

export class LocationManager {
    static _lastKnownPosition?: GeolocationPosition;

    static set lastKnownPosition(value: GeolocationPosition) {
        this._lastKnownPosition = value;
        ApplicationState.executeListeners(ApplicationEvent.POSITION_RECEIVED, value);
    }

    static get lastKnownPosition(): GeolocationPosition {
        return this._lastKnownPosition;
    }

    private static currentWatchId?: number;

    static watch(): number | null {
        this.clearWatch();
        ApplicationState.executeListeners(ApplicationEvent.POSITION_WATCH_STARTED);
        let watchId = navigator.geolocation.watchPosition(
            position => {
                this.lastKnownPosition = position;
            },
            error => {
                ApplicationState.executeListeners(ApplicationEvent.POSITION_FAILED, error);
                this.clearWatch();
            },
            {
                maximumAge: 5000,
                timeout: 5000,
            }
        );

        this.currentWatchId = watchId;

        return watchId;
    }

    static clearWatch() {
        if (!this.currentWatchId) {
            return
        }
        navigator.geolocation.clearWatch(this.currentWatchId);
        this.currentWatchId = null;
    }
}