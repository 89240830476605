import { TaskManager } from './Ridingazua.TaskManager';
import { SaveDialogController } from './Ridingazua.SaveDialogController';
import { CourseListDialogController } from './Ridingazua.CourseListDialogController';
import { DownloadDialogController } from './Ridingazua.DownloadDialogController';
import { CommonDialogController } from './Ridingazua.CommonDialogController';
import { ImportDialogController } from './Ridingazua.ImportDialogController';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { ApplicationState } from './Ridingazua.ApplicationState';
import { HelpDialogController } from './Ridingazua.HelpDialogController';
import { MoreMenuController } from './Ridingazua.MoreMenuController';
import { AdvertisementPosition, CourseListConfiguration } from '../common/Ridingazua.Model';

/**
 * 상단의 버튼들을 구성하기 위한 class
 */
export class TopToolsController {
    private static instance: TopToolsController;

    private items: TopBarItem[] = [
        {
            id: 'new',
            name: Resources.text.new_course,
            iconName: 'insert_drive_file',
            action: () => {
                TopToolsController.confirmNewCourse();
            },
        },
        {
            id: 'open',
            name: Resources.text.open,
            iconName: 'folder_open',
            action: () => {
                let configuration = CourseListConfiguration.defaultConfiguration();
                configuration.loadPublic = ApplicationState.user ? false : true;
                CourseListDialogController.showWithConfiguration(configuration);
            },
        },
        {
            id: 'save',
            name: Resources.text.save,
            iconName: 'save',
            action: () => {
                SaveDialogController.show();
            },
        },
        {
            id: 'import',
            name: Resources.text.import,
            iconName: 'cloud_upload',
            action: () => {
                ImportDialogController.show();
            },
        },
        {
            id: 'download',
            name: Resources.text.download,
            iconName: 'save_alt',
            action: () => {
                DownloadDialogController.show();
            },
        },
        {
            id: 'undo',
            name: Resources.text.undo,
            iconName: 'undo',
            action: () => {
                TaskManager.undoTask();
            },
        },
        {
            id: 'redo',
            name: Resources.text.redo,
            iconName: 'redo',
            action: () => {
                TaskManager.redoTask();
            },
        },
        {
            id: 'help',
            name: Resources.text.help,
            iconName: 'help',
            action: () => {
                HelpDialogController.show();
            },
        },
        {
            id: 'more',
            name: Resources.text.more_menus,
            iconName: 'more_horiz',
            action: (button) => {
                MoreMenuController.toggle(button);
            },
        },
    ];

    private constructor() {
        let div = document.createElement('div');
        div.classList.add('box');
        div.classList.add('children-spacing');

        this.items.forEach((item) => {
            let button = HTMLUtility.createIconButton(item.name, item.iconName, (button) => {
                item.action(button);
            });

            div.appendChild(button);
        });

        let divRightTop = document.getElementById('div-right-top');
        divRightTop.appendChild(div);
    }

    static createInstance(): TopToolsController {
        if (!this.instance) {
            this.instance = new TopToolsController();
        }

        return this.instance;
    }

    private static confirmNewCourse() {
        CommonDialogController.showConfirm(
            Resources.text.new_course,
            Resources.text.confirm_message_for_lost,
            [
                {
                    text: Resources.text.ok,
                    action: () => {
                        window.localStorage && window.localStorage.removeItem('editingCourse');
                        ApplicationState.doNotShowUnloadAlert = true;
                        location.href = '/';
                        ApplicationState.doNotShowUnloadAlert = false;
                    },
                },
                {
                    text: Resources.text.cancel,
                    action: () => {
                        // do nothing
                    },
                },
            ],
            null,
            true,
            AdvertisementPosition.NEW_DIALOG
        );
    }
}

interface TopBarItem {
    id: string;
    name: string;
    iconName: string;
    action: (button: HTMLElement) => void;
}
