import {
    ApplicationState,
    ApplicationEventListener,
    ApplicationEvent,
} from './Ridingazua.ApplicationState';
import { MapController } from './Ridingazua.MapController';
import { AutoRouteDirector, DirectDirector } from './Ridingazua.Director';
import { CheckboxController, HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { PlaceSearchController } from './Ridingazua.PlaceSearchController';
import { MapSettingsMenuController } from './MapSettingsMenuController';

export class MapSettingsController implements ApplicationEventListener {
    private autoRouteCheckbox: CheckboxController;
    private static instance: MapSettingsController;

    static createInstance(): MapSettingsController {
        if (!this.instance) {
            this.instance = new MapSettingsController();
        }
        return this.instance;
    }

    div: HTMLDivElement;

    private constructor() {
        let div = document.createElement('div');
        div.id = 'div-map-settings';
        div.classList.add('children-spacing');
        this.div = div;

        div.appendChild(this.createMapSettingsDiv());
        div.appendChild(this.createSearchDiv());

        this.updateAutoRouteCheckbox();
        ApplicationState.addListener(this);

        document.getElementById('div-right-top').appendChild(div);
    }

    private createMapSettingsDiv(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('box');
        div.classList.add('middle');
        div.classList.add('children-spacing');

        let autoRouteCheckbox = new CheckboxController();
        autoRouteCheckbox.title = Resources.text.auto_route;
        autoRouteCheckbox.isChecked =
            MapController.selectedDirector == DirectDirector.instance;
        autoRouteCheckbox.onchange = (isChecked) => {
            MapController.selectedDirector = isChecked
                ? AutoRouteDirector.instance
                : DirectDirector.instance;
        };
        div.appendChild(autoRouteCheckbox.div);
        this.autoRouteCheckbox = autoRouteCheckbox;

        let buttonSettings = HTMLUtility.createIconButton(
            Resources.text.map_settings,
            'settings',
            (button) => {
                MapSettingsMenuController.toggleWithElement(button);
            }
        );
        div.appendChild(buttonSettings);

        return div
    }

    private createSearchDiv(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('box');
        div.classList.add('middle');
        div.classList.add('children-spacing');

        let buttonSearch = HTMLUtility.createIconButton(
            Resources.text.search,
            'search',
            () => {
                this.toggleSearch();
            }
        )
        div.appendChild(buttonSearch);

        return div;
    }

    private toggleSearch() {
        PlaceSearchController.isShowing = !PlaceSearchController.isShowing;
        PlaceSearchController.currentBounds = ApplicationState.map.getBounds();
    }

    private updateAutoRouteCheckbox() {
        this.autoRouteCheckbox.isChecked =
            MapController.selectedDirector == AutoRouteDirector.instance;
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.SELECTED_DIRECTOR_CHANGED:
                this.updateAutoRouteCheckbox();
                break;
        }
    }
}
