import Axios from 'axios';
import { Resources } from './Ridingazua.Resources';
import { Statics } from '../common/Ridingazua.Statics';

export class ImportantDialogController {
    private static instance: ImportantDialogController;
    private dialog: JQuery;

    private constructor(div: HTMLDivElement) {
        this.dialog = $(div).dialog({
            title: Resources.text.important,
            modal: true,
            buttons: [
                {
                    text: Resources.text.close,
                    click: () => {
                        this.dialog.dialog('close');
                    },
                },
            ],
        });
    }

    static async showIfRequired() {
        if (this.instance) {
            this.instance.dialog.dialog('open');
        }

        try {
            let response = await Axios.get(Statics.editorPathFor('important.html'));
            if (!response.data || !response.data.length) {
                return;
            }

            let div = document.createElement('div');
            div.classList.add('doc');
            div.innerHTML = response.data;

            this.instance = new ImportantDialogController(div);
            this.instance.dialog.dialog('open');
        } catch (error) {
            // do nothing
        }
    }
}